import React, { useEffect, useState, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import * as Icon from 'react-feather';
import ImageWithBaseApi from '../../../../core/img/ImageWithBaseApi';
import { all_routes } from '../../../../core/data/routes/all_routes';
import StickyBox from 'react-sticky-box';
import { apiRepository } from '../../../../api/apiRepository';
import { AuthContext } from '../../../../core/context/AuthContext';
import { Console } from 'console';


const CustomerSideBar = () => { // Recibiendo userProfile como prop
  const authContext = useContext(AuthContext);
  if (!authContext) {
    return <div>Error: Auth context not found!</div>;
  }

  const { logout, user } = authContext;
  const routes = all_routes;
  const location = useLocation();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [userImage, setUserImage] = useState(null);
  const [subdroptoggle, setsubdroptoggle] = useState(false);

  const fetchUser = async () => {
    try {
      const response = await apiRepository.getIdUsers(user.userId);
      setUserImage(response.data.data.image);
    } catch (error) {
      console.error('Error al obtener los datos de usuarios:', error);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  useEffect(() => {
    return () => {
      location.pathname.includes('settings') ? setsubdroptoggle(true) : setsubdroptoggle(false);
    }
  }, [location.pathname]);

  const customerSidebarData = [
    {
      link: routes.customerDashboard,
      name: 'Dashboard',
      icon: <i className="ti ti-layout-grid me-3" />,
    },
    {
      link: routes.customerCalendar,
      name: 'Agenda',
      icon: <Icon.Calendar className="sidebar-feather me-3" />,
    },
    {
      link: routes.customerCart,
      name: 'Carrito',
      icon: <Icon.ShoppingCart className="sidebar-feather me-3" />,
    },
    {
      link: routes.customerReservation,
      name: 'Servicios',
      icon: <Icon.Briefcase className="sidebar-feather me-3" />,
    },
    {
      link: routes.purchaseHistory,
      name: 'Historial de compra',
      icon: <Icon.Archive className="sidebar-feather me-3" />,
    },
  ];

  const trainerSidebarData = [
    {
      link: routes.customerDashboard,
      name: 'Editar Perfil',
      icon: <i className="ti ti-layout-grid me-2" />,
    },
    {
      link: routes.customerCalendar,
      name: 'Agenda',
      icon: <Icon.Calendar className="sidebar-feather me-3" />,
    },
    {
      link: routes.trainerSchedules,
      name: 'Clases',
      icon: <Icon.Activity className="sidebar-feather me-3" />,
    },
  ];

  const adminSidebarData = [
    ...customerSidebarData,
    ...trainerSidebarData,
  ];


  const handleLogout = () => {
    logout();
  };

  const activeRouterPath = (link) => {
    return link === location.pathname;
  };
  
  const sidebarData = () => {
    switch (user?.role) {
      case 'admin':
        return adminSidebarData;
      case 'trainer':
        return trainerSidebarData;
      default:
        return customerSidebarData;
    }
  };

  return (
    <>
      <StickyBox>
        <div className="card slider-min user-sidebar mb-4">
          <div className="card-header user-sidebar-header mb-4">
            <div className="d-flex justify-content-center align-items-center flex-column">
              {userImage && (
                <>
                  <span className="avatar avatar-xxxl mb-2">
                    <ImageWithBaseApi
                      src={userImage}
                      routeName="user"
                      className="img-fluid"
                      alt="Perfil de Usuario"
                      isExternalLink={false}
                    />
                  </span>
                </>
              )}
              <h6 className="mb-2">{user?.username || 'Inicia tu Sesión'}</h6>
            </div>
          </div>
          <div className="card-body user-sidebar-body p-0">
            <ul>
              {sidebarData().map((item, index) => (
                <li className="mb-4" key={index}>
                  <Link
                    to={item.link}
                    className={`d-flex align-items-center ${activeRouterPath(item.link) && 'active'}`}
                  >
                    {item.icon}
                    {item.name}
                  </Link>
                </li>
              ))}
              <li className="mb-0">
                <button
                  onClick={handleLogout}
                  className="btn p-0 m-0 fw-normal"
                >
                  <Link
                  to="#"
                  className={`d-flex align-items-center`}
                  >
                    <i className="ti ti-logout me-3" />
                    Logout
                  </Link>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </StickyBox>
    </>
  );
};

export default CustomerSideBar;
