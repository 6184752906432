export const handleChange = (
  e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  setFormData: React.Dispatch<React.SetStateAction<Users>>
) => {
  const { name, value, type, checked } = e.target;
  const newValue = type === 'checkbox' ? checked : value;

  setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: newValue,
  }));
};
export const formatTo12Hour = (time24: string): string => {
  const [hour, minute] = time24.split(':').map(Number);
  const period = hour >= 12 ? 'PM' : 'AM';
  const hour12 = hour % 12 || 12;
  return `${hour12}:${minute.toString().padStart(2, '0')} ${period}`;
};

export const formatDatetoString = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Meses de 0 a 11
    const day = String(date.getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
};

export const dateStandard = (date: string): Date => {
  return new Date(date+ "T00:00:00");
};

export const formatDate = (date: string) => {
    if (!date) return "";
    return new Date(date).toISOString().split("T")[0];
  };

export const formatDateToFull = (dateString: string): string => {
  try {
    const date = new Date(dateString + "T00:00:00");
    const day = new Intl.DateTimeFormat('es-ES', { weekday: 'long', day: '2-digit' }).format(date);
    const month = new Intl.DateTimeFormat('es-ES', { month: 'long' }).format(date);
    const year = date.getFullYear();
    return `${day} de ${month} de ${year}`;
  } catch (error) {
    console.error('Error al formatear la fecha:', error);
    return 'Fecha no válida';
  }
};

export const formatTimeTo12Hour = (time24: string): string => {
  try {
    const [hour, minute] = time24.split(':').map(Number);
    const period = hour >= 12 ? 'PM' : 'AM';
    const hour12 = hour % 12 || 12;
    return `${hour12}:${minute.toString().padStart(2, '0')} ${period}`;
  } catch (error) {
    console.error('Error al formatear la hora:', error);
    return 'Hora no válida';
  }
};

export const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatToCustomDate = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Formateamos el mes para que siempre tenga 2 dígitos
  const day = date.getDate().toString().padStart(2, '0'); // Formateamos el día para que siempre tenga 2 dígitos

  return `${year}-${month}-${day}`;
};
