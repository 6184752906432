import React, { useEffect, useState } from 'react';
import DefaultEditor from 'react-simple-wysiwyg';
import { ValidationError } from '../../../core/form/validationForm';
import { Link } from 'react-router-dom';
import { apiRepository } from '../../../api/apiRepository';
import { PromotionSection } from '../../../core/Interface/repositoryInterfaces';
import { ImageUploadField, InputField } from '../common/input/inputField';
import { handleChange } from '../../../core/utils/utils';
import { getErrorMessage } from '../../../core/utils/errorUtils';
import { handleFormSubmit } from '../../../core/services/formService'

const EditPromotionSection = () => {
  const [sectionData, setSectionData] = useState<PromotionSection>([]);
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const [loading, setLoading] = useState<number | null>(null);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    handleChange(e, setSectionData);
  };

  const fetchPromotionSection = async () => {
    try {
      setLoading(true);
      const response = await apiRepository.getPromotionSection();
      setSectionData(response.data.data);
    } catch (error) {
      console.error("Error al obtener los datos de la sección:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPromotionSection();
  }, []);

  const handleUpdate = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    setErrors([]);

    const errors = await handleFormSubmit(
        1,
        sectionData,
        () => [],
        {
            create: apiRepository.updatePromotionSection,
            update: apiRepository.updatePromotionSection,
        },
        () => {
          fetchPromotionSection();
        },
        undefined,
        "Ocurrió un error al intentar guardar el contenido. Inténtalo de nuevo.",
        true
    );

    if (errors?.length > 0) {
      setErrors(errors);
    } else {
      const info = []
      info.push({ field: "success", message: "¡Contenido actualizado con exito!" })
      setErrors(info);
    }

    setLoading(false);
};


  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    setSectionData({ ...sectionData, image: file });
  };

  const handleRemoveImage = () => {
    setSectionData({ ...sectionData, image: null });
    const fileInput = document.getElementById("imgInp") as HTMLInputElement;
    if (fileInput) {
      fileInput.value = "";
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="row">
          <div className="col-lg-11 col-sm-12 m-auto">
            <div className="content-page-header">
              <h3>Editar sección de promoción</h3>
            </div>
            <div className="row">
              <form onSubmit={handleUpdate}>

              <div className="col-md-6">
                <ImageUploadField
                  label="Imagen principal para la sección de promoción"
                  formData={sectionData}
                  onFileChange={handleFileChange}
                  onRemoveImage={handleRemoveImage}
                  routeName="homesection"
                  recommendedSize="700 x 700 píxeles"
                />
              </div>
              <div className="col-md-6">
                <InputField
                  label="Link"
                  name="link"
                  value={sectionData.link}
                  onChange={handleInputChange}
                  error={getErrorMessage(errors, 'title')}
                />
              </div>
              <div className="col-lg-12">
                <div className="form-group">
                  <label>Información</label>
                  <div id="editor">
                    <DefaultEditor
                      value={sectionData.description}
                      name="description"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                {errors.find((value) => value.field === 'success') && (
                  <div className="alert alert-success mt-3 mb-3" role="alert">
                    {errors.find((value) => value.field === 'success')?.message}
                  </div>
                )}
                {errors.find((value) => value.field === 'error') && (
                  <div className="alert alert-danger mt-3 mb-3" role="alert">
                    {errors.find((value) => value.field === 'error')?.message}
                  </div>
                )}
              </div>
              {errors.find(error => error.field === 'server') && (
                <div className="alert alert-danger" role="alert">
                    {getErrorMessage(errors, "server")}
                </div>
              )}
              <div className="col-lg-12">
                <div className="btn-path">
                  <Link to="#" className="btn btn-cancel me-3">
                    Cancelar
                  </Link>
                  <button
                    className="btn btn-primary-admin"
                    type="submit"
                  >
                    {loading ? (
                      <>
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        Guardando...
                      </>
                    ) : (
                      'Guardar cambios'
                    )}
                  </button>
                </div>
              </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPromotionSection;