import React, { useState, useEffect, useContext } from 'react';
import { apiRepository } from '../../../../api/apiRepository';
import { Payments } from '../../../../core/Interface/repositoryInterfaces';
import DataTableWrapper from '../../../admin/common/dataTable/dataTableWrapper';
import "react-datepicker/dist/react-datepicker.css";
import { useResponsiveColumns } from '../../../../core/hooks/useResponsiveColumnsTrainer';
import { useOutletContext } from 'react-router-dom';
import { AuthContext } from '../../../../core/context/AuthContext';
import SchedulesModal from '../../../admin/common/modals/schedules-modal';
import DeleteSchedulesModal from '../../../admin/common/modals/delete-schedules-modal';
import { CalendarRangeField } from '../../../admin/common/input/inputField';
import { formatToCustomDate } from '../../../../core/utils/utils';
import { getDefaultWeekRange } from '../../../../core/utils/dateUtils';

const PurchaseHistory = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    return <div>Error: Auth context not found!</div>;
  }

  const { user } = authContext;
  const [data, setData] = useState<Payments[]>([]);
  // const [selectedScheduleId, setSelectedScheduleId] = useState<any>(null);
  // const [selectedSchedules, setSelectedSchedules] = useState<any>(null);
  // const [filterFuture, setFilterFuture] = useState(false);
  const { setBreadcrumbData } = useOutletContext();
  const [dateRange, setDateRange] = useState(getDefaultWeekRange());
  const [totalRecords, setTotalRecords] = useState<number>(-1);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    setBreadcrumbData({ title: "Historial de compra", item1: "Perfil", item2: "Historial de compra" });
  }, [setBreadcrumbData]);

  const fetchSales = async (pageNumber = currentPage, pageSize = rows) => {
    try {
      setLoading(true);
      const [startDate, endDate] = dateRange;
      const response = await apiRepository.getPaymentsId(user?.userId, formatToCustomDate(startDate), formatToCustomDate(endDate), pageNumber, pageSize);
      const totalRecords = response.data.data.totalElements;
      setData(response.data.data.content);
      setTotalRecords(totalRecords);
    } catch (error) {
      console.error("Error al obtener el historial de compras:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSales();
  }, [dateRange]);

  const handleDateChange = (e) => {
    setDateRange(e.value);
  };

  const columns = [
    { field: "purchaseId", header: "ID de Compra" },
    { field: "date", header: "Fecha", body: (rowData) => new Date(rowData.date).toLocaleDateString('es-PE') },
    { field: "items", header: "Artículos", body: (rowData) => rowData.items.map(item => item.name).join(", ") },
    { field: "totalAmount", header: "Monto Total", body: (rowData) => `S/. ${rowData.totalAmount.toFixed(2)}` },
    { field: "paymentMethod", header: "Método de Pago" },
    {
      field: "status",
      header: "Estado",
      body: (rowData) => (
        <span className={rowData.status === 'COMPLETED' ? 'badge-completed' : 'badge-pending'}>
          {rowData.status === 'COMPLETED' ? 'Completado' : 'Pendiente'}
        </span>
      ),
    },
  ];

  const getColumns = useResponsiveColumns(columns);

  const onPageChange = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
    const pageNumber = event.first / event.rows
    setCurrentPage(pageNumber);
    const [startDate, endDate] = dateRange;
    fetchSales(formatToCustomDate(startDate), formatToCustomDate(endDate), pageNumber, event.rows);
  };

  return (
    <>
      <div className="">
        <div className="d-flex justify-content-between mt-3 mb-3">
        <h4>Historial de compra</h4>
          <div className="col-md-4 col-sm-4 mb-0">
            <CalendarRangeField
              label="Selecciona Rango de Fechas"
              value={dateRange}
              onChange={handleDateChange}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
        <DataTableWrapper
          data={data}
          columns={getColumns()}
          // onEdit={handleEditClick}
          // onDelete={handleDeleteClick}
          // onCustomAction={handleCustomActionClick}
          loading={loading}
          paginator
          showGridlines
          rowsPerPage={rows}
          totalRecords={totalRecords}
          first={first}
          onPageChange={onPageChange}
          emptyMessage="No se encontraron Compras."
        />
        </div>
      </div>
    </>
  );
};

export default PurchaseHistory;