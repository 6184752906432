import React, { useState, useEffect } from 'react';
import { apiRepository } from '../../../api/apiRepository';
import { Headquarters } from '../../../core/Interface/repositoryInterfaces';
import DataTableWrapper from '../common/dataTable/dataTableWrapper';
import HeadquartersModal from '../common/modals/headquarters-modal';
import DeleteHeadquartersModal from '../common/modals/delete-headquarters-modal';
import { useResponsiveColumns } from '../../../core/hooks/useResponsiveColumnsHeadquarters';

const HeadquartersPage = () => {
    const [data, setData] = useState<Headquarters[]>([]);
    const [selectedHeadquartersId, setSelectedHeadquartersId] = useState<any>(null);
    const [selectedHeadquarters, setSelectedHeadquarters] = useState<any>(null);
    const [loading, setLoading] = useState(false);

    const fetchHeadquarters = async () => {
        try {
            setLoading(true);
            const response = await apiRepository.getHeadquarters();
            setData(response.data.data);
        } catch (error) {
            console.error("Error al obtener los datos de headquarters:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchHeadquarters();
    }, []);

    const handleEditClick = (rowData: any) => {
        setSelectedHeadquarters(null);
        setTimeout(() => setSelectedHeadquarters(rowData), 0);
    };

    const handleDeleteClick = (rowData: any) => {
        setSelectedHeadquartersId(null);
        setTimeout(() => setSelectedHeadquartersId(rowData.id), 0);
    };

    const columns = [
        { field: "name", header: "Nombre del Establecimiento" },
        { field: "address", header: "Ubicación" },
        { field: "phone", header: "Teléfono" },
    ];

    const getColumns = useResponsiveColumns(columns);

    return (
        <div className="page-wrapper page-settings">
            <div className="content">
                <div className="content-page-header content-page-headersplit">
                    <h5>Establecimientos</h5>
                    <div className="list-btn">
                        <button
                            className="btn btn-primary-admin"
                            onClick={handleEditClick}
                        >
                            <i className="fa fa-plus me-2" /> Agregar Establecimiento
                        </button>
                    </div>
                </div>
                <DataTableWrapper
                    data={data}
                    columns={getColumns()}
                    onEdit={handleEditClick}
                    onDelete={handleDeleteClick}
                    loading={loading}
                    paginator={false}
                    showGridlines
                    emptyMessage="No se encontraron establecimientos."
                />
            </div>
            <HeadquartersModal selectedHeadquarters={selectedHeadquarters} onUpdateSuccess={() => fetchHeadquarters()}/>
            <DeleteHeadquartersModal selectedHeadquarterId={selectedHeadquartersId} onDeleteSuccess={() => fetchHeadquarters()}/>
        </div>
    );
};

export default HeadquartersPage;