import React, { useState } from 'react';
import CustomerSideBar from './common/sidebar';
import BreadCrumb from '../common/breadcrumb/breadCrumb';
import { Outlet } from 'react-router-dom';

const CustomerLayout = () => {
  const [breadcrumbData, setBreadcrumbData] = useState({ title: "", item1: "", item2: "" });

  return (
    <>
        <BreadCrumb title={breadcrumbData.title} item1={breadcrumbData.item1} item2={breadcrumbData.item2} />
        <div className="page-wrapper">
            <div className="content">
                <div className="">
                    <div className="row justify-content-center">
                    <div className="col-xl-2 col-lg-3 col-11 col-md-11 col-sm-10 mb-3"  style={{ minWidth: '300px' }}>
                        <CustomerSideBar />
                    </div>
                    <div className="col-xl-8 col-lg-8 col-11 col-md-11 col-sm-10">
                        <Outlet context={{ setBreadcrumbData }} />
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
};

export default CustomerLayout;