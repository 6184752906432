import { Users, Schedules, Classes, Coupon, Banners, Faq, PaymentDays, Subscriptions } from '../../core/Interface/repositoryInterfaces'
import moment from 'moment';

export interface ValidationError {
    id: string;
    field: string;
    message: string;
}

export const validateLogin = (email: string, password: string): ValidationError[] => {
    const errors: ValidationError[] = [];

    if (!email || !email.trim()) {
        errors.push({ field: 'email', message: 'El correo electrónico no puede estar vacío.' });
    } else if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(email)) {
        errors.push({ field: 'email', message: 'El correo electrónico no es válido.' });
    }

    if (!password || !password.trim()) {
        errors.push({ field: 'password', message: 'La contraseña no puede estar vacía.' });
    }

    return errors;
};

export const validateHeadquarters = (headquartersData: { name: string, address: string, phone: string }): ValidationError[] => {
    const errors: ValidationError[] = [];

    if (!headquartersData.name || !headquartersData.name.trim()) {
        errors.push({ field: "name", message: "El nombre de la sede no puede estar vacío." });
    }

    if (!headquartersData.address || !headquartersData.address.trim()) {
        errors.push({ field: "address", message: "La dirección de la sede no puede estar vacía." });
    }

    if (!headquartersData.phone || !headquartersData.phone.trim()) {
        errors.push({ field: "phone", message: "El teléfono de la sede no puede estar vacío." });
    }

    return errors;
};

export const validateFaq = (faqData: Faq): ValidationError[] => {
    const errors: ValidationError[] = [];

    if (!faqData.title || !faqData.title.trim()) {
        errors.push({ field: "title", message: "El título no puede estar vacío." });
    }

    if (!faqData.description || !faqData.description.trim()) {
        errors.push({ field: "description", message: "La respuesta no puede estar vacía." });
    }

    return errors;
};

export const validateCategory = (categoryData: { name: string, type: number }): ValidationError[] => {
    const errors: ValidationError[] = [];

    if (!categoryData.name || !categoryData.name.trim()) {
        errors.push({ field: "name", message: "El nombre no puede estar vacío." });
    }

    if (!categoryData.type || isNaN(Number(categoryData.type)) || Number(categoryData.type) <= 0) {
        errors.push({ field: "type", message: "La cantidad debe ser un número válido y mayor que cero." });
    }

    return errors;
};

export const validatePackage = (
    packageData: {
        subtitle: string;
        packageTypeId: number | string;
        title: string;
        feature1: string;
        price: string | number;
        status: boolean;
        image: File | null;
        quantityDate: string | number;
    },
): ValidationError[] => {
    const errors: ValidationError[] = [];

    if (!packageData.title || !packageData.title.trim()) {
        errors.push({ field: "title", message: "El título no puede estar vacío." });
    }

    if (!packageData.subtitle || !packageData.subtitle.trim()) {
        errors.push({ field: "subtitle", message: "El subtítulo no puede estar vacío." });
    }

    if (!packageData.packageTypeId || packageData.packageTypeId === "") {
        errors.push({ field: "packageTypeId", message: "La categoría no puede estar vacía." });
    }

    if (!packageData.feature1 || !packageData.feature1.trim()) {
        errors.push({ field: "feature1", message: "La descripción no puede estar vacía." });
    }

    if (!packageData.price || Number(packageData.price) <= 0) {
        errors.push({ field: "price", message: "El precio debe ser mayor a 0." });
    }

    if (packageData.quantityDate && Number(packageData.quantityDate) <= 0) {
        errors.push({ field: "quantityDate", message: "La cantidad de días debe ser mayor a 0." });
    }

    return errors;
};

export const validateClasses = (classes: Classes): ValidationError[] => {
    const errors: ValidationError[] = [];

    if (!classes.title || !classes.title.trim()) {
        errors.push({ field: "title", message: "El titulo no puede estar vacío." });
    }

    if (!classes.description || !classes.description.trim()) {
        errors.push({ field: "description", message: "La descripción no puede estar vacía." });
    }

    if (!classes.headquartersId) {
        errors.push({ field: "selectedHeadquarters", message: "La establecimiento no puede estar vacía." });
    }

    return errors;
};

export const validateCoupon = (couponData: Coupon): ValidationError[] => {
    const errors: ValidationError[] = [];

    if (!couponData.name || !(typeof couponData.name === 'string' && couponData.name.trim())) {
        errors.push({ field: "name", message: "El nombre no puede estar vacío." });
    }

    if (!couponData.code || !(typeof couponData.code === 'string' && couponData.code.trim())) {
        errors.push({ field: "code", message: "El código no puede estar vacío." });
    }

    if (!couponData.amount || isNaN(Number(couponData.amount)) || Number(couponData.amount) <= 0) {
        errors.push({ field: "amount", message: "El monto debe ser un número válido mayor que 0." });
    }

    const expirationDate = new Date(couponData.dateExpired);
    if (!couponData.dateExpired || !(expirationDate instanceof Date) || isNaN(expirationDate.getTime())) {
        errors.push({ field: "dateExpired", message: "La fecha de expiración no es válida." });
    } else if (expirationDate < new Date()) {
        errors.push({ field: "dateExpired", message: "La fecha de expiración debe ser futura." });
    }

    if (typeof couponData.typeDiscount !== "boolean") {
        errors.push({ field: "typeDiscount", message: "El tipo de descuento debe ser un valor booleano." });
    }

    if (typeof couponData.typeGeneralProduct !== "boolean") {
        errors.push({ field: "typeGeneralProduct", message: "El tipo de producto general debe ser un valor booleano." });
    }
    return errors;
};

export const validateSubscriptions = (subscriptionsData: Subscriptions): ValidationError[] => {
    const errors: ValidationError[] = [];

    if (!subscriptionsData.fullname ) {
        errors.push({ field: "fullname", message: "El nombre no puede estar vacío." });
    }

    if (!subscriptionsData.email ) {
        errors.push({ field: "email", message: "El correo no puede estar vacío." });
    }

    return errors;
};

export const validateSchedule = (schedule: Schedules): ValidationError[] => {
    const errors: ValidationError[] = [];
    const phoneRegex = /^\d+$/;

    if (!schedule.title || !(typeof schedule.title === 'string' && schedule.title.trim())) {
        errors.push({ field: "title", message: "El título no puede estar vacío." });
    }

    if (!schedule.date) {
        errors.push({ field: "date", message: "La fecha no puede estar vacía." });
    } else if (!moment(schedule.date, 'YYYY-MM-DD', true).isValid()) {
        errors.push({ field: "date", message: "La fecha debe tener un formato válido (YYYY-MM-DD)." });
    }

    if (!schedule.hourStart) {
        errors.push({ field: "hourStart", message: "La hora de inicio no puede estar vacía." });
    } else if (!moment(schedule.hourStart, 'HH:mm', true).isValid()) {
        errors.push({ field: "hourStart", message: "La hora de inicio debe tener un formato válido (HH:mm)." });
    }

    if (!schedule.minutes) {
        errors.push({ field: "minutes", message: "La duración total no puede estar vacía." });
    } else if (!phoneRegex.test(schedule.minutes)) {
        errors.push({ field: "minutes", message: "Ingrese un formato correcto." });
    }

    if (!schedule.quantity || isNaN(Number(schedule.quantity)) || Number(schedule.quantity) <= 0) {
        errors.push({ field: "quantity", message: "La cantidad debe ser un número válido y mayor que cero." });
    }

    if (!schedule.classId) {
        errors.push({ field: "classId", message: "La clase no puede estar vacía." });
    }

    if (!schedule.trainerId) {
        errors.push({ field: "trainerId", message: "El entrenador no puede estar vacía." });
    }

    if (!schedule.status) {
        errors.push({ field: "status", message: "El estado de clase no puede estar vacía." });
    }

    return errors;
};

export const validateUser = (user: Users): ValidationError[] => {
    const errors: ValidationError[] = [];
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phoneRegex = /^(\+?\d{1,3}[-\s]?)?(\(?\d{1,4}\)?[-\s]?)?(\d{4}[-\s]?\d{4})$/;

    if (!user.name) {
        errors.push({ field: "name", message: "El nombre no puede estar vacío." });
    }

    if (!user.lastname) {
        errors.push({ field: "lastname", message: "El apellido no puede estar vacío." });
    }

    if (!user.email) {
        errors.push({ field: "email", message: "El email no puede estar vacío." });
    } else if (!emailRegex.test(user.email)) {
        errors.push({ field: "email", message: "El email debe tener un formato válido." });
    }

    if (!user.id && !user.password) {
        errors.push({ field: "password", message: "La contraseña no puede estar vacía." });
    }

    if (user.password && user.newPassword !== user.password) {
        errors.push({ field: "newPassword", message: "La contraseña debe coincidir." });
    }

    if (!user.profile) {
        errors.push({ field: "profile", message: "El perfil no puede estar vacío." });
    }

    if (!user.phone) {
        errors.push({ field: "phone", message: "El teléfono no puede estar vacío." });
    } else if (!phoneRegex.test(user.phone)) {
        errors.push({ field: "phone", message: "Ingrese un formato correcto." });
    }

    if (user.active === undefined || user.active === null) {
        errors.push({ field: "active", message: "El estado activo no puede estar vacío." });
    }

    if (!user.dni) {
        errors.push({ field: "dni", message: "El DNI no puede estar vacío." });
    }

    if (!user.birthdate) {
        errors.push({ field: "birthdate", message: "La fecha de nacimiento no puede estar vacía." });
    } else {
        const birthDate = new Date(user.birthdate);

        if (isNaN(birthDate.getTime())) {
            errors.push({ field: "birthdate", message: "La fecha de nacimiento es inválida." });
        } else {
            const today = new Date();
            let age = today.getFullYear() - birthDate.getFullYear();
            const month = today.getMonth() - birthDate.getMonth();

            if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }

            if (age < 10) {
                errors.push({ field: "birthdate", message: "Ingresa una fecha de nacimiento válida." });
            }
        }
    }

    return errors;
};

export const validatePaymentDays = (paymentDays: PaymentDays): ValidationError[] => {
    const errors: ValidationError[] = [];
    const phoneRegex = /^\d+$/;

    if (!phoneRegex.test(paymentDays.days)) {
        errors.push({ field: "days", message: "Ingrese un formato correcto." });
    }

    if (paymentDays.days && !paymentDays.endDate) {
        errors.push({ field: "endDate", message: "La fecha de vencimiento no puede estar vacía." });
    } else {
        const endDate = new Date(paymentDays.endDate);

        if (isNaN(endDate.getTime())) {
            errors.push({ field: "endDate", message: "La fecha ingresada es inválida." });
        } else {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            if (endDate < today) {
                errors.push({ field: "endDate", message: "La fecha debe ser hoy o en el futuro." });
            }
        }
    }

    return errors;
};


export const validateComplaint = (complaint: any): ValidationError[] => {
    const errors: ValidationError[] = [];

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phoneRegex = /^(\+?\d{1,3}[-\s]?)?(\(?\d{1,4}\)?[-\s]?)?(\d{4}[-\s]?\d{4})$/;

    if (!complaint.firstName) {
        errors.push({ field: "firstName", message: "El nombre no puede estar vacío." });
    }

    if (!complaint.lastName) {
        errors.push({ field: "lastName", message: "El apellido no puede estar vacío." });
    }

    if (!complaint.documentType) {
        errors.push({ field: "documentType", message: "El tipo de documento no puede estar vacío." });
    }

    if (!complaint.documentNumber) {
        errors.push({ field: "documentNumber", message: "El número de documento no puede estar vacío." });
    }

    if (!complaint.email) {
        errors.push({ field: "email", message: "El correo electrónico no puede estar vacío." });
    } else if (!emailRegex.test(complaint.email)) {
        errors.push({ field: "email", message: "El correo electrónico debe tener un formato válido." });
    }

    if (!complaint.phoneNumber) {
        errors.push({ field: "phoneNumber", message: "El teléfono no puede estar vacío." });
    } else if (!phoneRegex.test(complaint.phoneNumber)) {
        errors.push({ field: "phoneNumber", message: "Ingrese un formato de teléfono válido." });
    }

    if (!complaint.department) {
        errors.push({ field: "department", message: "El departamento no puede estar vacío." });
    }

    if (!complaint.province) {
        errors.push({ field: "province", message: "La provincia no puede estar vacía." });
    }

    if (!complaint.district) {
        errors.push({ field: "district", message: "El distrito no puede estar vacío." });
    }

    if (!complaint.address) {
        errors.push({ field: "address", message: "La dirección no puede estar vacía." });
    }

    if (!complaint.reference) {
        errors.push({ field: "reference", message: "La referencia no puede estar vacía." });
    }

    if (!complaint.complaintType) {
        errors.push({ field: "complaintType", message: "El tipo de queja no puede estar vacío." });
    }

    if (!complaint.consumptionType) {
        errors.push({ field: "consumptionType", message: "El tipo de consumo no puede estar vacío." });
    }

    if (!complaint.receiptNumber) {
        errors.push({ field: "receiptNumber", message: "El número de recibo no puede estar vacío." });
    }

    if (!complaint.complaintDate) {
        errors.push({ field: "complaintDate", message: "La fecha de la queja no puede estar vacía." });
    }

    if (!complaint.provider) {
        errors.push({ field: "provider", message: "El proveedor no puede estar vacío." });
    }

    if (!complaint.productCost) {
        errors.push({ field: "productCost", message: "El costo del producto no puede estar vacío." });
    }

    if (!complaint.productDescription) {
        errors.push({ field: "productDescription", message: "La descripción del producto no puede estar vacía." });
    }

    if (!complaint.purchaseDate) {
        errors.push({ field: "purchaseDate", message: "La fecha de compra no puede estar vacía." });
    }

    if (!complaint.consumptionDate) {
        errors.push({ field: "consumptionDate", message: "La fecha de consumo no puede estar vacía." });
    }

    if (!complaint.complaintDetails) {
        errors.push({ field: "complaintDetails", message: "Los detalles de la queja no pueden estar vacíos." });
    }

    if (!complaint.customerRequest) {
        errors.push({ field: "customerRequest", message: "La solicitud del cliente no puede estar vacía." });
    }

    return errors;
};

export const validateBanner = (banner: Banners): ValidationError[] => {
    const errors: ValidationError[] = [];

    if (!banner.title) {
        errors.push({ field: "title", message: "El título del banner no puede estar vacío." });
    }

    if (!banner.subtitle) {
        errors.push({ field: "subtitle", message: "La descripción del banner no puede estar vacía." });
    }

    if (!banner.path) {
        errors.push({ field: "path", message: "El link del banner no puede estar vacío." });
    }

    if (!banner.image) {
        errors.push({ field: "image", message: "La imagen del banner no puede estar vacía." });
    } else if (!(banner.image instanceof File || typeof banner.image === "string")) {
        errors.push({ field: "image", message: "La imagen debe ser un archivo válido o una URL." });
    }

    return errors;
};