import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import PagesAuthHeader from './common/header';
import { all_routes } from '../../../../core/data/routes/all_routes';
import AuthFooter from './common/footer';
import { ValidationError, validateUser } from '../../../../core/form/validationForm';
import { InputField, CalendarField } from '../../../admin/common/input/inputField';
import { apiRepository } from '../../../../api/apiRepository';
import { handleChange } from '../../../../core/utils/utils';
import { AuthContext } from '../../../../core/context/AuthContext';

const UserSignup = () => {
  const routes = all_routes;
  const [formData, setFormData] = useState<Users>({
    name: '',
    lastname: '',
    email: '',
    password: '',
    newPassword: '',
    profile: 'customer',
    phone: '',
    birthdate: '',
    dni: '',
    active: true,
  });
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const [loading, setLoading] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const [isFree, setIsFree] = useState(false);
  const authContext = useContext(AuthContext);
  if (!authContext) {
    return <div>Error: Auth context not found!</div>;
  }

  const { login } = authContext;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    handleChange(e, setFormData);
  };

  const registerUser = async (event: React.FormEvent) => {
    event.preventDefault();

    const validationErrors = validateUser(formData);

    if (validationErrors.length > 0) {
      if (!isAccepted) {
        validationErrors.push({ field: "terms", message: "Debes aceptar los termino y condiciones." });
      }
      setErrors(validationErrors);
      return;
    }

    setLoading(true);
    try {
      setErrors([]);
      const formattedBirthdate = formData.birthdate
        ? new Date(formData.birthdate).toISOString().split('T')[0]
        : null;

      const payload = {
        ...formData,
        birthdate: formattedBirthdate,
        classFree: isFree
      };

      await apiRepository.createUser(payload);
      console.info('Usuario registrado correctamente');
      const responseAuth = await apiRepository.authUser(payload);
      const { success, data, message } = responseAuth.data;
      if (success) {
        login({
          token: data.authToken,
          username: data.name,
          userId: data.id,
          role: data.profile,
        });

        console.info('Usuario verificado correctamente');
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        const cleanedMessage = error.response.data.message.replace(/^Bad request: /, '');
        validationErrors.push({ field: "server", message: cleanedMessage });
      } else {
        validationErrors.push({ field: "server", message: 'Ocurrió un error al intentar iniciar sesión. Inténtalo de nuevo.' });
      }
      setErrors(validationErrors);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <PagesAuthHeader />
      <div className="main-wrapper">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-5 mx-auto">
              <form onSubmit={registerUser}>
                <div className="d-flex flex-column justify-content-center">
                  <div className="card p-sm-4 my-4">
                    <div className="card-body">
                      <div className="text-center mb-3">
                        <h3 className="mb-2">Registro de Usuario</h3>
                        <p>Introduce tus datos para crear tu cuenta</p>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <InputField
                            label="Nombre"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            error={errors.find((err) => err.field === 'name')?.message}
                          />
                        </div>
                        <div className="col-md-6">
                          <InputField
                            label="Apellido"
                            value={formData.lastname}
                            name="lastname"
                            onChange={handleInputChange}
                            error={errors.find((err) => err.field === 'lastname')?.message}
                          />
                        </div>
                      </div>
                      <InputField
                        label="Correo Electrónico"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        error={errors.find((err) => err.field === 'email')?.message}
                      />
                      <div className="row">
                        <div className="col-md-6">
                          <InputField
                            label="Número de teléfono"
                            value={formData.phone}
                            name="phone"
                            onChange={handleInputChange}
                            error={errors.find((err) => err.field === 'phone')?.message}
                          />
                        </div>
                        <div className="col-md-6">
                          <InputField
                            label="DNI"
                            value={formData.dni}
                            name="dni"
                            onChange={handleInputChange}
                            error={errors.find((err) => err.field === 'dni')?.message}
                          />
                        </div>
                      </div>
                      <CalendarField
                        label="Fecha de nacimiento"
                        value={formData.birthdate}
                        onChange={(e) => setFormData({ ...formData, birthdate: e.value })}
                        error={errors.find((err) => err.field === 'birthdate')?.message}
                      />
                      <InputField
                        type="password"
                        name="password"
                        label="Nueva Contraseña"
                        value={formData.password}
                        onChange={handleInputChange}
                        error={errors.find((err) => err.field === 'password')?.message}
                      />
                      <InputField
                        type='password'
                        name="newPassword"
                        label="Confirmar Contraseña"
                        value={formData.newPassword}
                        onChange={handleInputChange}
                        error={errors.find((err) => err.field === 'newPassword')?.message}
                      />
                      <div className="mb-3">
                        <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-2">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="classFree"
                              defaultValue={isFree}
                              onChange={() => setIsFree(!isAccepted)}
                            />
                            <label className="form-check-label" htmlFor="remember_me">
                              Solicitar clase gratuita
                            </label>
                          </div>
                          {errors.find(error => error.field === 'terms') && (
                            <small className="text-danger mb-3">
                              {errors.find(error => error.field === 'terms')?.message}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="mb-3">
                        <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-2">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                              id="accept_terms"
                              checked={isAccepted}
                              onChange={() => setIsAccepted(!isAccepted)}
                            />
                            <label className="form-check-label" htmlFor="remember_me">
                              Acepto los{" "}
                              <Link
                                to="#"
                                className="text-primary text-decoration-underline"
                              >
                                Términos de uso
                              </Link>{" "}
                              y la{" "}
                              <Link
                                to="#"
                                className="text-primary text-decoration-underline"
                              >
                                Política de privacidad
                              </Link>
                            </label>
                          </div>
                          {errors.find(error => error.field === 'terms') && (
                            <small className="text-danger mb-3">
                              {errors.find(error => error.field === 'terms')?.message}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="mb-3">
                        <button
                          type="submit"
                          className="btn btn-lg btn-linear-primary w-100"
                          disabled={loading}
                        >
                          {loading ? 'Registrando...' : 'Registrarse'}
                        </button>
                      </div>
                      <div className="d-flex justify-content-center">
                        <p>
                          ¿Ya tienes una cuenta?{" "}
                          <Link to={routes.login} className="text-primary">
                            Inicia sesión
                          </Link>
                        </p>
                      </div>
                    </div>
                    <div>
                      <ImageWithBasePath
                        src="assets/img/bg/authentication-bg.png"
                        className="bg-left-top"
                        alt="Img"
                      />
                      <ImageWithBasePath
                        src="assets/img/bg/authentication-bg.png"
                        className="bg-right-bottom"
                        alt="Img"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <AuthFooter />
    </>
  );
};

export default UserSignup;