import React, { useEffect, useState } from 'react';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import ImageWithBaseApi from '../../../../core/img/ImageWithBaseApi';
import { serviceSlideData } from '../../../../core/data/json/services-slide';
import { apiRepository } from '../../../../api/apiRepository';
import Slider from 'react-slick';

const FeatureSection = () => {
  const routes = all_routes;
  const [classes, setClasses] = useState([]);
  const slidesToShowCount = Math.max(classes.length >= 3 ? 3 : classes.length, 1);

  const imgslideroption = {
    dots: false,
    nav: false,
    infinite: true,
    speed: 500,
    swipe: true,
    slidesToShow: slidesToShowCount,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: slidesToShowCount,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: slidesToShowCount >= 2 ? 2 : 1,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: slidesToShowCount >= 2 ? 2 : 1,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const response = await apiRepository.getClasses();
        const activeClasses = response.data.data.filter((cls: any) => cls.status === true);
        setClasses(activeClasses);
      } catch (error) {
        console.error('Error al obtener los paquetes:', error);
      }
    };
    fetchClasses();
  }, []);

  return (
    <>
      {/* Service Section */}
      <section className="section p-0">
        <div className="container col-11">
          <div className="row justify-content-center">
            <div
              className="col-lg-6 text-center wow fadeInUp"
              data-wow-delay="0.2s"
            >
              <div className="section-header text-center">
                <h2 className="mb-1">
                  Nuestras{' '}
                  <span className="text-linear-primary">
                    Sesiones Destacadas
                  </span>
                </h2>
                <p className="sub-title">
                  Cada sesión está diseñada para maximizar tu potencial físico y
                  apoyar tus objetivos de estética y salud.
                </p>
              </div>
            </div>
          </div>
          <Slider {...imgslideroption} className="img-slider service-slider nav-center hero-section  slick-slider  slide-arrows-hero">
            {classes.map((res: any, index: number) => {

              return (
                <div
                  className="service-item wow fadeInUp"
                  data-wow-delay="0.2s"
                  key={index}
                >
                  <div className="service-img">
                    <div className="slide-images">
                      <Link to={res.path}>
                        <ImageWithBaseApi
                          src={res.image}
                          routeName="class"
                          className="img-fluid"
                          alt="Clases"
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="service-content">
                    <div className="d-flex justify-content-between">
                      <h6 className="text-truncate mb-1">
                        <Link to={res.image}>{res.title}</Link>
                      </h6>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <p className="fw-medium fs-14 mb-0">{res.description}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
          {serviceSlideData.length > 3 && (
            <div className="row">
              <div className="col-md-12">
                <div className="text-center wow fadeInUp" data-wow-delay="0.2s">
                  <Link to={routes.search} className="btn btn-dark">
                    View All
                    <i className="ti ti-arrow-right ms-2" />
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default FeatureSection;
