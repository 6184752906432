import React from "react";
import { AxiosInstance } from "axios";
import axios from "axios";
import { API_BASE_URL } from '../../environment';
import Cookies from 'js-cookie';

export const api: AxiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 5000, // 5 segundos
});

const handleResponseError = (error: any): Promise<any> => {
  if (error.response) {
    console.error(`Error ${error.response.status}: ${error.response.data}`);
  } else if (error.request) {
    console.error("No response received:", error.request);
  } else {
    console.error("Error setting up request:", error.message);
  }
  return Promise.reject(error);
};

export const ApiProvider = ({ children }: any) => {
    api.interceptors.request.use(
        (config) => {
          const token = Cookies.get('user') ? JSON.parse(Cookies.get('user') as string).token : null;
          if (token) {
            config.headers.Authorization = `Bearer ${token}`;
          }
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );

  api.interceptors.response.use(
    (response) => response,
    handleResponseError
  );

  return <>{children}</>;
};
