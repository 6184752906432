import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { InputOtp } from 'primereact/inputotp';
import { apiRepository } from '../../../../api/apiRepository';
import { Auth, Users } from '../../../../core/Interface/repositoryInterfaces';
import { InputField, CalendarField } from '../../../admin/common/input/inputField';
import { validateLogin, ValidationError, validateUser } from '../../../../core/form/validationForm';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { AuthContext } from '../../../../core/context/AuthContext';


const AuthModals = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    return <div>Error: Auth context not found!</div>;
  }

  const { login } = authContext;

  const routes = all_routes;
  const [serverError, setServerError] = useState<ValidationError[]>([]);
  const [token, setTokens] = useState<any>();
  const [token2, setTokens2] = useState<any>();
  const [formData, setFormData] = useState<Users>({
    name: '',
    lastname: '',
    email: '',
    password: '',
    newPassword: '',
    profile: 'customer',
    phone: '',
    birthdate: '',
    dni: '',
    active: true,
  });
  const [isAccepted, setIsAccepted] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const resetFields = () => {
    setIsAccepted(false);
    setServerError([]);
    setFormData(
      {
        name: '',
        lastname: '',
        email: '',
        password: '',
        profile: 'customer',
        phone: '',
        birthdate: '',
        dni: '',
        active: true,
      }
    );
};

  useEffect(() => {
    const addModalElement = document.getElementById('login-modal');
    const editModalElement = document.getElementById('register-modal');

    addModalElement?.addEventListener('hide.bs.modal', resetFields);
    editModalElement?.addEventListener('hide.bs.modal', resetFields);

    return () => {
        addModalElement?.removeEventListener('hide.bs.modal', resetFields);
        editModalElement?.removeEventListener('hide.bs.modal', resetFields);
    };
}, []);

  const getErrorForField = (field: string) => {
    const error = serverError.find((error) => error.field === field);
    return error ? error.message : null;
  };

  const authLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    setServerError([]);
    const { email, password } = formData;
    const validationErrors = validateLogin(email, password);
    if (validationErrors.length > 0) {
      setServerError(validationErrors);

      return;
    }

    const user: Auth = { email, password};

    try {
      const response = await apiRepository.authUser(user);
      const { success, data, message } = response.data;
      if (success) {
        // console.info('Usuario verificado correctamente');
        const closeModalButton = document.querySelector('[data-bs-dismiss="modal"]');
        if (closeModalButton) {
          closeModalButton.click();
        }

        login({
          token: data.authToken,
          username: data.name,
          userId: data.id,
          role: data.profile,
        });
      } else {
        setServerError(message);
      }
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.message) {
        const cleanedMessage = error.response.data.message.replace(/^Bad request: /, '');
        setServerError(prevErrors => [
          ...prevErrors,
          { field: "server", message: cleanedMessage }
        ]);
      } else {
        setServerError(prevErrors => [
          ...prevErrors,
          { field: "server", message: 'Ocurrió un error al intentar iniciar sesión. Inténtalo de nuevo.' }
        ]);
      }
    }
  };

  const registerUser = async (event: React.FormEvent) => {
    event.preventDefault();
    setServerError([]);
    const validationErrors = validateUser(formData);
    if (validationErrors.length > 0) {
      setServerError(validationErrors);

      if (!isAccepted) {
        setServerError(prevErrors => [
          ...prevErrors,
          { field: "terms", message: 'Debes aceptar los termino y condiciones.' }
        ]);
    }
      return;
    }

    try {
      const response = await apiRepository.createUser(formData);
      if (response.data) {
        // console.info('Usuario registrado correctamente');
        authLogin(event);
        const closeModalButton = document.querySelector('#register-modal [data-bs-dismiss="modal"]');
        if (closeModalButton) closeModalButton.click();
      }
    } catch (error) {
      console.error('Error al registrar usuario:', error);
    }
  };

  return (
    <>
  {/* Login Modal */}
  <div
    className="modal fade"
    id="login-modal"
    tabIndex={-1}
    data-bs-backdrop="static"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header d-flex align-items-center justify-content-end pb-0 border-0">
          <Link
            to="#"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i className="ti ti-circle-x-filled fs-20" />
          </Link>
        </div>
        <div className="modal-body p-4">
          <form onSubmit={authLogin}>
            <div className="text-center mb-3">
              <h3 className="mb-2">Bienvenido a Lift</h3>
              <p>Ingresa tus datos para acceder a tu cuenta</p>
            </div>
            <InputField
              label="Correo Electronico"
              name='email'
              value={formData.email || ''}
              onChange={handleChange}
              error={getErrorForField('email')}
              />
            <div className="mb-3">
              <div className="d-flex align-items-center justify-content-between flex-wrap">
                <label className="form-label">Contraseña</label>
                <Link
                  to="#"
                  className="text-primary fw-medium text-decoration-underline mb-1 fs-14"
                  data-bs-toggle="modal"
                  data-bs-target="#forgot-modal"
                >
                  ¿Olvidaste tu contraseña?
                </Link>
              </div>
              <InputField
                  type="password"
                  name="password"
                  value={formData.password || ''}
                  onChange={handleChange}
                  error={getErrorForField('password')}
              />
            </div>
            <div className='mb-3'>
              {serverError.find(error => error.field === 'server') && (
                  <small className="text-danger mb-3">
                    {serverError.find(error => error.field === 'server')?.message}
                  </small>
              )}
            </div>

            <div className="mb-3">
              <button
                type="submit"
                className="btn btn-lg btn-linear-primary w-100"
                >
                Iniciar sesión
              </button>
            </div>
            {/* <div className="login-or mb-3">
              <span className="span-or">O inicia sesión con </span>
            </div>
            <div className="d-flex align-items-center mb-3">
              <Link
                to="#"
                className="btn btn-light flex-fill d-flex align-items-center justify-content-center me-3"
              >
                <ImageWithBasePath
                  src="assets/img/icons/google-icon.svg"
                  className="me-2"
                  alt="Img"
                />
                Google
              </Link>
              <Link
                to="#"
                className="btn btn-light flex-fill d-flex align-items-center justify-content-center"
              >
                <ImageWithBasePath
                  src="assets/img/icons/fb-icon.svg"
                  className="me-2"
                  alt="Img"
                />
                Facebook
              </Link>
            </div> */}
            <div className="d-flex justify-content-center">
              <p>
              ¿No tienes una cuenta?{" "}
                <Link
                  to="#"
                  className="text-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#register-modal"
                >
                  {" "}
                  Únete hoy mismo
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  {/* /Login Modal */}
  {/* Register Modal */}
  <div
    className="modal fade"
    id="register-modal"
    tabIndex={-1}
    data-bs-backdrop="static"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header d-flex align-items-center justify-content-end pb-0 border-0">
          <Link
            to="#"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i className="ti ti-circle-x-filled fs-20" />
          </Link>
        </div>
        <div className="modal-body p-4">
        <form onSubmit={registerUser}>
                <div className="text-center mb-3">
                  <h3 className="mb-2">Registro</h3>
                  <p>Ingresa tus datos para acceder a tu cuenta</p>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <InputField
                      label="Nombre"
                      name='name'
                      value={formData.name || ''}
                      onChange={handleChange}
                      error={getErrorForField('name')}
              />
                  </div>
                  <div className="col-md-6">
                    <InputField
                      label="Apellido"
                      value={formData.lastname || ''}
                      onChange={handleChange}
                      name="lastname"
                      error={getErrorForField('lastname')}
                    />
                  </div>
                </div>
                <InputField
                  label="Correo Electrónico"
                  name="email"
                  value={formData.email || ''}
                  onChange={handleChange}
                  error={getErrorForField('email')}
                />
                <div className="row">
                  <div className="col-md-6">
                    <InputField
                      label="Número de teléfono"
                      value={formData.phone || ''}
                      onChange={handleChange}
                      name="phone"
                      error={getErrorForField('phone')}
                    />
                  </div>
                  <div className="col-md-6">
                    <InputField
                      label="DNI"
                      value={formData.dni || ''}
                      onChange={handleChange}
                      name="dni"
                      error={getErrorForField('dni')}
                    />
                  </div>
                </div>
                <CalendarField
                  label="Fecha de nacimiento"
                  value={formData.birthdate}
                  onChange={(e) => setFormData((prevFormData) => ({
                    ...prevFormData,
                    birthdate: e.value as string,
                  }))}
                  error={getErrorForField('birthdate')}
                />
                <InputField
                  type="password"
                  name="password"
                  label="Nueva Contraseña"
                  value={formData?.password || ''}
                  onChange={handleChange}
                  error={getErrorForField('password')}
                />
                <InputField
                  type='password'
                  name="newPassword"
                  label="Confirmar Contraseña"
                  value={formData?.newPassword || ''}
                  onChange={handleChange}
                  error={getErrorForField('newPassword')}
                />
                <div className="mb-3">
                <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-2">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="isFree"
                      checked={formData.isFree}
                      onChange={(e) => setFormData((prevFormData) => ({
                        ...prevFormData,
                        isFree: e.target.checked,
                      }))}
                    />
                    <label className="form-check-label" htmlFor="remember_me">
                      Solicitar clase gratuita
                    </label>
                  </div>
                </div>
              </div>

                <div className="mb-1">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="accept_terms"
                      checked={isAccepted}
                      onChange={() => setIsAccepted(!isAccepted)}
                    />
                    <label className="form-check-label" htmlFor="accept_terms">
                      Acepto los {" "}
                      <Link
                        target='_blank'
                        to={routes.termsCondition}
                        className="text-primary text-decoration-underline"
                        // data-bs-dismiss="modal"
                      >
                        Términos de uso
                      </Link>{" "}
                      y {" "}
                      <Link
                        target='_blank'
                        to={routes.privacyPolicy}
                        className="text-primary text-decoration-underline"
                        // data-bs-dismiss="modal"
                      >
                        la Política de privacidad
                      </Link>
                    </label>
                  </div>
                </div>
                <div className='mb-3'>
                  {serverError.find(error => error.field === 'terms') && (
                      <small className="text-danger mb-3">
                        {serverError.find(error => error.field === 'terms')?.message}
                      </small>
                  )}
                </div>
                <button type="submit" className="btn btn-lg btn-linear-primary w-100">
                  Registrarse
                </button>
                <div className="d-flex justify-content-center mt-3">
                  <p>
                    ¿Ya tienes una cuenta?{" "}
                    <Link
                      to="#"
                      className="text-primary"
                      data-bs-target="#login-modal"
                      data-bs-toggle="modal"
                    >
                      Iniciar sesión
                    </Link>
                  </p>
                </div>
              </form>
        </div>
      </div>
    </div>
  </div>
  {/* /Register Modal */}
  {/* Forgot Modal */}
  {/* <div
    className="modal fade"
    id="forgot-modal"
    tabIndex={-1}
    data-bs-backdrop="static"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header d-flex align-items-center justify-content-end pb-0 border-0">
          <Link
            to="#"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i className="ti ti-circle-x-filled fs-20" />
          </Link>
        </div>
        <div className="modal-body p-4">
          <form action="#">
            <div className="text-center mb-3">
              <h3 className="mb-2">Forgot Password?</h3>
              <p>
                Enter your email, we will send you a otp to reset your password.
              </p>
            </div>
            <div className="mb-3">
              <label className="form-label">Email</label>
              <input type="email" className="form-control" />
            </div>
            <div className="mb-3">
              <button
                type="button"
                className="btn btn-lg btn-linear-primary w-100"
                data-bs-toggle="modal"
                data-bs-target="#otp-email-modal"
              >
                Submit
              </button>
            </div>
            <div className=" d-flex justify-content-center">
              <p>
                Remember Password?{" "}
                <Link
                  to="#"
                  className="text-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#login-modal"
                >
                  Sign In
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div> */}
  {/* /Forgot Modal */}
  {/* Email otp Modal */}
  {/* <div
    className="modal fade"
    id="otp-email-modal"
    tabIndex={-1}
    data-bs-backdrop="static"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header d-flex align-items-center justify-content-end pb-0 border-0">
          <Link
            to="#"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i className="ti ti-circle-x-filled fs-20" />
          </Link>
        </div>
        <div className="modal-body p-4">
          <form action="#" className="digit-group">
            <div className="text-center mb-3">
              <h3 className="mb-2">Email OTP Verification</h3>
              <p className="fs-14">
                OTP sent to your Email Address ending ******doe@example.com
              </p>
            </div>
            <div className="text-center otp-input">
              <div className="d-flex align-items-center justify-content-center mb-3">
              <InputOtp value={token} onChange={(e) => setTokens(e.value)} integerOnly/>
              </div>
              <div>
                <div className="badge bg-danger-transparent mb-3">
                  <p className="d-flex align-items-center ">
                    <i className="ti ti-clock me-1" />
                    09:59
                  </p>
                </div>
                <div className="mb-3 d-flex justify-content-center">
                  <p>
                    Didn&apos;t get the OTP?{" "}
                    <Link to="#" className="text-primary">
                      Resend OTP
                    </Link>
                  </p>
                </div>
                <div>
                  <button
                    type="button"
                    className="btn btn-lg btn-linear-primary w-100"
                    data-bs-toggle="modal"
                    data-bs-target="#otp-phone-modal"
                  >
                    Verify &amp; Proceed
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div> */}
  {/* /Email otp Modal */}
  {/* Phone otp Modal */}
  {/* <div
    className="modal fade"
    id="otp-phone-modal"
    tabIndex={-1}
    data-bs-backdrop="static"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header d-flex align-items-center justify-content-end pb-0 border-0">
          <Link
            to="#"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i className="ti ti-circle-x-filled fs-20" />
          </Link>
        </div>
        <div className="modal-body p-4">
          <form action="#" className="digit-group">
            <div className="text-center mb-3">
              <h3 className="mb-2">Phone OTP Verification</h3>
              <p>OTP sent to your mobile number ending&nbsp;******9575</p>
            </div>
            <div className="text-center otp-input">
              <div className="d-flex align-items-center justify-content-center mb-3">
              <InputOtp value={token2} onChange={(e) => setTokens2(e.value)} integerOnly/>
              </div>
              <div>
                <div className="badge bg-danger-transparent mb-3">
                  <p className="d-flex align-items-center ">
                    <i className="ti ti-clock me-1" />
                    09:59
                  </p>
                </div>
                <div className="mb-3 d-flex justify-content-center">
                  <p>
                    Didn&apos;t get the OTP?{" "}
                    <Link to="#" className="text-primary">
                      Resend OTP
                    </Link>
                  </p>
                </div>
                <div>
                  <button
                    type="button"
                    className="btn btn-lg btn-linear-primary w-100"
                    data-bs-toggle="modal"
                    data-bs-target="#reset-password"
                  >
                    Verify &amp; Proceed
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div> */}
  {/* /Phone otp Modal */}
  {/* Reset password Modal */}
  {/* <div
    className="modal fade"
    id="reset-password"
    tabIndex={-1}
    data-bs-backdrop="static"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header d-flex align-items-center justify-content-end pb-0 border-0">
          <Link
            to="#"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i className="ti ti-circle-x-filled fs-20" />
          </Link>
        </div>
        <div className="modal-body p-4">
          <div className="text-center mb-3">
            <h3 className="mb-2">Reset Password</h3>
            <p className="fs-14">
              Your new password must be different from previous used passwords.
            </p>
          </div>
          <form action="#">
            <div className="input-block mb-3">
              <div className="mb-3">
                <label className="form-label">New Password</label>
                <div className="pass-group" id="passwordInput">
                  <input type="password" value={password} onChange={(e) => onChangePassword(e.target.value)} className="form-control pass-input" />
                </div>
              </div>
              <div
                      className={`password-strength d-flex ${
                        passwordResponce.passwordResponceKey === '0'
                          ? 'poor-active'
                          : passwordResponce.passwordResponceKey === '1'
                          ? 'avg-active'
                          : passwordResponce.passwordResponceKey === '2'
                          ? 'strong-active'
                          : passwordResponce.passwordResponceKey === '3'
                          ? 'heavy-active'
                          : ''
                      }`}
                      id="passwordStrength"
                    >
                      <span id="poor" className="active" />
                      <span id="weak" className="active" />
                      <span id="strong" className="active" />
                      <span id="heavy" className="active" />
                    </div>
              <div id="passwordInfo" className="mb-2" />
              <p className="fs-12">
              {passwordResponce.passwordResponceText}
              </p>
            </div>
            <div className="mb-3">
              <div className="d-flex align-items-center justify-content-between flex-wrap">
                <label className="form-label">Confirm Password</label>
              </div>
              <input type="password" className="form-control" />
            </div>
            <div>
              <button
                type="button"
                className="btn btn-lg btn-linear-primary w-100"
                data-bs-toggle="modal"
                data-bs-target="#success_modal"
              >
                Save Change
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div> */}
  {/* /Reset password Modal */}
  {/* success message Modal */}
  <div
    className="modal fade"
    id="success-modal"
    tabIndex={-1}
    data-bs-backdrop="static"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header d-flex align-items-center justify-content-end pb-0 border-0">
          <Link
            to="#"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i className="ti ti-circle-x-filled fs-20" />
          </Link>
        </div>
        <div className="modal-body p-4">
          <div className="text-center">
            <span className="success-check mb-3 mx-auto">
              <i className="ti ti-check" />
            </span>
            <h4 className="mb-2">Success</h4>
            <p>Your new password has been successfully saved</p>
            <div>
              <button
                type="button"
                data-bs-dismiss="modal"
                className="btn btn-lg btn-linear-primary w-100"
              >
                Back to Sign In
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* /success message Modal */}
</>

  )
}

export default AuthModals