import moment from 'moment-timezone';
import 'moment/locale/es';

export const convertToLocalDateString = (date: Date): string => {
    return moment.tz(date, 'America/Lima').format('YYYY-MM-DD');
  };

export const convertToLocalDate = (fecha: string): Date => {
    return moment.tz(fecha, 'YYYY-MM-DD', 'America/Lima').toDate();
  };

export const formatDateToDayMonth = (dateString: string): string => {
    const date = moment.tz(dateString, 'America/Lima');
    return date.format('dddd, DD');
};

export const getDefaultWeekRange = () => {
  const today = moment.tz('America/Lima');
  const firstDayOfWeek = today.clone().startOf('isoWeek');
  const lastDayOfWeek = today.clone().endOf('isoWeek');

  return [firstDayOfWeek.toDate(), lastDayOfWeek.toDate()];
};