import React, { createContext, useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

interface AuthUser {
  token: string;
  username: string;
  userId: number;
  role: string;
}

interface AuthContextType {
  user: AuthUser | null;
  login: (userData: AuthUser) => void;
  logout: () => void;
  updateUserData: (updates: Partial<AuthUser>) => void; // Nuevo método
  isAuthenticated: boolean;
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate(); 
  const [user, setUser] = useState<AuthUser | null>(() => {
    const token = Cookies.get('token');
    if (token) {
      const username = localStorage.getItem('username');
      const userId = localStorage.getItem('userId');
      const role = localStorage.getItem('role');
      return token && username && userId && role
        ? { token, username, userId: Number(userId), role }
        : null;
    }
    return null;
  });

  const INACTIVITY_TIMEOUT = 15 * 60 * 1000;
  const inactivityTimer = useRef<NodeJS.Timeout | null>(null);

  const logout = useCallback(() => {
    setUser(null);
    Cookies.remove('token');
    localStorage.removeItem('username');
    localStorage.removeItem('userId');
    localStorage.removeItem('role');
    localStorage.removeItem('user');
    navigate('/authentication/login');
  }, [navigate]);

  const resetInactivityTimer = useCallback(() => {
    if (inactivityTimer.current) {
      clearTimeout(inactivityTimer.current);
    }
    inactivityTimer.current = setTimeout(() => {
      logout();
    }, INACTIVITY_TIMEOUT);
  }, [logout]);

  useEffect(() => {
    if (user && user.role !== 'admin') {
      resetInactivityTimer();

      window.addEventListener('mousemove', resetInactivityTimer);
      window.addEventListener('keydown', resetInactivityTimer);
      window.addEventListener('scroll', resetInactivityTimer);

      return () => {
        if (inactivityTimer.current) {
          clearTimeout(inactivityTimer.current);
        }
        window.removeEventListener('mousemove', resetInactivityTimer);
        window.removeEventListener('keydown', resetInactivityTimer);
        window.removeEventListener('scroll', resetInactivityTimer);
      };
    }
  }, [user, resetInactivityTimer]);

  const login = (userData: AuthUser) => {
    setUser(userData);
    Cookies.set('token', userData.token, {
      expires: 7,
      secure: false,
      sameSite: 'Lax',
    });
    localStorage.setItem('username', userData.username);
    localStorage.setItem('userId', userData.userId.toString());
    localStorage.setItem('role', userData.role);

    if (userData.role !== 'admin') {
      resetInactivityTimer();
    }

    switch (userData.role) {
      case 'admin':
        navigate('/admin');
        break;
      case 'trainer':
        navigate('/perfil/customer-dashboard');
        break;
      default:
        navigate('/');
    }
  };

  const updateUserData = (updates: Partial<AuthUser>) => {
    setUser((prevUser) => {
      if (prevUser) {
        const updatedUser = { ...prevUser, ...updates };
        if (updates.username) {
          localStorage.setItem('username', updates.username);
        }
        return updatedUser;
      }
      return prevUser;
    });
  };

  const isAuthenticated = !!user;

  return (
    <AuthContext.Provider value={{ user, login, logout, updateUserData, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};