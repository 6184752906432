import React, { useState, useEffect } from 'react';
import { apiRepository } from '../../../../api/apiRepository';
import { Link } from 'react-router-dom'
import ImageWithBaseApi from '../../../../core/img/ImageWithBaseApi';
import StickyBox from 'react-sticky-box';
import ConfirmClassModal from '../../common/modals/confirm-class-modal';
import { DropdownField, CalendarRangeField } from '../../../admin/common/input/inputField';
import { formatToCustomDate } from '../../../../core/utils/utils';
import { useOutletContext } from 'react-router-dom';
import moment from 'moment-timezone';

const CustomerBookingCalendar = () => {
  const { setBreadcrumbData } = useOutletContext();

  useEffect(() => {
    setBreadcrumbData({ title: "Reserva tu Clase", item1: "Perfil", item2: "Reservas" });
  }, [setBreadcrumbData]);

  const getDefaultWeekRange = () => {
    const today = new Date();
    const firstDayOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 1));
    const lastDayOfWeek = new Date(today.setDate(firstDayOfWeek.getDate() + 6));

    const formatDate = (date) => date.toISOString().split('T')[0];

    return [firstDayOfWeek, lastDayOfWeek];
  };

  const [selectedClassItem, setSelectedClassItem] = useState(null);
  const [classIndex, setClassIndex] = useState([]);
  const [groupedSchedules, setGroupedSchedules] = useState<{ [key: number]: any[] }>({});
  const [headquarters, setHeadquarters] = useState([]);
  const [selectedHeadquarters, setSelectedHeadquarters] = useState('');
  const [dateRange, setDateRange] = useState(getDefaultWeekRange());

  const handleDateChange = (e) => {
    setDateRange(e.value);
  };

  const resetFilters = () => {
    setDateRange(getDefaultWeekRange());
    setSelectedHeadquarters('');
    fetchClasses();
    fetchSchedules();
  };

  const handleHeadquartersChange = (e) => {
    setSelectedHeadquarters(e.value);
  };

  const fetchClasses = async () => {
    try {
      const response = await apiRepository.getClasses();
      const classList = response.data.data.map((item: any) => ({
        id: item.id,
        title: item.title,
        headquartersId: item.headquartersId,
        image: item.image,
        description: item.description,
        headquartersName: item.headquartersName,
      }));
      setClassIndex(classList);
    } catch (error) {
      console.error("Error al obtener los datos de las clases:", error);
    }
  };

  const fetchHeadquarters = async () => {
    try {
      const response = await apiRepository.getHeadquarters();
      const formattedHeadquarters = response.data.data.map((item) => ({
        label: item.name,
        value: item.id.toString(),
      }));

      setHeadquarters(formattedHeadquarters);
    } catch (error) {
      console.error("Error al obtener los datos de las clases:", error);
    }
  };

  const fetchSchedules = async () => {
    try {
      const [startDate, endDate] = dateRange;
      const response = await apiRepository.getdateSchedules(formatToCustomDate(startDate), formatToCustomDate(endDate), 1);
      const schedules = response.data.data;

      const grouped = schedules.reduce((acc, schedule) => {
        const { id, classId, date, title, hourStart, hourEnd, classDescription, quantity, reservation, trainerName } = schedule;
        
        const day = moment.tz(date, 'YYYY-MM-DD', 'America/Lima');
        const dayName = day.format('dddd');
        const dayNumber = day.date();
        const dayWithDate = `${dayName} ${dayNumber}`;
      
        if (!acc[classId]) {
          acc[classId] = {};
        }
        if (!acc[classId][dayWithDate]) {
          acc[classId][dayWithDate] = [];
        }
      
        acc[classId][dayWithDate].push({
          id,
          title,
          hourStart,
          hourEnd,
          date,
          classDescription,
          reservation,
          trainerName,
          quantity,
        });
        return acc;
      }, {});
      

      const sortedGrouped = Object.fromEntries(
        Object.entries(grouped).map(([classId, days]) => {
          const sortedDays = Object.entries(days)
            .sort(([dayA], [dayB]) => {
              const dateA = new Date(days[dayA][0].date); // Accede a la fecha del primer elemento
              const dateB = new Date(days[dayB][0].date);
              return dateA - dateB;
            });

          return [classId, Object.fromEntries(sortedDays)];
        })
      );

      setGroupedSchedules(sortedGrouped);
    } catch (error) {
      console.error("Error al obtener los datos de horarios:", error);
    }
  };

  useEffect(() => {
    fetchHeadquarters();
    fetchClasses();
    fetchSchedules();
  }, [dateRange]);

  const onUpdateSchedules = () => {
    fetchSchedules();
  };

  const filteredClasses = selectedHeadquarters
  ? classIndex.filter(classItem => classItem.headquartersId.toString() === selectedHeadquarters)
  : classIndex;

  return (
    <>
      <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3 mb-4">
        <h4>Reservas</h4>
      </div>
      <StickyBox>
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-betwee">
              <h5 className="col mb-3">
                <i className="ti ti-filter-check me-2" />
                Filtros
              </h5>

              <div className="d-flex align-items-center justify-content-end mb-1">
                <button
                  type="button"
                  className="btn btn-light bg-transparent border-0 text-danger"
                  onClick={resetFilters}
                >
                  Limpiar Filtros
                </button>
              </div>
            </div>
            <form>
              {/* Title */}
              <div className="row mb-3 pb-1 border-bottom">
                <div className="col-md-6 col-sm-12 mb-1">
                  <CalendarRangeField
                    label="Selecciona Rango de Fechas"
                    value={dateRange}
                    onChange={handleDateChange}
                  />
                </div>

                <div className="col-md-6 col-sm-12 mb-1">
                  <DropdownField
                    label="Selecciona Establecimiento"
                    options={headquarters}
                    value={selectedHeadquarters}
                    onChange={handleHeadquartersChange}
                    placeholder="Selecciona el establecimiento"
                  />
                </div>


              </div>

              {filteredClasses.map((classItem) => {
                const schedules = groupedSchedules[classItem.id] || {};

                return (
                  <div className="accordion border-bottom mb-3" key={classItem.id}>
                    <div className="accordion-item">
                      <div
                        className="accordion-header"
                        id={`accordion-heading-${classItem.id}`}
                      >
                        <div
                          className="accordion-button p-0 mb-3"
                          data-bs-toggle="collapse"
                          data-bs-target={`#accordion-collapse-${classItem.id}`}
                          aria-expanded="true"
                          aria-controls={`accordion-collapse-${classItem.id}`}
                          role="button"
                        >
                          <div className="row w-100">
                            <div className="col-12">
                              <div className="service-cont">
                                <div className="service-cont-img">
                                  <ImageWithBaseApi
                                    routeName='class'
                                    src={classItem.image}
                                    alt={classItem.title}
                                  />
                                </div>
                                <div className="service-cont-info">
                                  <span className="badge bg-light fs-14 mb-2">
                                    {classItem.headquartersName}
                                  </span>
                                  <h3 className="title">
                                    <Link to="#">{classItem.title}</Link>
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id={`accordion-collapse-${classItem.id}`}
                        className="accordion-collapse collapse"
                        aria-labelledby={`accordion-heading-${classItem.id}`}
                      >
                        <div className="table-responsive reservation">
                          <table className="table table-reservation">
                            <thead>
                              <tr>
                                {Object.keys(schedules).map((day) => (
                                  <th key={day}>{day}</th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {Object.keys(schedules).length > 0 ? (
                                <tr>
                                  {Object.entries(schedules).map(([day, classes]) => (
                                    <td key={day}>
                                      {classes.length > 0 ? (
                                        classes.map((schedule, index) => (
                                          <div
                                            key={index}
                                            data-bs-toggle="modal"
                                            data-bs-target="#confirm-class"
                                            className="class-item"
                                            onClick={() => setSelectedClassItem(schedule)}
                                          >
                                            <h6>{schedule.title}</h6>
                                            <p>
                                              {schedule.hourStart} - {schedule.hourEnd}
                                            </p>
                                            {schedule.trainerName || "Entrenador sin asignar"}
                                          </div>
                                        ))
                                      ) : (
                                        <div className="class-item">
                                          <p>No hay clases</p>
                                        </div>
                                      )}
                                    </td>
                                  ))}
                                </tr>
                              ) : (
                                <tr>
                                  <td colSpan={Object.keys(schedules).length}>No hay clases disponibles</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </form>
          </div>
        </div>
      </StickyBox>
      <ConfirmClassModal selectedClassData={selectedClassItem} onConfirmSuccess={onUpdateSchedules} />
    </>
  );
};

export default CustomerBookingCalendar;
