import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import DashWidget from '../../common/dashWidget/dashWidget';
import { apiRepository } from '../../../../api/apiRepository';
import {
  InputField,
  CalendarField,
} from '../../../admin/common/input/inputField';
import { validateUser, ValidationError } from '../../../../core/form/validationForm';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { handleChange, formatTo12Hour, formatDate } from '../../../../core/utils/utils';
import { formatDateToDayMonth } from '../../../../core/utils/dateUtils';
import { useOutletContext } from 'react-router-dom';
import { AuthContext } from '../../../../core/context/AuthContext';
import { convertToLocalDateString } from '../../../../core/utils/dateUtils';
import moment from 'moment-timezone';

const CustomerDashboard = () => {
  const [totalRecords, setTotalRecords] = useState(0);
  const [classesToday, setClassesToday] = useState(0);
  const [nextClass, setNextClass] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    lastname: '',
    dni: '',
    phone: '',
    email: '',
    birthdate: null,
    password: '',
  });
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const [loading, setLoading] = useState(false);
  const routes = all_routes
  const { setBreadcrumbData } = useOutletContext();
  const authContext = useContext(AuthContext);
  if (!authContext) {
    return <div>Error: Auth context not found!</div>;
  }

  const { user, updateUserData } = authContext;

  useEffect(() => {
    setBreadcrumbData({ title: "Editar Perfil", item1: "Perfil", item2: "Editar Perfil" });
  }, [setBreadcrumbData]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    handleChange(e, setFormData);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      try {
        const response = await apiRepository.getIdUsers(user.userId);
        const userData = response.data.data;
        setFormData({
          id: userData.id || '',
          name: userData.name || '',
          lastname: userData.lastname || '',
          dni: userData.dni || '',
          phone: userData.phone || '',
          email: userData.email || '',
          password: userData.password || '',
          profile: userData.profile || '',
          active: userData.active || '',
          birthdate: new Date(userData.birthdate) || null,
        });
      } catch (error: any) {
        console.error('Error al cargar los datos del usuario:', error);
        const errorMessage = error.response?.data?.message || 'Error al procesar la solicitud.';
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const updatePerfil = async (e: React.FormEvent) => {
    e.preventDefault();
    const validationErrors = validateUser(formData);

    if (validationErrors.length > 0) {
      setErrors(validationErrors);
      return;
    }

    const formattedFormData = {
      ...formData,
      birthdate: formatDate(formData.birthdate),
    };


    setLoading(true);
    try {
      await apiRepository.updateUser(user?.userId, formattedFormData);
      setErrors([]);
      validationErrors.push({ field: "success", message: '¡La actualización de datos fue exitosa!' });
      setErrors(validationErrors);
      updateUserData({
        username: formData.name,
        role: formData.role,
      });

    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.message) {
        const cleanedMessage = error.response.data.message.replace(/^Bad request: /, '');
        validationErrors.push({ field: "server", message: cleanedMessage });
      } else {
        validationErrors.push({ field: "server", message: 'Ocurrió un error al intentar iniciar sesión. Inténtalo de nuevo.' });
      }
      setErrors(validationErrors);

    } finally {
      setLoading(false);
    }
  };

  const fetchSchedules = async () => {
    setLoading(true);
    try {
      let response;

      if (user?.role === "trainer") {
        response = await apiRepository.getTrainerSchedules(user?.userId);
      } else {
        response = await apiRepository.getUserSchedules(user?.userId);
      }

      const schedules = response.data.data;
      setTotalRecords(schedules.length);

      const today = convertToLocalDateString(new Date());
      const todayClasses = schedules.filter((cls) => cls.date === today);
      setClassesToday(todayClasses.length);

      const futureClassesToday = todayClasses
        .filter((cls) => {
          const classDateTime = moment.tz(`${cls.date}T${cls.hourStart}`, 'America/Lima');
          const now = moment.tz('America/Lima');
          return classDateTime.isAfter(now) || classDateTime.isSame(now, 'minute');
        })
        .sort((a, b) => {
          const dateA = moment.tz(`${a.date}T${a.hourStart}`, 'America/Lima');
          const dateB = moment.tz(`${b.date}T${b.hourStart}`, 'America/Lima');
          return dateA.diff(dateB);
        });

      if (futureClassesToday.length > 0) {
        const next = futureClassesToday[0];
        const classDateTime = moment.tz(`${next.date}T${next.hourStart}`, 'America/Lima');
        const now = moment.tz('America/Lima');

        if (classDateTime.isAfter(now)) {
          setNextClass({
            ...next,
            dateFormatted: formatDateToDayMonth(next.date),
            hourStartFormatted: formatTo12Hour(next.hourStart),
            hourEndFormatted: formatTo12Hour(next.hourEnd),
          });
        } else {
          setNextClass(null);
        }
      } else {
        setNextClass(null);
      }
    } catch (error) {
      console.error('Error al obtener los datos de usuarios:', error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchSchedules();
  }, []);

  const widgets = [
    {
      iconClass: 'ti ti-chart-line',
      bgClass: 'bg-primary-transparent',
      title: 'Clases Totales',
      value: totalRecords
        ? `${totalRecords}`
        : 'Sin clases agendadas',
      duration: 1,
      link: `${routes.customerCalendar}?type=dayGridMonth`
    },
    {
      iconClass: 'ti ti-calendar',
      bgClass: 'bg-secondary-transparent',
      title: 'Clases de hoy',
      value: classesToday
        ? `${classesToday}`
        : 'Sin clases hoy',
      duration: 2,
      link: `${routes.customerCalendar}?type=timeGridWeek`
    },
    {
      iconClass: 'ti ti-alarm',
      bgClass: 'bg-success-transparent',
      title: 'Siguiente Clase',
      value: nextClass
        ? nextClass.dateFormatted
        : 'Sin siguiente clase',
      duration: 2,
      hourStart: nextClass ? nextClass.hourStartFormatted : undefined,
      hourEnd: nextClass ? nextClass.hourEndFormatted : undefined,
      link: `${routes.customerCalendar}?type=timeGridDay`

    },
  ];

  return (
    <>
      <h4 className="mb-3">Dashboard</h4>
      <div className="row mb-2">
        {widgets.map((widget, index) => (
          <DashWidget key={index} {...widget} />
        ))}
      </div>
      <div className="row">
        <h4 className="mb-3">Información</h4>
        <form onSubmit={updatePerfil}>
          <div className="row mb-4">
            <div className="col-md-6">
              <InputField
                label="Nombre"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                error={errors.find((err) => err.field === 'name')?.message}
              />
            </div>
            <div className="col-md-6">
              <InputField
                label="Apellido"
                name="lastname"
                value={formData.lastname}
                onChange={handleInputChange}
                error={errors.find((err) => err.field === 'lastname')?.message}
              />
            </div>
            <div className="col-md-6">
              <InputField
                label="DNI"
                name="dni"
                value={formData.dni}
                onChange={handleInputChange}
                error={errors.find((err) => err.field === 'dni')?.message}
              />
            </div>
            <div className="col-md-6">
              <InputField
                label="Telefono"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                error={errors.find((err) => err.field === 'phone')?.message}
              />
            </div>
            <div className="col-md-6">
              <InputField
                label="Correo"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                error={errors.find((err) => err.field === 'email')?.message}
              />
            </div>
            <div className="col-md-6">
              <CalendarField
                label="Fecha de nacimiento"
                name="birthdate"
                value={formData.birthdate}
                onChange={(e) => setFormData({ ...formData, birthdate: e.value })}
                error={errors.find((err) => err.field === 'birthdate')?.message}
              />
            </div>
            <div className="col-md-6">
              <InputField
                type='password'
                label="Nueva Contraseña"
                name='password'
                value={formData.password}
                onChange={handleInputChange}
                error={errors.find((err) => err.field === 'password')?.message}
              />
            </div>
            <div className="col-md-6">
              <InputField
                type='password'
                label="Confirmar Contraseña"
                name='newPassword'
                value={formData.newPassword}
                onChange={handleInputChange}
                error={errors.find((err) => err.field === 'newPassword')?.message}
              />
            </div>
            {errors.find(error => error.field === 'server') && (
              <div className="alert alert-danger" role="alert">
                {errors.find(error => error.field === 'server')?.message}
              </div>
            )}
            {errors.find(error => error.field === 'success') && (
              <div className="alert alert-success" role="alert">
                {errors.find(error => error.field === 'success')?.message}
              </div>
            )}
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <button type="submit" className="btn btn-dark" disabled={loading}>
              {loading ? 'Guardando...' : 'Guardar Cambios'}
            </button>
          </div>
        </form>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default CustomerDashboard;