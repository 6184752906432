type ValidationError = { field: string; message: string };

type ApiRepositoryType<T> = {
    create: (data: T) => Promise<void>;
    update: (id: number, data: T) => Promise<void>;
};

export async function handleFormSubmit<T>(
    id: number,
    data: T,
    validate: (data: T) => ValidationError[],
    apiRepository: ApiRepositoryType<T>,
    onSuccess: () => void,
    formatData?: (data: T) => T,
    errorMessage = "Ocurrió un error al intentar guardar los datos. Inténtalo de nuevo.",
    useFormData = false
) {
    const validationErrors = validate(data);
    if (validationErrors.length > 0) {
        return validationErrors;
    }

    const formattedData = formatData ? formatData(data) : data;

    let dataToSend = formattedData;
    if (useFormData) {
        dataToSend = new FormData();
        for (const key in formattedData) {
            if (Object.prototype.hasOwnProperty.call(formattedData, key)) {
                if (formattedData[key] instanceof File) {
                    dataToSend.append(key, formattedData[key]);
                } else {
                    dataToSend.append(key, formattedData[key]);
                }
            }
        }
    }

    try {
        if (id == null || id === -1) {
            await apiRepository.create(dataToSend);
        } else {
            await apiRepository.update(id, dataToSend);
        }
        onSuccess();
    } catch (error) {
        const errors: ValidationError[] = [];
        if (error.response && error.response.data && error.response.data.message) {
            const cleanedMessage = error.response.data.message.replace(/^Internal server error: /, '');
            errors.push({ field: "server", message: cleanedMessage });
        } else {
            errors.push({ field: "server", message: errorMessage });
        }
        return errors;
    }
}