import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import DefaultEditor from 'react-simple-wysiwyg';
import { ValidationError } from '../../../core/form/validationForm';
import { Link } from 'react-router-dom';
import { apiRepository } from '../../../api/apiRepository';

const EditLegal = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get('id');
  const [title, setTitle] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const [loading, setLoading] = useState<number | null>(null);

  useEffect(() => {
    const loadData = async () => {
      setErrors([]);
      try {
        const response = await apiRepository.getPage(id);
        if (response) {
          setDescription(response.data.data.description);
          if (id === "1") {
            setTitle("Terminos y Condiciones");
          } else if (id === "2") {
            setTitle("Politica de Privacidad");
          } else {
            setTitle(response.data.data.title);
          }
        }
      } catch (error) {
        console.error('Error al cargar los datos:', error);
      }
    };
    loadData();
  }, [id]);

  function onChange(e: any) {
    setDescription(e.target.value);
  }

  const handleUpdate = async () => {
    const Pages = { id, description };

    try {
      setLoading(true)
      await apiRepository.updatePage(id, Pages);
      errors?.push({field: "success", message: "¡Condiciones actualizadas con exito!"})
    } catch (error) {
      errors?.push({field: "success", message: "¡Condiciones actualizadas con exito!"})
    } finally {
      setLoading(false)
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="row">
          <div className="col-lg-11 col-sm-12 m-auto">
            <div className="content-page-header">
              <h5>Editar términos legales</h5>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group">
                  <label>Título</label>
                  <input
                    type="text"
                    className="form-control"
                    value={title}
                    readOnly
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group">
                  <label> Descripción</label>
                  <div id="editor">
                    <DefaultEditor value={description} onChange={onChange} />
                  </div>
                </div>
                {errors.find((value) => value.field === 'success') && (
                <div className="alert alert-success mt-3 mb-3" role="alert">
                  {errors.find((value) => value.field === 'success')?.message}
                </div>
              )}
              {errors.find((value) => value.field === 'error') && (
                <div className="alert alert-danger mt-3 mb-3" role="alert">
                  {errors.find((value) => value.field === 'error')?.message}
                </div>
              )}
              </div>

              <div className="col-lg-12">
                <div className="btn-path">
                  <Link to="#" className="btn btn-cancel me-3">
                    Cancelar
                  </Link>
                  <button
                    className="btn btn-primary-admin"
                    type="button"
                    onClick={handleUpdate}
                  >
                    {loading ? (
                      <>
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> 
                        Guardando...
                      </>
                    ) : (
                      'Guardar cambios'
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditLegal;