import Slider from "react-slick";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CartContext } from '../../../../core/context/CartContext';
import { AuthContext } from '../../../../core/context/AuthContext';
import { getCart } from '../../../../core/services/cartService';
import { all_routes } from '../../../../core/data/routes/all_routes';

interface Package {
  title: string;
  price: string;
  description: string;
  features: string[];
  id: string;
}

interface PackageSliderProps {
  packages: Package[];
  sliderOptions: any;
}

const PackageSlider: React.FC<PackageSliderProps> = ({ packages, sliderOptions }) => {
  const routes = all_routes;
  const cartContext = useContext(CartContext);
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const { cart, addToCart, setCart } = cartContext;
  const [loadingPlan, setLoadingPlan] = useState<number | null>(null);

  useEffect(() => {
    const currentCart = getCart(user?.userId);
    setCart(currentCart);
  }, [setCart]);

  const handleAddToCart = (packageId: number) => {
    setLoadingPlan(packageId);
    setTimeout(() => {
      addToCart(packageId, setCart);
      setLoadingPlan(null);
    }, 1000);
  };

  const isSlider = packages.length > 4;
  const renderPackage = (pkg: Package, index: number) => (
    <div className="col-lg-3 col-md-6 col-12" key={index}>
      <div className={`hidden-charge-main pricing-plans price-new ${index === 2 ? "hidden-charge-main-two" : ""}`}>
        <h4>{pkg.title}</h4>
        <div className="pricing-planscontent features-slide col-lg-12">
          <ul>
            {pkg.description.split('|').map((feature: string, i: number) => (
              <li key={i}>
                <i className="fa fa-check-circle me-2" />
                <span>{feature.trim()}</span> {/* Usar trim() para quitar espacios extras */}
              </li>
            ))}
          </ul>
        </div>
        <div className="hidden-charges-bottom-mark text-center">
          <h1>S/ {pkg.price}</h1>
          <h6>{pkg.subtitle}</h6>
        </div>
        {cart.some(item => item.id === pkg.id) ? (
          <Link to={routes.customerCart} className="w-100 btn btn-view">
              <i className="fa fa-check me-2 ms-0"></i>

              Ver Carrito
          </Link>
        ) : (
          <Link
            to="#"
            className={`btn w-100 btn-view ${loadingPlan === pkg.id ? 'disabled' : ''}`}
            onClick={(e) => {
              if (loadingPlan === pkg.id) {
                e.preventDefault();  // Evita la acción de navegar si está "deshabilitado"
              } else {
                handleAddToCart(pkg.id);  // Si no está deshabilitado, ejecuta la acción
              }
            }}
          >
          {loadingPlan === pkg.id ? (
            <i className="fa fa-spinner fa-spin ms-0"></i>
            ) : (
            'Reservar ahora'
          )}
          </Link>
        )}
      </div>
    </div>
  );

  return (
    <>
      {isSlider ? (
        <Slider {...sliderOptions} className="slick-slider slide-arrows product-service owl-carousel nav-center">
          {packages.map(renderPackage)}
        </Slider>
      ) : (
        <div className="row">
          {packages.map(renderPackage)}
        </div>
      )}
    </>
  );
};

export default PackageSlider;