import React, { useState, useEffect, useContext } from 'react';
import { apiRepository } from '../../../api/apiRepository';
import { ValidationError } from '../../../core/form/validationForm';
import { DropdownField } from '../../admin/common/input/inputField';
import { useLocation } from 'react-router-dom';
import { CLASS_STATUS } from '../../../core/data/options/options';
import { AuthContext } from '../../../core/context/AuthContext';

const ClassAttendanceManagement = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    return <div>Error: Auth context not found!</div>;
  }

  const [classes, setClasses] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [schedulesClass, setSchedulesClass] = useState([]);
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [scheduleId, setScheduleId] = useState(queryParams.get('id'));
  const { user } = authContext;


  const isValidStatus = schedules.status === 2 || schedules.status === 3;

  const fetchClasses = async () => {
    try {
      const response = await apiRepository.getReservation(scheduleId);
      setClasses(response.data.data);
    } catch (error) {
      console.error('Error al obtener los datos de la asistencia:', error);
    }
  };

  const fetchSchedules = async () => {
    try {
      const response = await apiRepository.getIdSchedules(scheduleId);
      const schedules = response.data.data
      setSchedules(schedules);
    } catch (error) {
      console.error('Error al obtener los datos de los horarios:', error);
    }
  };

  const fetchSchedulesList = async () => {
    try {
      let response;

      if (user?.role === "trainer") {
        response = await apiRepository.getTrainerSchedules(user?.userId);
      } else {
        response = await apiRepository.getUserSchedules(user?.userId);
      }

      const schedulesClass = response.data.data.map(schedule => ({
        value: schedule.id,
        label: schedule.title || schedule.name,
      }));
      setSchedulesClass(schedulesClass);
    } catch (error) {
      console.error('Error al obtener los datos de usuarios:', error);
    }
  };

  useEffect(() => {
    fetchSchedulesList();
    if (scheduleId) {
      fetchClasses();
      fetchSchedules();
    }
  }, []);

  useEffect(() => {
    if (scheduleId) {
      fetchClasses();
      fetchSchedules();
    }
  }, [scheduleId]);

  const handleStatusUpdate = async (message = true) => {
    try {
      setLoading(true);
      setErrors([])
      await apiRepository.updateSchedule(scheduleId, schedules);
      if (message) {
        const validationErrors = []
        validationErrors.push({ field: 'succesStatus', message: '¡Estado actualizado con exito!' });
        setErrors(validationErrors);
      }
    } catch (error: any) {
      const validationErrors = []
      console.error('Error al actualizar el estado:', error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        const cleanedMessage = error.response.data.message.replace(
          /^Error interno del servidor: /,
          '',
        );
        validationErrors.push({ field: 'status', message: cleanedMessage });
      } else {
        validationErrors.push({
          field: 'status',
          message:
            'Ocurrió un error al intentar iniciar sesión. Inténtalo de nuevo.',
        });
      }
      setErrors(validationErrors);
    } finally {
      setLoading(false);
    }
  };

  const handleStatusChange = (event) => {
    const newStatus = event.target.value; // Captura el valor seleccionado
    setSchedules({ ...schedules, status: newStatus }); // Actualiza el estado local
  };

  const handleCheckboxToggle = (id) => {
    setClasses(classes.map(classItem =>
      classItem.id === id ? { ...classItem, attended: !classItem.attended } : classItem
    ));
  };

  const handleSubmit = async () => {
    setErrors([])
    if (!isValidStatus) {
      setErrors([{ field: 'assist', message: 'Para confirmar la asistencia, el estado debe ser "Atención" o "Finalizada".' }]);
      return;
    }

    const attendedCustomers = classes
      .filter(classItem => classItem.attended)
      .map(classItem => classItem.customerId);

    const payload = {
      scheduleId,
      customerIds: attendedCustomers
    };
    try {
      setLoading(true);
      handleStatusUpdate(false)
      await apiRepository.assistanceReservation(payload);
      const validationErrors = []
      validationErrors.push({ field: 'assistSuccess', message: '¡Asistencia enviada con exito!' });
      setErrors(validationErrors)
    } catch (error) {
      console.error('Error al enviar los datos:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
        <div className="page-wrapper page-settings">
            <div className="content">
        <h4 className="mb-2">Administrar Clase</h4>
        {/* <DropdownField
          className="mb-3"
          value={Number(scheduleId)}
          options={schedulesClass}
          onChange={(e) => { setScheduleId(e.value); }}
          placeholder="Selecciona un horario de clase"
        /> */}

      {scheduleId && (
        <>
          <div className="card review-item mb-3">
            <div className="card-body">
              <div className="review-info">
                <div className="row align-items-center justify-content-between row-gap-3">
                  <h6 className="mb-0">Selecciona el estado de la clase {schedules.title}</h6>
                  <div className="col-12 col-md-auto">
                    <DropdownField
                      className={`mb-0 ${schedules.status !== 1 ? "mb-2" : ""}`}
                      options={CLASS_STATUS}
                      value={schedules.status}
                      onChange={handleStatusChange}
                      placeholder="Selecciona el estado de la clase"
                    />
                    {schedules.status !== 1 && (
                      <small className="text-gray mb-2">
                        Al seleccionar esta opción, los usuarios ya no podrán inscribirse.
                      </small>
                    )}
                  </div>
                  <div className="col-12 col-md-auto filter-buttons">
                    <button className="btn btn-primary-admin w-100" onClick={handleStatusUpdate}>
                      {loading ? <i className="fa fa-spinner fa-spin me-2"></i> : <i className="fa fa-cog me-2" />}
                      Enviar estado de clase
                    </button>
                  </div>

                </div>
                {errors.find((error) => error.field === 'succesStatus') && (
                  <div className="col-12 alert alert-success mb-0 mt-3" role="alert">
                    {errors.find((error) => error.field === 'succesStatus')?.message}
                  </div>
                )}
                {errors.find((error) => error.field === 'status') && (
                  <div className="col-12 alert alert-danger mb-0 mt-3" role="alert">
                    {errors.find((error) => error.field === 'status')?.message}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="card review-item mb-3">
            <div className="card-body">
              <div className="review-info">
                <div className="row align-items-start justify-content-between row-gap-3">
                  <h6 className="mb-0">Completa la lista de asistencia</h6>
                  <div className="reservation">
                    <ul className="list-group">
                      {classes.length === 0 ? (
                        <li className="list-group-item p-3 text-center">
                          Sin registros
                        </li>
                      ) : (
                        classes.map((classItem) => (
                          <li
                            key={classItem.id}
                            className="list-group-item item-select p-3"
                            onClick={() => handleCheckboxToggle(classItem.id)}
                            style={{ cursor: 'pointer' }}
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <h6>{classItem.customerName}</h6>
                                <p className="mt-1">{classItem.titleClass}</p>
                              </div>
                              <div>
                                <input
                                  type="checkbox"
                                  checked={classItem.attended}
                                  onChange={() => handleCheckboxToggle(classItem.id)}
                                  className="form-check-input"
                                />
                                <label className="ms-2">
                                  Asistió
                                </label>
                              </div>
                            </div>
                          </li>
                        ))
                      )}
                    </ul>

                    {errors.find((error) => error.field === 'assistSuccess') && (
                      <div className="alert alert-success mt-4" role="alert">
                        {errors.find((error) => error.field === 'assistSuccess')?.message}
                      </div>
                    )}
                    {errors.find((error) => error.field === 'assist') && (
                      <div className="alert alert-danger mt-4" role="alert">
                        {errors.find((error) => error.field === 'assist')?.message}
                      </div>
                    )}
                    <button className="btn btn-success mt-4" onClick={handleSubmit} disabled={loading | classes.length === 0}>
                      {loading ? <i className="fa fa-spinner fa-spin me-2"></i> : <i className="fa fa-list-check  me-2" />}
                      Enviar Asistencia
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      </div>
      </div>
    </>
  )
};

export default ClassAttendanceManagement;