import React, { useState, useEffect } from 'react';
import { apiRepository } from '../../../../api/apiRepository';
import { EnterpriseInformation, Subscriptions } from '../../../../core/Interface/repositoryInterfaces';
import { ValidationError } from '../../../../core/form/validationForm';
import { Link } from 'react-router-dom';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { handleChange } from '../../../../core/utils/utils';
import { getErrorMessage } from '../../../../core/utils/errorUtils';

const FooterOne = () => {
  const routes = all_routes
  const [enterpriseData, setEnterpriseData] = useState<EnterpriseInformation>([]);
  const [subscriptionData, setSubscriptionData] = useState<Subscriptions>([]);
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const [loading, setLoading] = useState<number | null>(null);

  const fetchEnterpriseInformation = async () => {
    try {
      const response = await apiRepository.getEnterpriseInformation();
      setEnterpriseData(response.data.data);
    } catch (error) {
      console.error("Error al obtener los datos de la empresa:", error);
    }
  };

  const createSubscriptionData = async () => {
    try {
      const info = []
      setLoading(true);
      if (!subscriptionData.fullname || subscriptionData.fullname.trim() === "") {
        info.push({field:"server", message: "El campo del nombre es obligatorio."})
        setErrors(info)
        return;
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!subscriptionData?.email){
        info.push({field:"server", message: "El campo del correo es obligatorio."})
        setErrors(info)
        return
      } else if (!emailRegex.test(subscriptionData.email)) {
        info.push({field:"server", message: "Por favor, ingresa un email válido."})
        setErrors(info)
        return;
      }

      const response = await apiRepository.createSubscriptions(subscriptionData);

      if (response.status) {
        info.push({field:"success", message: "¡Suscripción hecha con exito!"})
        setErrors(info)
      }

    } catch (error) {
        const info = []
        info.push({field:"server", message: "La suscripción no se pudo enviar."})
      } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchEnterpriseInformation();
  }, []);

  return (
    <footer className="footer footer-second ">
      {/* Footer Top */}
      <div className="footer-top" >
        <div className="container col-11">
          <div className="row flex-lg-row flex-md-column">
            <div className="col-lg-3 col-md-6">
              {/* Footer Widget */}
              <div className="footer-widget">
                <div className="footer-logo">
                  <Link to={routes.index}>
                    <ImageWithBasePath src="assets/img/logo-lift.svg" alt="logo" />
                  </Link>
                </div>
                <div className="footer-content">
                  { enterpriseData?.phone && (
                    <div>
                      <p className="fs-16">
                        <span className="ti ti-device-mobile me-2" />
                        { enterpriseData?.phone }
                      </p>
                    </div>
                  )}
                  { enterpriseData?.phone && (
                    <div>
                      <p className="fs-16">
                        <span className="ti ti-device-mobile me-2" />
                        { enterpriseData?.email }
                        </p>
                    </div>
                  )}
                </div>

              </div>
              {/* /Footer Widget */}
            </div>
            <div className="col-lg-2 col-md-4">
              {/* Footer Widget */}
              <div className="footer-widget footer-menus">
                <h2 className="footer-title">Paginas</h2>
                <ul>
                  <li>
                    <Link to={routes.index}>Home</Link>
                  </li>
                  <li>
                    <Link to={routes.categories}>Todas los servicios</Link>
                  </li>
                  <li>
                    <Link to={routes.contactUs}>Contactanos</Link>
                  </li>
                </ul>
              </div>
              {/* /Footer Widget */}
            </div>
            <div className="col-lg-2 col-md-4">
              {/* Footer Widget */}
              <div className="footer-widget footer-menus">
                <h2 className="footer-title">Ayuda</h2>
                <ul>
                  <li>
                    <Link to={routes.faq}>FAQS</Link>
                  </li>
                  <li>
                    <Link to={routes.complaintBook}>Libro de reclamaciones</Link>
                  </li>
                </ul>
              </div>
              {/* /Footer Widget */}
            </div>
            <div className="col-lg-2 col-md-4">
              {/* Footer Widget */}
              <div className="footer-widget footer-menus">
              <h2 className="footer-title">Siguenos en:</h2>
                <div className="social-icon">
                  <ul className="mb-0">
                    { enterpriseData?.facebook && (
                      <li>
                        <Link to={enterpriseData.facebook}>
                          <i className="fa-brands fa-facebook ms-1" />{" "}
                        </Link>
                      </li>
                    )}
                    { enterpriseData?.instagram && (
                      <li>
                        <Link to={enterpriseData.instagram}>
                          <i className="fa-brands fa-instagram" />
                        </Link>
                      </li>
                    )}
                    { enterpriseData?.youtube && (
                      <li>
                        <Link to={enterpriseData.youtube}>
                          <i className="fa-brands fa-youtube" />
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
              {/* /Footer Widget */}
            </div>
            <div className="col-lg-3 col-md-6">
              {/* Footer Widget */}
              <div className="footer-widget">
                <h2 className="footer-subtitle">Suscribete</h2>
                <div className="subscribe-form mb-4">
                  <div className="row">
                    <div className="col-12">
                      <input
                        type="text"
                        value={subscriptionData?.fullname}
                        onChange={(e) => setSubscriptionData({ ...subscriptionData, fullname: e.target.value })}
                        className="form-control"
                        placeholder="Ingresa tu nombre completo"
                      />
                    </div>
                    <div className="col">
                      <input
                        type="email"
                        value={subscriptionData?.email}
                        onChange={(e) => setSubscriptionData({ ...subscriptionData, email: e.target.value })}
                        className="form-control mt-3"
                        placeholder="Ingresa tu correo"
                      />
                    </div>
                    <div className="col-2 justify-content-end d-flex">
                      <button onClick={createSubscriptionData} className="btn btn-primary mt-3">
                        <i className="fa fa-paper-plane" />
                      </button>
                    </div>
                    {errors.find(error => error.field === 'server') && (
                      <small className="text-danger mt-2">{getErrorMessage(errors, "server")}</small>
                    )}
                    {errors.find(error => error.field === 'success') && (
                      <small className="text-success mt-2">{getErrorMessage(errors, "success")}</small>
                    )}
                  </div>
                </div>
              </div>
              {/* /Footer Widget */}
            </div>
          </div>
        </div>
      </div>
      {/* /Footer Top */}
      {/* Footer Bottom */}
      <div className="footer-bottoms">
        <div className="container col-11">
          {/* Copyright */}
          <div className="copyright">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-6">
                <div className="copyright-text">
                  <p className="mb-0 fs-14">
                    © 2024 Lift. Todos los derechos reservados.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                {/* Copyright Menu */}
                <div className="copyright-menu">
                  <ul className="policy-menu">
                    <li>
                      <Link to={routes.privacyPolicy}>Politica de Privacidad</Link>
                    </li>
                    <li>
                      <Link to={routes.termsCondition}>Terminos y Condiciones</Link>
                    </li>
                  </ul>
                </div>
                {/* /Copyright Menu */}
              </div>
            </div>
          </div>
          {/* /Copyright */}
        </div>
      </div>
      {/* /Footer Bottom */}
    </footer>

  );
};

export default FooterOne;
