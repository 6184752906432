import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';

interface DataTableWrapperProps {
    data: any[];
    columns: any[];
    onEdit?: (rowData: any) => void;
    onDelete?: (rowData: any) => void;
    onCustomAction?: (rowData: any) => void;
    customIcon?: React.ElementType;
    customTitle?: string;
    paginator?: boolean;
    rowsPerPage?: number;
    totalRecords?: number;
    emptyMessage?: string;
    sortField?: string;
    sortOrder?: number;
    first?: number;
    lazy?: boolean;
    onPageChange?: (event: any) => void;
    showGridlines?: boolean;
}

const DataTableWrapper: React.FC<DataTableWrapperProps> = ({
    data,
    columns,
    onEdit,
    onDelete,
    onCustomAction,
    customIcon: CustomIcon,
    customTitle = 'Custom Action',
    paginator = true,
    rowsPerPage = 10,
    totalRecords = 0,
    emptyMessage = "No se encontraron registros.",
    first = 0,
    sortField = '',
    sortOrder = null,
    lazy = true,
    onPageChange,
    showGridlines = true
}) => {

    return (
        <div className="row">
            <div className="col-12">
                <div className="table-responsive table datatable">
                    <DataTable
                        key={data.length}
                        value={data}
                        paginator={paginator}
                        rows={rowsPerPage}
                        totalRecords={totalRecords}
                        lazy={lazy}
                        first={first}
                        onPage={onPageChange}
                        showGridlines={showGridlines}
                        emptyMessage={emptyMessage}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        tableStyle={{ minWidth: '50rem' }}
                        sortField={sortField}
                        sortOrder={sortOrder}
                    >
                        {columns.map((col, index) => (
                            <Column
                                key={index}
                                field={col.field}
                                header={col.header}
                                body={col.body}
                                sortable={col.sortable}
                            />
                        ))}

                        {(onEdit || onDelete || onCustomAction) && (
                            <Column
                                body={(rowData) => (
                                    <div className="table-actions d-flex">
                                        {onEdit && (
                                            <Link
                                                to="#"
                                                onClick={() => onEdit(rowData)}
                                                className="delete-table me-2"
                                            >
                                                <Icon.Edit className="react-feather-custom" />
                                            </Link>
                                        )}
                                        {onDelete && (
                                            <Link
                                                to="#"
                                                onClick={() => onDelete(rowData)}
                                                className="delete-table me-2"
                                            >
                                                <Icon.Trash2 className="react-feather-custom" />
                                            </Link>
                                        )}
                                        {onCustomAction && CustomIcon && (
                                            <button
                                                onClick={() => onCustomAction(rowData)}
                                                className="delete-table me-2 border-0 outline-0"
                                                title={customTitle}
                                            >
                                                <CustomIcon className="react-feather-custom" />
                                            </button>
                                        )}
                                    </div>
                                )}
                            />
                        )}
                    </DataTable>
                </div>
            </div>
        </div>
    );
};

export default DataTableWrapper;