import React, { useState, useEffect } from 'react';
import { apiRepository } from '../../../../api/apiRepository';
import { PaymentDays } from '../../../../core/Interface/repositoryInterfaces';
import { validatePaymentDays, ValidationError } from '../../../../core/form/validationForm';
import { InputField, CalendarField, DropdownField, SwitchField } from '../input/inputField';
import { handleChange, formatDate, dateStandard } from '../../../../core/utils/utils';
import { getErrorMessage } from '../../../../core/utils/errorUtils';
import { handleFormSubmit } from '../../../../core/services/formService';
import BaseModal from './baseModal';

export interface UpdateCouponModalProps {
    selectedPaymentDays: PaymentDays;
    onUpdateSuccess?: () => void;
}

const CouponesModal: React.FC<UpdateCouponModalProps> = ({ selectedPaymentDays, onUpdateSuccess }) => {
    const [id, setId] = useState<number>(selectedPaymentDays ? selectedPaymentDays.id : -1);
    const [couponData, setCouponData] = useState<PaymentDays>({} as PaymentDays);
    const [errors, setErrors] = useState<ValidationError[]>([]);
    const [show, setShow] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
        handleChange(e, setCouponData);
    };

    const handleClose = () => {
        setShow(false);
        setErrors([]);
        setCouponData({});
        setId(-1);
    };

    useEffect(() => {
        if (selectedPaymentDays) {
            setCouponData({
                userName: selectedPaymentDays.userName,
                userId: selectedPaymentDays.userId,
                days: selectedPaymentDays.quantity,
                endDate: dateStandard(selectedPaymentDays.endDate),
                type: selectedPaymentDays.type,
                status: selectedPaymentDays.status,
            });
            setId(selectedPaymentDays.id);
            setShow(true);
        }
    }, [selectedPaymentDays]);

    const handleConfirmCreate = async (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);

        const errors = await handleFormSubmit(
            id,
            couponData,
            validatePaymentDays,
            {
                create: apiRepository.createPaymentDay,
                update: apiRepository.updatePaymentDay,
            },
            () => {
                if (onUpdateSuccess) onUpdateSuccess();
                handleClose();
            },
            (data) => ({
                ...data,
                endDate: formatDate(data.endDate),
            }),
            "Ocurrió un error al intentar guardar el cupón. Inténtalo de nuevo."
        );

        if (errors) {
            console.debug(errors, 'errorserrors')
            setErrors(errors);
        }

        setLoading(false);
    };

    return (
        <>
            <BaseModal
                show={show}
                title="Actualizar Cupón"
                onClose={handleClose}
                onConfirm={handleConfirmCreate}
                loading={loading}
            >
                <form onSubmit={handleConfirmCreate}>
                    <div className="row">
                        <div className="col-md-6">
                            <InputField
                                label="Usuario"
                                value={couponData.userName}
                            />
                        </div>
                        <div className="col-md-6">
                            <InputField
                                label="Cantidad"
                                name="days"
                                value={couponData.days}
                                onChange={handleInputChange}
                                error={getErrorMessage(errors, "days")}
                            />
                        </div>
                    </div>
                    <CalendarField
                        label="Fecha de Fin"
                        name="endDate"
                        value={couponData.endDate}
                        onChange={(e) => setCouponData({ ...couponData, endDate: e.value })}
                        error={getErrorMessage(errors, "endDate")}
                    />
                    <DropdownField
                        label="Tipo"
                        name="type"
                        options={[{ value: 'monthly', label: 'Mensual' }, { value: 'annual', label: 'Anual' }]}
                        value={couponData.type}
                        onChange={handleInputChange}
                        error={getErrorMessage(errors, "type")}
                    />
                    <SwitchField
                        label="Estado"
                        checked={couponData.status}
                        onChange={handleInputChange}
                        defaultChecked={true}
                        name="status"
                    />
                    {errors.find(error => error.field === 'server') && (
                        <div className="alert alert-danger" role="alert">
                            {getErrorMessage(errors, "server")}
                        </div>
                    )}
                </form>
            </BaseModal>
        </>
    );
};

export default CouponesModal;