import React, { useEffect, useContext } from 'react'
import ImageWithBasePath from '../../../../../core/img/ImageWithBasePath';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../../../core/context/AuthContext';

const PagesAuthHeader = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    return <div>Error: Auth context not found!</div>;
  }

  const { isAuthenticated } = authContext;
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [navigate]);

  return (
    <>
      {/* Header */}
      <div className="authentication-header">
        <div className="container">
          <div className="col-md-12">
            <div className="text-center">
              <div className="d-flex justify-content-center align-items-center flex-column">
                <Link to="/" aria-label="Ir al inicio" className="img-fluid">
                    <span className="  avatar avatar-m w-auto mt-2">
                        <ImageWithBasePath
                          src="assets/lift/brand/logo-lift.svg"
                          alt="logo"
                          className="img-fluid"
                        />
                    </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Header */}
    </>
  );
}

export default PagesAuthHeader;