import React from 'react';
import { Modal } from 'react-bootstrap';

interface BaseModalProps {
  show: boolean;
  title: string;
  onClose: () => void;
  onConfirm: () => void;
  children: React.ReactNode;
  loading: boolean;
}

const BaseModal: React.FC<BaseModalProps> = ({
  show,
  title,
  onClose,
  onConfirm,
  children,
  loading
}) => {
  return (
    <Modal show={show} onHide={onClose} centered size="lg">
      <Modal.Header closeButton className="pb-0">
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer className="d-flex justify-content-end">
        <button className="btn btn-secondary me-2" onClick={onClose}>Cerrar</button>
        <button
          className="btn btn-primary-admin"
          onClick={onConfirm}
          disabled={loading}
        >
          {loading ? (
            <>
              <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> 
              Guardando...
            </>
          ) : (
            'Guardar'
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default BaseModal;
