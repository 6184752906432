import React, { useEffect, useState } from 'react';
import { ValidationError } from '../../../core/form/validationForm';
import { Link } from 'react-router-dom';
import { apiRepository } from '../../../api/apiRepository';
import { EnterpriseInformation as EnterpriseInterface } from '../../../core/Interface/repositoryInterfaces';
import { ImageUploadField, InputField } from '../common/input/inputField';
import { handleChange } from '../../../core/utils/utils';
import { getErrorMessage } from '../../../core/utils/errorUtils';
import { handleFormSubmit } from '../../../core/services/formService';

const EnterpriseInformation = () => {
  const [enterpriseData, setEnterpriseData] = useState<EnterpriseInterface>([]);
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const [loading, setLoading] = useState<number | null>(null);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    handleChange(e, setEnterpriseData);
  };

  const fetchEnterpriseInformation = async () => {
    try {
      setLoading(true);
      const response = await apiRepository.getEnterpriseInformation();
      setEnterpriseData(response.data.data);
    } catch (error) {
      console.error("Error al obtener los datos de la empresa:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEnterpriseInformation();
  }, []);

  const handleUpdate = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    setErrors([]);

    const errors = await handleFormSubmit(
      enterpriseData.id,
      enterpriseData,
      () => [],
      {
        create: apiRepository.updateEnterpriseInformation,
        update: apiRepository.updateEnterpriseInformation,
      },
      () => {
        fetchEnterpriseInformation();
      },
      undefined,
      "Ocurrió un error al intentar guardar la información de la empresa. Inténtalo de nuevo.",
      true
    );

    if (errors?.length > 0) {
      setErrors(errors);
    } else {
      const info = [];
      info.push({ field: "success", message: "¡Información actualizada con éxito!" });
      setErrors(info);
    }

    setLoading(false);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    setEnterpriseData({ ...enterpriseData, image: file });
  };

  const handleRemoveImage = () => {
    setEnterpriseData({ ...enterpriseData, image: null });
    const fileInput = document.getElementById("imgInp") as HTMLInputElement;
    if (fileInput) {
      fileInput.value = "";
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="row">
          <div className="col-lg-11 col-sm-12 m-auto">
            <div className="content-page-header">
              <h3>Editar información de la empresa</h3>
            </div>
            <div className="row">
              <form onSubmit={handleUpdate}>
                <div className="col-md-6">
                  <ImageUploadField
                    label="Logo de la empresa"
                    formData={enterpriseData}
                    onFileChange={handleFileChange}
                    onRemoveImage={handleRemoveImage}
                    routeName="enterprise"
                    recommendedSize="500 x 500 píxeles"
                  />
                </div>
                <div className="col-md-6">
                  <InputField
                    label="Facebook"
                    name="facebook"
                    value={enterpriseData.facebook}
                    onChange={handleInputChange}
                    error={getErrorMessage(errors, 'facebook')}
                  />
                </div>
                <div className="col-md-6">
                  <InputField
                    label="Instagram"
                    name="instagram"
                    value={enterpriseData.instagram}
                    onChange={handleInputChange}
                    error={getErrorMessage(errors, 'instagram')}
                  />
                </div>
                <div className="col-md-6">
                  <InputField
                    label="YouTube"
                    name="youtube"
                    value={enterpriseData.youtube}
                    onChange={handleInputChange}
                    error={getErrorMessage(errors, 'youtube')}
                  />
                </div>
                <div className="col-md-6">
                  <InputField
                    label="Teléfono"
                    name="phone"
                    value={enterpriseData.phone}
                    onChange={handleInputChange}
                    error={getErrorMessage(errors, 'phone')}
                  />
                </div>
                <div className="col-md-6">
                  <InputField
                    label="Correo Electrónico"
                    name="email"
                    value={enterpriseData.email}
                    onChange={handleInputChange}
                    error={getErrorMessage(errors, 'email')}
                  />
                </div>
                {errors.find((value) => value.field === 'success') && (
                  <div className="alert alert-success mt-3 mb-3" role="alert">
                    {errors.find((value) => value.field === 'success')?.message}
                  </div>
                )}
                {errors.find((value) => value.field === 'error') && (
                  <div className="alert alert-danger mt-3 mb-3" role="alert">
                    {errors.find((value) => value.field === 'error')?.message}
                  </div>
                )}
                {errors.find(error => error.field === 'server') && (
                  <div className="alert alert-danger" role="alert">
                    {getErrorMessage(errors, "server")}
                  </div>
                )}
                <div className="col-lg-12">
                  <div className="btn-path">
                    <Link to="#" className="btn btn-cancel me-3">
                      Cancelar
                    </Link>
                    <button
                      className="btn btn-primary-admin"
                      type="submit"
                    >
                      {loading ? (
                        <>
                          <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                          Guardando...
                        </>
                      ) : (
                        'Guardar cambios'
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnterpriseInformation;