import React, { useState, useEffect } from 'react';
import { apiRepository } from '../../../../api/apiRepository';
import { Coupon } from '../../../../core/Interface/repositoryInterfaces';
import { validateCoupon, ValidationError } from '../../../../core/form/validationForm';
import { InputField, CalendarField, DropdownField, SwitchField } from '../input/inputField';
import { handleChange, dateStandard, formatDate  } from '../../../../core/utils/utils';
import { getErrorMessage  } from '../../../../core/utils/errorUtils';
import { handleFormSubmit } from '../../../../core/services/formService'
import { TYPE_DISCOUNT, TYPE_GENERAL_PRODUCT } from '../../../../core/data/options/options';
import BaseModal from './baseModal';

export interface UpdateCouponModalProps {
    selectedCoupon: Coupon;
    categoriesPackage?: [];
    onUpdateSuccess?: () => void;
}

const CouponesModal: React.FC<UpdateCouponModalProps> = ({ selectedCoupon, onUpdateSuccess, categoriesPackage }) => {
    const [id, setId] = useState<number>(selectedCoupon ? selectedCoupon.id : -1);
    const [couponData, setCouponData] = useState<Coupon>({} as Coupon);
    const [errors, setErrors] = useState<ValidationError[]>([]);
    const [show, setShow] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
        handleChange(e, setCouponData);
    };

    const handleClose = () => {
        setShow(false);
        setErrors([]);
        setCouponData([]);
        setId(-1);
    };

    useEffect(() => {
        if (selectedCoupon) {
            setCouponData({
                name: selectedCoupon.name,
                code: selectedCoupon.code,
                typeDiscount: selectedCoupon.typeDiscount,
                typeGeneralProduct: selectedCoupon.typeGeneralProduct,
                amount: selectedCoupon.amount,
                packetId: selectedCoupon.packetId,
                dateExpired: dateStandard(selectedCoupon.dateExpired),
                status: selectedCoupon.status,
            })
            setId(selectedCoupon.id);
            setShow(true);
        }
    }, [selectedCoupon]);

    const handleConfirmCreate = async (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true)

        const errors = await handleFormSubmit(
            id,
            couponData,
            validateCoupon,
            {
                create: apiRepository.createCoupon,
                update: apiRepository.updateCoupon,
            },
            () => {
                if (onUpdateSuccess) onUpdateSuccess();
                handleClose();
            },
            (data) => ({
                ...data,
                dateExpired: formatDate(data.dateExpired),
            }),
            "Ocurrió un error al intentar guardar el cupón. Inténtalo de nuevo."
        );

        if (errors) {
            setErrors(errors);
        }

        setLoading(false)
    };

    return (
        <>
            <BaseModal
                show={show}
                title="Cupón"
                onClose={handleClose}
                onConfirm={handleConfirmCreate}
                loading={loading}
            >
                <form onSubmit={handleConfirmCreate}>
                    <div className="row">
                        <div className="col-md-6">
                            <InputField
                                label="Nombre"
                                name="name"
                                value={couponData.name}
                                onChange={handleInputChange}
                                error={getErrorMessage(errors, "name")}
                            />
                        </div>
                        <div className="col-md-6">
                            <InputField
                                label="Cupón"
                                name="code"
                                value={couponData.code}
                                onChange={handleInputChange}
                                error={getErrorMessage(errors, "code")}
                            />
                        </div>
                    </div>
                    <DropdownField
                        label="Aplicación del Descuento"
                        name="typeGeneralProduct"
                        options={TYPE_GENERAL_PRODUCT}
                        value={couponData.typeGeneralProduct}
                        onChange={handleInputChange}
                        error={getErrorMessage(errors, "typeGeneralProduct")}
                    />
                    {couponData.typeGeneralProduct && (
                        <DropdownField
                            label="Categoria"
                            name="packetId"
                            value={couponData.packetId}
                            options={categoriesPackage}
                            onChange={handleInputChange}
                            error={getErrorMessage(errors, "packetId")}
                        />
                    )}
                    <DropdownField
                        label="Tipo de Descuento"
                        name="typeDiscount"
                        value={couponData.typeDiscount}
                        options={TYPE_DISCOUNT}
                        onChange={handleInputChange}
                        error={getErrorMessage(errors, "typeDiscount")}
                    />
                    <div className="row">
                        <div className="col-md-6">
                            <InputField
                                label="Cantidad"
                                name="amount"
                                value={couponData.amount}
                                onChange={handleInputChange}
                                error={getErrorMessage(errors, "amount")}
                            />
                        </div>
                        <div className="col-md-6">
                            <CalendarField
                                label="Fecha de Expiración"
                                name="dateExpired"
                                value={couponData.dateExpired}
                                onChange={(e) => setCouponData({ ...couponData, dateExpired: e.value })}
                                error={getErrorMessage(errors, "dateExpired")}
                            />
                        </div>
                    </div>
                    <SwitchField
                        label="Estado"
                        checked={couponData.status}
                        onChange={handleInputChange}
                        defaultChecked={true}
                        name="status"
                    />
                    {errors.find(error => error.field === 'server') && (
                        <div className="alert alert-danger" role="alert">
                            {getErrorMessage(errors, "server")}
                        </div>
                    )}
                </form>
            </BaseModal>
        </>
    );
};

export default CouponesModal;