import React, { useState, useEffect } from 'react';
import { apiRepository } from '../../../api/apiRepository';
import { Payments } from '../../../core/Interface/repositoryInterfaces';
import { getDefaultWeekRange } from '../../../core/utils/dateUtils';
import { formatToCustomDate } from '../../../core/utils/utils';
import { useResponsiveColumns } from '../../../core/hooks/useResponsiveColumnsCoupons';
import DataTableWrapper from '../common/dataTable/dataTableWrapper';
import * as Icon from 'react-feather';
import { CalendarRangeField } from '../common/input/inputField';

const SalesHistory = () => {
  const [data, setData] = useState<Payments[]>([]);
  const [selectedPayment, setSelectedPayment] = useState<any>(null);
  const [dateRange, setDateRange] = useState(getDefaultWeekRange());
  const [totalRecords, setTotalRecords] = useState<number>(-1);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const fetchSales = async (pageNumber = currentPage, pageSize = rows) => {
    try {
      setLoading(true);
      const [startDate, endDate] = dateRange;
      const response = await apiRepository.getPayments(formatToCustomDate(startDate), formatToCustomDate(endDate), pageNumber, pageSize);
      const totalRecords = response.data.data.totalElements;
      setData(response.data.data.content);
      setTotalRecords(totalRecords);
    } catch (error) {
      console.error("Error al obtener el historial de compras:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSales();
  }, [dateRange]);

  const handleDateChange = (e) => {
    setDateRange(e.value);
  };

  const handleCustomActionClick = (rowData: any) => {
    setSelectedPayment(null);
    setTimeout(() => setSelectedPayment(rowData.id), 0);
  };

  const columns = [
    { field: "purchaseId", header: "ID de Compra" },
    { field: "date", header: "Fecha", body: (rowData) => new Date(rowData.date).toLocaleDateString('es-PE') },
    { field: "items", header: "Artículos", body: (rowData) => rowData.items.map(item => item.name).join(", ") },
    { field: "totalAmount", header: "Monto Total", body: (rowData) => `S/. ${rowData.totalAmount.toFixed(2)}` },
    { field: "paymentMethod", header: "Método de Pago" },
    {
      field: "status",
      header: "Estado",
      body: (rowData) => (
        <span className={rowData.status === 'COMPLETED' ? 'badge-completed' : 'badge-pending'}>
          {rowData.status === 'COMPLETED' ? 'Completado' : 'Pendiente'}
        </span>
      ),
    },
  ];

  const getColumns = useResponsiveColumns(columns);

  const onPageChange = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
    const pageNumber = event.first / event.rows
    setCurrentPage(pageNumber);
    const [startDate, endDate] = dateRange;
    fetchSales(formatToCustomDate(startDate), formatToCustomDate(endDate), pageNumber, event.rows);
  };

  return (
    <div className="page-wrapper page-settings">
      <div className="content">
        <div className="content-page-header content-page-headersplit">
          <h5>Service Sales</h5>
          <div className="list-btn">
            <div className="col-md-12 col-sm-12 mb-0">
              <CalendarRangeField
                label="Selecciona Rango de Fechas"
                value={dateRange}
                onChange={handleDateChange}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 ">
            <DataTableWrapper
              data={data}
              columns={getColumns()}
              // onEdit={handleEditClick}
              // onDelete={handleDeleteClick}
              onCustomAction={handleCustomActionClick}
              customIcon={Icon.Eye}
              loading={loading}
              paginator
              showGridlines
              rowsPerPage={rows}
              totalRecords={totalRecords}
              first={first}
              onPageChange={onPageChange}
              emptyMessage="No se encontraron cupones."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesHistory;
