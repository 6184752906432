import React, { useRef, useState, useEffect, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import CommonDatePicker from '../../../../core/hooks/commonDatePicker';
import esLocale from '@fullcalendar/core/locales/es';
import { apiRepository } from '../../../../api/apiRepository';
import { useOutletContext } from 'react-router-dom';
import { AuthContext } from '../../../../core/context/AuthContext';

const CustomerBookingCalendar = () => {
  const [searchParams] = useSearchParams();
  const [showEventDetailsModal, setShowEventDetailsModal] = useState(false);
  const [eventDetails, setEventDetails] = useState(null);
  const [events, setEvents] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const calendarRef = useRef(null);
  const authContext = useContext(AuthContext);
  if (!authContext) {
    return <div>Error: Auth context not found!</div>;
  }

  const { user } = authContext;
  const userId = user?.userId;
  const userProfile = user?.role;

  const { setBreadcrumbData } = useOutletContext();

  useEffect(() => {
    setBreadcrumbData({ title: "Calendario", item1: "Perfil", item2: "Calendario" });
  }, [setBreadcrumbData]);


  const fetchSchedules = async () => {
    try {
        let response;
        if (userProfile === 'customer') {
          response = await apiRepository.getUserSchedules(userId);
        } else if (userProfile === 'trainer') {
          response = await apiRepository.getTrainerSchedules(userId);
        } else {
          response = await apiRepository.getUserSchedules(userId);
        }

          const transformedEvents = response.data.data.map(event => {
          const startDate = new Date(`${event.date}T${event.hourStart}:00`);
          const endDate = new Date(`${event.date}T${event.hourEnd}:00`);

          return {
            title: event.title,
            start: startDate.toISOString(),
            end: endDate.toISOString(),
            trainerName: event.trainerName,
            quantity: event.quantity,
            reservation: event.reservation,
            className: event.className,
            ...getEventColor(startDate.toISOString()),
          };
        });

        setEvents(transformedEvents);
    } catch (error) {
        console.error("Error al obtener los datos de horarios:", error);
    }
};

  useEffect(() => {
    fetchSchedules();
}, []);

  const handleEventClick = (info) => {
    setEventDetails({
      title: info.event.title,
      date: info.event.extendedProps.date,
      start: info.event.start,
      end: info.event.end,
      location: info.event.extendedProps.location || "No especificado",
      userName: info.event.extendedProps.userName || "Anónimo",
      trainerName: info.event.extendedProps.trainerName || "Desconocido",
      quantity: info.event.extendedProps.quantity || 0,
      reservation: info.event.extendedProps.reservation || 0,
      className: info.event.extendedProps.className || "Descripción no disponible",
    });
    setShowEventDetailsModal(true);
  };

  const handleEventDetailsClose = () => {
    setShowEventDetailsModal(false);
  };

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const getEventColor = (start: string) => {
    const now = new Date();
    const eventDate = new Date(start);

    now.setHours(0, 0, 0, 0);
    eventDate.setHours(0, 0, 0, 0);

    if (now.getTime() === eventDate.getTime()) {
      return { backgroundColor: '#fbc746', textColor: '#000000', border: 'none' };
    }

    const milisegundosPorDia = 1000 * 60 * 60 * 24;

    const diferenciaMilisegundos = eventDate.getTime() - now.getTime();
    const diffInDays = Math.floor(diferenciaMilisegundos / milisegundosPorDia);


    if (diffInDays < 0) {
      return { backgroundColor: '#ff3030', textColor: '#FFFFFF', daysUntil: diffInDays, border: 'none' };
    } else if (diffInDays === 0) {
      return { backgroundColor: '#fbc746', textColor: '#000000', daysUntil: diffInDays, border: 'none' };
    } else {
      return { backgroundColor: '#2ac538', textColor: '#FFFFFF', daysUntil: diffInDays, border: 'none' };
    }
  };
  const startOfWeek = new Date();
  startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
  const initialView = searchParams.get('type') || 'timeGridWeek';

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
    const handleResize = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addEventListener("change", handleResize);
    return () => mediaQuery.removeEventListener("change", handleResize);
  }, []);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3 mb-4">
        <h4>Clases</h4>

      </div>
      <div id="calendar-book" >
        <FullCalendar
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin,
            listPlugin
          ]}
          initialView={initialView}
          events={events}
          headerToolbar={{
            left: 'title, prev,today next',
            right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
          }}
          locale="es"
          locales={[esLocale]}
          eventClick={handleEventClick}
          ref={calendarRef}
          eventTimeFormat={{
            hour: '2-digit',
            minute: '2-digit',
            meridiem: 'short',
          }}
          slotLabelFormat={{
            hour: '2-digit',
            minute: '2-digit',
            hour12: isMobile ? false : true,
          }}
          allDaySlot={false}
          slotMinTime="05:00:00"
          slotMaxTime="22:00:00"
          views={{
            timeGridWeek: {
              dayHeaderFormat: isMobile && { weekday: 'short' },
            },
          }}
        />
      </div>
      <>
        {/* Booking Details Slide  */}
        <div className={`toggle-sidebar ${showEventDetailsModal && "sidebar-popup"}`}>
          <div className="sidebar-layout">
            <div className="sidebar-header">
              <h5>Detalle de la Clase</h5>
              <a href="#" onClick={handleEventDetailsClose} className="sidebar-close">
                <i className="ti ti-x" />
              </a>
            </div>
            {eventDetails ? (
              <div className="sidebar-body">
                <div className="book-confirm bk-wrap">
                  <div className="d-flex mt-3 justify-content-between">
                    <h6 className="mb-2">{eventDetails.title}</h6>
                  </div>
                  <ul>
                    <li>
                      <span className="bk-date"><i className="fa fa-calendar" /> Fecha de Clase:</span>{" "}
                      {capitalizeFirstLetter(eventDetails.start.toLocaleDateString('es-ES', { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' }))}
                    </li>
                    <li>
                      <span className="bk-date">
                        <i className="fa fa-clock" /> Hora de inicio:
                      </span>{" "}
                      {eventDetails.start.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit', hour12: true })}
                    </li>
                    <li>
                      <span className="bk-date"><i className="fa fa-clock" /> Hora de finalización:</span>{" "}
                      {eventDetails.end.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit', hour12: true })}

                    </li>
                    <li>
                      <span className="bk-date"><i className="fa fa-user" /> Instructor:</span>{" "}
                      {eventDetails.trainerName}
                    </li>
                    <li>
                      <span className="bk-date"><i className="fa fa-users" /> Capacidad:</span>{" "}
                      {eventDetails.quantity}
                    </li>
                    <li>
                      <span className="bk-date"><i className="fa fa-check" /> Reservas:</span>{" "}
                      {eventDetails.reservation}
                    </li>
                  </ul>
                </div>
              </div>
            ) : (
              <div className="sidebar-body">
                <p>No event selected.</p>
              </div>
            )}
          </div>
        </div>
        {/* /Booking Details */}
        {/* Reschedule Appointment */}
        <div className="modal fade custom-modal" id="reschedule">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header d-flex align-items-center justify-content-between border-bottom">
                <h5 className="modal-title">Reschedule Appointment</h5>
                <a
                  href="#"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-circle-x-filled fs-20" />
                </a>
              </div>
              <div className="modal-body">
                <form >
                  <div className="mb-3">
                    <label className="form-label">Appointment Date</label>
                    <div className="form-icon react-calender">
                      <CommonDatePicker />
                      <span className="cus-icon">
                        <i className="feather-calendar" />
                      </span>
                    </div>
                  </div>
                  <div className="mb-0">
                    <label className="form-label">Appointment Time</label>
                    <div className="form-icon">
                      <input
                        type="text"
                        className="form-control timepicker"
                        placeholder="DD/MM/YYYY"
                      />
                      <span className="cus-icon">
                        <i className="feather-clock" />
                      </span>
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <div className="acc-submit">
                  <a
                    href="#"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </a>
                  <button className="btn btn-dark" type="button" data-bs-dismiss="modal">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Reschedule Appointment */}
        {/* Cancel Appointment */}
        <div className="modal fade custom-modal" id="cancel_appointment">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header d-flex align-items-center justify-content-between border-bottom">
                <h5 className="modal-title">Cancel Appointment</h5>
                <a
                  href="#"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-circle-x-filled fs-20" />
                </a>
              </div>
              <form >
                <div className="modal-body">
                  <p>
                    Are you sure you want to cancel{" "}
                    <span className="text-dark"> John Doe</span> appointment on
                    <span className="text-dark"> Oct 28, 2022</span> at time{" "}
                    <span className="text-dark"> 10AM - 12PM</span>
                  </p>
                </div>
                <div className="modal-footer">
                  <div className="acc-submit">
                    <a
                      href="#"
                      className="btn btn-light me-2"
                      data-bs-dismiss="modal"
                    >
                      Dismiss
                    </a>
                    <button data-bs-dismiss="modal" className="btn btn-dark" type="button">
                      Yes, Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Cancel Appointment */}
      </>
      <div className={`sidebar-overlay ${showEventDetailsModal && 'opened'}`} onClick={handleEventDetailsClose}></div>
    </>
  );
};

export default CustomerBookingCalendar;
