import React, { useState, useEffect } from 'react';
import { apiRepository } from '../../../api/apiRepository';
import { Schedules } from '../../../core/Interface/repositoryInterfaces';
import DataTableWrapper from '../common/dataTable/dataTableWrapper';
import SchedulesModal from '../common/modals/schedules-modal';
import DeleteSchedulesModal from '../common/modals/delete-schedules-modal';
import { useResponsiveColumns } from '../../../core/hooks/useResponsiveColumnsTrainer';
import { formatTimeTo12Hour } from '../../../core/utils/utils';
import { useNavigate } from 'react-router-dom';
import { all_routes } from '../../../core/data/routes/all_routes';
import * as Icon from 'react-feather';

const ClassSchedules = () => {
    const [data, setData] = useState<Schedules[]>([]);
    const [trainers, setTrainers] = useState([]);
    const [selectedScheduleId, setSelectedScheduleId] = useState<number | null>(null);
    const [selectedSchedule, setSelectedSchedule] = useState<string | null>(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const navigate = useNavigate();
    const routes = all_routes;

    const fetchSchedules = async (pageNumber = currentPage, pageSize = rows) => {
        try {
            setLoading(true);
            const response = await apiRepository.getSchedules(pageNumber, pageSize);
            setData(response.data.data.content);
            setTotalRecords(response.data.data.totalElements);
        } catch (error) {
            console.error('Error al obtener los datos de horarios:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchTrainers = async () => {
        try {
            const response = await apiRepository.getUsers(0, 10);
            const formattedTrainers = response.data.data.content.map(user => ({
                label: `${user.name} ${user.lastname}`,
                value: user.id
            }));
            setTrainers(formattedTrainers);
        } catch (error) {
            console.error('Error al obtener los datos de entrenadores:', error);
        }
    };

    useEffect(() => {
        fetchSchedules();
        fetchTrainers();
    }, []);

    const handleEditClick = (rowData: any) => {
        setSelectedSchedule(null);
        setTimeout(() => setSelectedSchedule(rowData), 0);
    };

    const handleDeleteClick = (rowData: any) => {
        setSelectedScheduleId(null);
        setTimeout(() => setSelectedScheduleId(rowData.id), 0);
    };

    const handleCustomActionClick = (rowData: any) => {
        navigate(`${routes.classScheduleAssits}?id=${rowData.id}`);
      };

    const formatReservation = (reservation: number) => {
        return reservation > 0 ? reservation : 'Sin reservas';
    };

    const columns = [
        { field: "title", header: "Nombre" },
        {
            field: "date",
            header: "Fecha y Horario",
            sortable: true,
            body: (rowData) => {
                return (
                <span>
                    {rowData.date} - {formatTimeTo12Hour(rowData.hourStart)} a {formatTimeTo12Hour(rowData.hourEnd)}
                </span>
                );
            }
        },
        {
        field: "status",
        header: "Estado",
        body: (rowData) => {
            let label = '';
            let className = '';

            switch (rowData.status) {
            case 1:
                label = 'Pendiente';
                className = 'badge-pending';
                break;
            case 2:
                label = 'En Atención';
                className = 'badge-active';
                break;
            case 3:
                label = 'Finalizada';
                className = 'badge-default';
                break;
            case 4:
                label = 'Cancelado';
                className = 'badge-delete';
                break;
                case 5:
                label = 'Asistencia finalizada';
                className = 'badge-active';
                break;
            default:
                label = 'Desconocido';
                className = 'badge-default';
            }

            return <span className={className}>{label}</span>;
        }
        },
        { field: "quantity", header: "Total Cupos" },
        {
        field: "reservation",
        header: "Reservas",
        body: (rowData) => formatReservation(rowData.reservation),
        },
    ];

    const getColumns = useResponsiveColumns(columns);

    const onPageChange = (event: any) => {
        setFirst(event.first);
        setRows(event.rows);
        const pageNumber = event.first / event.rows;
        setCurrentPage(pageNumber);
        fetchSchedules(pageNumber, event.rows);
    };

    return (
        <div className="page-wrapper page-settings">
            <div className="content">
                <div className="content-page-header content-page-headersplit">
                    <h5>Horarios</h5>
                    <div className="list-btn">
                        <button className="btn btn-primary-admin" onClick={handleEditClick}>
                            <i className="fa fa-plus me-2" /> Agregar Horario
                        </button>
                    </div>
                </div>
                <DataTableWrapper
                    data={data}
                    columns={getColumns()}
                    onEdit={handleEditClick}
                    onDelete={handleDeleteClick}
                    onCustomAction={handleCustomActionClick}
                    customIcon={Icon.Eye}
                    sortField="date"
                    sortOrder={-1}
                    loading={loading}
                    paginator
                    showGridlines
                    rowsPerPage={rows}
                    totalRecords={totalRecords}
                    first={first}
                    onPageChange={onPageChange}
                    emptyMessage="No se encontraron horarios."
                />
            </div>
            <SchedulesModal selectedSchedule={selectedSchedule} onUpdateSuccess={fetchSchedules} trainerList={trainers} />
            <DeleteSchedulesModal selectedScheduleId={selectedScheduleId} onDeleteSuccess={fetchSchedules} />
        </div>
    );
};

export default ClassSchedules;