import { apiRepository } from '../../api/apiRepository';

const CART_STORAGE_KEY = 'cart_storage';

export type Product = {
  id: string;
  title: string;
  subtitle: string;
  description: string;
  price: number;
  image: string;
  quantity: number;
  createdAt: string;
  updatedAt: string;
};

export type Cart = Product[];

const getStoredCarts = (): Record<string, Cart> => {
  const carts = localStorage.getItem(CART_STORAGE_KEY);
  return carts ? JSON.parse(carts) : {};
};

const saveStoredCarts = (carts: Record<string, Cart>): void => {
  localStorage.setItem(CART_STORAGE_KEY, JSON.stringify(carts));
};

export const getCart = (userId: string | null): Cart => {
  const carts = getStoredCarts();
  return carts[userId ?? 'general'] ?? [];
};

const getProductById = async (productId: string): Promise<Product> => {
  const response = await apiRepository.getIdPackage(productId);
  return response.data.data;
};

const getCategoryId = async (packageTypeId: string): Promise<Product> => {
  const response = await apiRepository.getIdPackageType(packageTypeId);
  return response.data.data;
};

export const addToCart = async (
  userId: string | null,
  productId: string,
  setCart: React.Dispatch<React.SetStateAction<Cart>>
): Promise<void> => {
  const carts = getStoredCarts();
  const cartKey = userId ?? 'general';
  const cart = carts[cartKey] ?? [];

  const existingProduct = cart.find(item => item.id === productId);

  if (existingProduct) {
    existingProduct.quantity += 1;
  } else {
    const product = await getProductById(productId);
    const typeProduct = await getCategoryId(product.packageTypeId);
    cart.push({ ...product, quantity: 1, subType: typeProduct.subType });
  }

  carts[cartKey] = cart;
  saveStoredCarts(carts);
  setCart(cart);
};

export const removeFromCart = (
  userId: string | null,
  productId: string,
  setCart: React.Dispatch<React.SetStateAction<Cart>>
): void => {
  const carts = getStoredCarts();
  const cartKey = userId ?? 'general';
  const cart = carts[cartKey] ?? [];

  const updatedCart = cart
    .map(item => {
      if (item.id === productId) {
        if (item.quantity > 1) {
          item.quantity -= 1;
          return item;
        }
        return null;
      }
      return item;
    })
    .filter(item => item !== null);

  carts[cartKey] = updatedCart;
  saveStoredCarts(carts);
  setCart(updatedCart);
};

export const removeProductFromCart = (
  userId: string | null,
  productId: string,
  setCart: React.Dispatch<React.SetStateAction<Cart>>
): void => {
  const carts = getStoredCarts();
  const cartKey = userId ?? 'general';
  const cart = carts[cartKey] ?? [];

  const updatedCart = cart.filter(item => item.id !== productId);
  carts[cartKey] = updatedCart;
  saveStoredCarts(carts);
  setCart(updatedCart);
};

export const clearCart = (
  userId: string | null,
  setCart: React.Dispatch<React.SetStateAction<Cart>>
): void => {
  const carts = getStoredCarts();
  const cartKey = userId ?? 'general';

  delete carts[cartKey];
  saveStoredCarts(carts);
  setCart([]);
};