import React, { useState, useEffect } from 'react';
import { apiRepository } from '../../../../api/apiRepository';
import { Subscriptions } from '../../../../core/Interface/repositoryInterfaces';
import { validateSubscriptions, ValidationError } from '../../../../core/form/validationForm';
import { InputField } from '../input/inputField';
import { handleChange } from '../../../../core/utils/utils';
import { getErrorMessage } from '../../../../core/utils/errorUtils';
import { handleFormSubmit } from '../../../../core/services/formService';
import BaseModal from './baseModal';

export interface UpdateUserModalProps {
    selectedUser: Subscriptions;
    onUpdateSuccess?: () => void;
}

const UpdateUserModal: React.FC<UpdateUserModalProps> = ({ selectedUser, onUpdateSuccess }) => {
    const [userData, setUserData] = useState<{ fullname: string; email: string }>({
        fullname: selectedUser ? selectedUser.fullname : '',
        email: selectedUser ? selectedUser.email : ''
    });
    const [errors, setErrors] = useState<ValidationError[]>([]);
    const [show, setShow] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
        handleChange(e, setUserData);
    };

    const handleClose = () => {
        setShow(false);
        setLoading(false)
        setErrors([]);
        setUserData({ fullname: '', email: '' });
    };

    useEffect(() => {
        if (selectedUser) {
            setUserData({
                fullname: selectedUser.fullname,
                email: selectedUser.email,
            });
            setShow(true);
        }
    }, [selectedUser]);

    const handleConfirmUpdate = async (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);

        const errors = await handleFormSubmit(
            selectedUser.id,
            userData,
            validateSubscriptions,
            {
                create: apiRepository.createUser,
                update: apiRepository.updateUser,
            },
            () => {
                if (onUpdateSuccess) onUpdateSuccess();
                handleClose();
            },
            (data) => data,
            "Ocurrió un error al intentar guardar los datos. Inténtalo de nuevo."
        );

        if (errors) {
            setErrors(errors);
        }

        setLoading(false);
    };

    return (
        <>
            <BaseModal
                show={show}
                title="Actualizar Usuario"
                onClose={handleClose}
                onConfirm={handleConfirmUpdate}
                loading={loading}
            >
                <form onSubmit={handleConfirmUpdate}>
                    <div className="row">
                        <div className="col-md-6">
                            <InputField
                                label="Nombre Completo"
                                name="fullname"
                                value={userData.fullname}
                                onChange={handleInputChange}
                                error={getErrorMessage(errors, "fullname")}
                            />
                        </div>
                        <div className="col-md-6">
                            <InputField
                                label="Correo Electrónico"
                                name="email"
                                value={userData.email}
                                onChange={handleInputChange}
                                error={getErrorMessage(errors, "email")}
                            />
                        </div>
                    </div>
                    {errors.find(error => error.field === 'server') && (
                        <div className="alert alert-danger" role="alert">
                            {getErrorMessage(errors, "server")}
                        </div>
                    )}
                </form>
            </BaseModal>
        </>
    );
};

export default UpdateUserModal;