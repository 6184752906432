import React, { useContext, useState, useEffect } from 'react';
import { CartContext } from '../../../../core/context/CartContext';
import { AuthContext } from '../../../../core/context/AuthContext';
import { Link, useLocation } from 'react-router-dom';
import ImageWithBaseApi from '../../../../core/img/ImageWithBaseApi';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { apiRepository } from '../../../../api/apiRepository';
import { ValidationError } from '../../../../core/form/validationForm';
import { useOutletContext } from 'react-router-dom';
import { initMercadoPago } from '@mercadopago/sdk-react';
import PaymentSuccessModal from '../../common/modals/succes-payment';

const CartComponent: React.FC = () => {
  const cartContext = useContext(CartContext);
  const [couponCode, setCouponCode] = useState('');
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [loading, setLoading] = useState<number | null>(null);
  const [loadingPay, setLoadingPay] = useState<number | null>(null);
  const [info, setInfo] = useState<ValidationError[]>([]);
  const { setBreadcrumbData } = useOutletContext();
  const location = useLocation();
  initMercadoPago('APP_USR-9845e8d5-0a69-4a8a-a4d2-708e01c3fd9e');

  const authContext = useContext(AuthContext);
  if (!authContext) {
    return <div>Error: Auth context not found!</div>;
  }

  if (!cartContext) {
    return <div>Error: Cart context not found!</div>;
  }

  const { cart, addToCart, setCart, removeFromCart, removeProductFromCart, clearCart } = cartContext;
  const { user } = authContext;

  const subtotal = cart.reduce((acc, product) => acc + product.price * product.quantity, 0);
  const total = subtotal - couponDiscount;

  const getPaymentDetails = async (paymentId:any) => {
    try {
      const response = await fetch(`https://api.mercadopago.com/v1/payments/${paymentId}`, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer APP_USR-8591860113159620-112917-213db177427bc78036158aa9f1e911e2-1230359806', // Reemplaza con tu token de acceso
        }
      });

      const data = await response.json();
      console.debug(data, 'response')

      return data

    } catch (error) {
      console.error('Error al obtener detalles del pago:', error);
    }
  };

  const clearCartIfSuccess = async () => {
    const params = new URLSearchParams(location.search);
    const success = params.get('succes');
    const paymentId = params.get('payment_id');

    if (success === 'true' && cartContext && paymentId) {
      const paymentData = await getPaymentDetails(paymentId);

      if (!paymentData) {
        console.error('No se pudieron obtener los detalles del pago');
        return;
      }

      console.debug(paymentData, 'paymentDatapaymentDatapaymentData')

      const paymentDetails = paymentData.additional_info.items.map((item) => ({
        packetId: Number(item.id),
        quantity: Number(item.quantity),
        amount: parseFloat(item.unit_price * item.quantity),
      }));

      cartContext.clearCart(setCart);

      const paymentInfo = {
        userId: user?.userId || "Usuario no logeado",
        jsonResponse: JSON.stringify({ status: 'success' }),
        status: 'COMPLETED',
        paymentDetails,
      };

      try {
        await apiRepository.createPayments(paymentInfo)
      } catch {
        console.error('Eror with the request')
      }

      const successModalButton = document.querySelector('#succes-pay-confirm [data-bs-toggle="modal"]');
      if (successModalButton) {
        successModalButton.click();
      }

    }
  };

  useEffect(() => {
    clearCartIfSuccess();
  }, [location.search]);

  const createPreference = async () => {
    const items = cart.map((product, index) => {
      const unitPrice = (index === 0 && couponDiscount > 0)
        ? product.price - couponDiscount
        : product.price;

      return {
        title: product.title,
        id: product.id,
        subtle: product.subtitle,
        quantity: product.quantity,
        currency_id: 'PEN',
        unit_price: unitPrice,
      };
    });

    const preferenceData = {
      items,
      back_urls: {
        success: 'http://37.27.187.218/perfil/cart?succes=true',
        failure: 'http://37.27.187.218/perfil/cart',
        pending: 'http://37.27.187.218/page/faq',
        // success: 'http://localhost:3000/perfil/cart?succes=true',
        // failure: 'http://localhost:3000/perfil/cart',
        // pending: 'http://localhost:3000/page/faq',
      },
      auto_return: 'approved',
    };

    try {
      const response = await fetch('https://api.mercadopago.com/checkout/preferences', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer APP_USR-8591860113159620-112917-213db177427bc78036158aa9f1e911e2-1230359806' // Sustituye con tu token de acceso
        },
        body: JSON.stringify(preferenceData)
      });

      const data = await response.json();
      return data.init_point;
    } catch (error) {
      console.error('Error al crear la preferencia:', error);
    }
  };

  const confirmPay = async () => {
    setLoadingPay(true);

    const informationSucces = [];
    const subTypesInCart = cart.map(item => item.subType);

    if (subTypesInCart.includes("Membresias") && subTypesInCart.includes("Clases")) {
        informationSucces.push({
            field: 'error',
            message: 'No se puede combinar Membresias con Clases.'
        });
    }

    if (subTypesInCart.includes("Clases Reformer") && subTypesInCart.includes("Membresias Premium")) {
        informationSucces.push({
            field: 'error',
            message: 'No se puede combinar Clases Reformer con Membresias Premium.'
        });
    }

    if (subTypesInCart.includes("Membresias") && subTypesInCart.includes("Membresias Premium")) {
        informationSucces.push({
            field: 'error',
            message: 'No se puede combinar Membresias con Membresias Premium.'
        });
    }

    if (informationSucces.length > 0) {
        console.error(informationSucces);
        setInfo(informationSucces)
        setLoadingPay(false);
        return;
    }

    try {
        setInfo([])
        const link = await createPreference();
        window.location.href = link;
    } catch (error) {
        console.error('Error al crear la preferencia:', error);
    } finally {
        setLoadingPay(false);
    }
};


  useEffect(() => {
    setBreadcrumbData({ title: "Carrito", item1: "Perfil", item2: "Carrito" });
  }, [setBreadcrumbData]);

  const validateCode = async () => {
    try {
      setLoading(true);
      setInfo([]);
      const informationSucces = [];

      const response = await apiRepository.validateCouponCode(couponCode);
      const couponData = response.data.data;

      if (couponData) {
        if (couponData.typeDiscount) {
          const productInCart = cart.find(product => product.id === couponData.packetId);

          if (productInCart) {
            const discount = ((productInCart.price * couponData.amount) / 100).toFixed(2);

            if (discount <= subtotal) {
              setCouponDiscount(parseFloat(discount));
              informationSucces.push({ field: 'success', message: '¡Cupón válido!' });
            } else {
              setCouponDiscount(0);
              informationSucces.push({ field: 'error', message: 'El descuento es mayor al subtotal.' });
            }
          } else {
            setCouponDiscount(0);
            informationSucces.push({ field: 'error', message: 'El cupón no es válido para los productos seleccionados.' });
          }
        } else {
          const productInCart = cart.find(product => product.id === couponData.packetId);

          if (productInCart) {
            const discount = couponData.amount;

            if (discount <= subtotal) {
              setCouponDiscount(parseFloat(discount));
              informationSucces.push({ field: 'success', message: '¡Cupón válido!' });
            } else {
              setCouponDiscount(0);
              informationSucces.push({ field: 'error', message: 'El descuento es mayor al subtotal.' });
            }
          } else {
            setCouponDiscount(0);
            informationSucces.push({ field: 'error', message: 'El cupón no es válido para los productos seleccionados.' });
          }
        }

      } else {
        setCouponDiscount(0);
        informationSucces.push({ field: 'error', message: 'El cupón no es válido.' });
      }

      setInfo(informationSucces);
    } catch (error) {
      setCouponDiscount(0);
      const informationSucces = [];
      if (error.response && error.response.data && error.response.data.message) {
        const cleanedMessage = error.response.data.message.replace(/^Bad request: /, '');
        informationSucces.push({ field: 'error', message: cleanedMessage });
      } else {
        informationSucces.push({
          field: 'error',
          message: 'Ocurrió un error al intentar validar el cupón. Inténtalo de nuevo.',
        });
      }
      setInfo(informationSucces);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="row">
      <div className="col-xl-8 col-lg-8">
        <h5 className="pay-title">Carrito de Compras</h5>
        {cart.length === 0 ? (
          <div className="empty-cart text-center">
            <i className="fa fa-frown fa-4x mb-2" />
            <p className="lead">¡Tu carrito está vacío!</p>
            <Link to="/" className="btn btn-primary-admin mt-3 mb-3">
              Ver productos
            </Link>
          </div>
        ) : (
          <>
            <div className=" row-cols-1 row-cols-md-2 g-3">
              {cart.map((productId) => (
                <div
                  className="container card shadow-none"
                  key={productId.id}
                >
                  <div className="row card-body justify-content-center">
                    {productId.image && (
                      <div className="p-0 me-sm-3 mb-3 mb-sm-0 image-cart">
                        <ImageWithBaseApi
                          src={productId.image}
                          routeName="packet"
                          alt="User Image"
                        />
                      </div>
                    )}
                    <div className="p-0 mb-0 me-sm-3 mb-3 mb-sm-0 col product-info-cart">
                      <h6 className="mb-3 truncate-text-cart">{productId.title}</h6>
                      <ul className="booking-details w-100">
                        <li className="d-flex align-items-center mb-2 truncate-text-cart">
                          <span className="book-item me-1">Detalles</span>
                          <small className="me-2">: </small>
                          <span className="w-100">{productId.description.replace(/\|/g, ' • ')}</span>
                        </li>
                        <li className="d-flex align-items-center mb-2 truncate-text-cart">
                          <span className="book-item me-1">Subtitulo</span>
                          <small className="me-2">: </small>
                          {productId.subtitle}
                        </li>
                        <li className="d-flex align-items-center mb-2 truncate-text-cart">
                          <span className="book-item me-1">Precio</span>
                          <small className="me-2">: </small> S/{' '}
                          {productId.price}
                        </li>
                      </ul>
                    </div>

                    <div className="p-0 d-flex button-cart flex-sm-column">
                      <div className="buttons-mob d-flex d-flex mb-0 mb-sm-2 w-100 justify-content-between">
                        <button
                          className="btn btn-light"
                          onClick={() => removeFromCart(productId.id, setCart)}
                        >
                          <i className="fa fa-minus" />
                        </button>
                        <button className="btn btn-light">
                          {productId.quantity}
                        </button>
                        <button
                          className="btn btn-light me-2 me-sm-0"
                          onClick={() => addToCart(productId.id, setCart)}
                        >
                          <i className="fa fa-plus" />
                        </button>
                      </div>

                      <button
                        className="btn btn-danger w-100"
                        onClick={() => removeProductFromCart(productId.id, setCart)}
                      >
                        <i className="fa fa-trash me-2" />
                        Eliminar
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      <div className="col-xl-4 col-lg-4">
        <h5 className="pay-title">Resumen de la compra</h5>
        <div className="summary-box">
          <div className="booking-summary">
            <ul className="booking-date">
              <li>
                Subtotal <span>S/ {subtotal.toLocaleString('es-PE', { minimumFractionDigits: 2 })}</span>
              </li>
              <li>
                Descuento de Cupón <span>S/ {couponDiscount.toLocaleString('es-PE', { minimumFractionDigits: 2 })}</span>
              </li>
            </ul>
            <div className="booking-total">
              <ul className="booking-total-list">
                <li>
                  <span>Total</span>
                  <span className="total-cost">S/ {total.toLocaleString('es-PE', { minimumFractionDigits: 2 })}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="booking-coupon mb-3">
          <div className="w-100">
            <div className="coupon-icon">
              <input
                type="text"
                className="form-control"
                placeholder="Código de Cupón"
                value={couponCode}
                onChange={(e) => setCouponCode(e.target.value)}  // Actualiza el estado
              />
              <span>
                <ImageWithBasePath
                  src="assets/img/icons/coupon-icon.svg"
                  alt="image"
                />
              </span>
            </div>
          </div>
          <button onClick={validateCode} className="btn btn-dark">
            {loading ? <i className="fa fa-spinner fa-spin"></i> : 'Aplicar'}
          </button>
        </div>
        {info.find((value) => value.field === 'success') && (
          <div className="alert alert-success mt-3 mb-3" role="alert">
            {info.find((value) => value.field === 'success')?.message}
          </div>
        )}
        {info.find((value) => value.field === 'error') && (
          <div className="alert alert-danger mt-3 mb-3" role="alert">
            {info.find((value) => value.field === 'error')?.message}
          </div>
        )}
        <button onClick={confirmPay} className="btn btn-dark w-100 pt-2 pb-2 mb-3 mb-sm-0">
          {loadingPay ? (
            <i className="fa fa-spinner fa-spin"></i>
          ) : (
            <>Pagar S/ {total.toLocaleString('es-PE', { minimumFractionDigits: 2 })}</>
          )}
        </button>


      </div>
      <div id="succes-pay-confirm">

        <button
          type="button"
          className="d-none"
          data-bs-toggle="modal"
          data-bs-target="#payment-success"
        >
        </button>
      </div>
      <PaymentSuccessModal message="¡La compra se realizo con exito!" />
    </div>
  );
};

export default CartComponent;