export const addNewServiceRow = (services: any[]) => {
  const maxId = services.length > 0
    ? Math.max(...services.map(service => parseInt(service.id, 10)))
    : 0;
  const newId = (maxId + 1).toString();
  return [
    ...services,
    { id: newId, title: '', subtitle: '', path: '', image: '', status: true, position: services.length + 1 },
  ];
};

export const addNewCategoryRow = (services: any[]) => {
  const maxId = services.length > 0
    ? Math.max(...services.map(service => parseInt(service.id, 10)))
    : 0;
  const newId = (maxId + 1).toString();
  return [
    ...services,
    { id: newId, name: '', type: 1, status: true, position: services.length + 1 },
  ];
};

export const deleteServiceRow = async (
  id: string,
  services: any[],
  apiRepository: any
) => {
  try {
    await apiRepository.deleteBanner(id);
  } catch (error) {
    console.error("Error al eliminar el servicio:", error);
  }
  return services.filter((service) => service.id !== id);
};

export const handleInputChange = (
  id: string,
  name: string,
  value: string,
  services: any[]
) => {
  return services.map((service) =>
    service.id === id ? { ...service, [name]: value } : service
  );
};

export const handleOnDragEnd = (result: DropResult, services: any[]) => {
  if (!result.destination) return services;

  const reorderedServices = Array.from(services);
  const [movedService] = reorderedServices.splice(result.source.index, 1);
  reorderedServices.splice(result.destination.index, 0, movedService);

  return reorderedServices.map((service, index) => ({
    ...service,
    position: index + 1,
  }));
};