import React, { useState, useEffect } from 'react';
import * as Icon from 'react-feather';
import { apiRepository } from '../../../../api/apiRepository';
import BaseModal from './baseModalDelete';
import { getErrorMessage } from '../../../../core/utils/errorUtils';
import { ValidationError } from '../../../../core/form/validationForm';

export interface DeleteCouponsModalProps {
    selectedPackageId: number;
    onDeleteSuccess?: () => void;
}

const DeleteCouponsModal: React.FC<DeleteCouponsModalProps> = ({ selectedPackageId, onDeleteSuccess }) => {
    const [show, setShow] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState<ValidationError[]>([]);

    useEffect(() => {
        if (selectedPackageId) {
            setShow(true);
        }
    }, [selectedPackageId]);

    const handleClose = () => {
        setShow(false);
        setErrors([]);
    };

    const handleConfirmDelete = async () => {
        if (selectedPackageId === -1) return;

        try {
            setLoading(true);
            console.log("Intentando eliminar paquete con ID:", selectedPackageId);
            await apiRepository.deletePackage(selectedPackageId);
            console.log("Paquete eliminado con éxito");

            setShow(false);
            onDeleteSuccess?.();
        } catch (error) {
            setErrors([{ field: "server", message: "Error al eliminar el paquete." }]);
            console.error("Error al eliminar el paquete:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <BaseModal
            show={show}
            title="Eliminar Paquete"
            onClose={handleClose}
            onConfirm={handleConfirmDelete}
            loading={loading}
        >
            <div className="text-center">
                <Icon.Trash2 size={45} color='#f05050' className="text-danger fs-1" />
                <div className="mt-4">
                    <h4>¿Eliminar Paquete?</h4>
                    <p className="text-muted mb-0">¿Estás seguro de que quieres eliminar este paquete?</p>
                    {errors.find(error => error.field === 'server') && (
                        <div className="alert alert-danger mt-3" role="alert">
                            {getErrorMessage(errors, "server")}
                        </div>
                    )}
                </div>
            </div>
        </BaseModal>
    );
};

export default DeleteCouponsModal;