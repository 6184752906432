import React, { useState, useEffect } from 'react'
import { apiRepository } from '../../../../api/apiRepository';
import { Users, PaymentDays } from '../../../../core/Interface/repositoryInterfaces';
import { validateUser, ValidationError, validatePaymentDays } from '../../../../core/form/validationForm';
import { InputField, CalendarField, DropdownField, SwitchField, ImageUploadField } from '../input/inputField';
import { handleChange, dateStandard, formatDatetoString } from '../../../../core/utils/utils';
import { getErrorMessage } from '../../../../core/utils/errorUtils';
import { handleFormSubmit } from '../../../../core/services/formService'
import { TYPE_ROLES_USERS, TYPE_ACTIONS_USERS } from '../../../../core/data/options/options';
import * as Icon from 'react-feather';
import BaseModal from './baseModal';

export interface UpdateUserModalProps {
    selectedUser?: Users;
    onUpdateSuccess?: () => void;
}

const UsersModal: React.FC<UpdateUserModalProps> = ({ selectedUser, onUpdateSuccess }) => {
    const [id, setId] = useState<number>(selectedUser ? selectedUser.id : -1);
    const [userData, setUserData] = useState<Users>([]);
    const [userDays, setUserDays] = useState<PaymentDays>({
        type: "monthly",
        status: true,
    });
    const [errors, setErrors] = useState<ValidationError[]>([]);
    const [errorsDays, setErrorsDays] = useState<ValidationError[]>([]);
    const [show, setShow] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
        handleChange(e, setUserData);
    };

    const handleDayInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
        handleChange(e, setUserDays);
    };

    const handleClose = () => {
        setShow(false);
        setErrors([]);
        setUserData([]);
        setId(-1);
    };

    const loadDays = async () => {
        try {
            const response = await apiRepository.getPaymentDayById(1);
            const paymentDay = response.data.data;
            return paymentDay;
        } catch {
            console.debug('none days');
            return null;
        }
    };

    useEffect(() => {
        const fetchDays = async () => {
            if (selectedUser && selectedUser.id) {
                const userDays = await loadDays();
                if (userDays) {
                    setUserDays({
                        userId: selectedUser.id,
                        days: userDays.days,
                        type: "monthly",
                        status: selectedUser.active,
                        endDate: userDays.endDate,
                    });
                }
            }
        };

        if (selectedUser) {
            setUserData({
                id: selectedUser.id,
                name: selectedUser.name,
                lastname: selectedUser.lastname,
                dni: selectedUser.dni,
                phone: selectedUser.phone,
                email: selectedUser.email,
                password: '',
                profile: selectedUser.profile,
                active: selectedUser.active,
                image: selectedUser.image || null,
                classFree: selectedUser.classFree || false,
                birthdate: selectedUser.birthdate && !isNaN(new Date(selectedUser.birthdate).getTime())
                    ? dateStandard(selectedUser.birthdate)
                    : dateStandard(formatDatetoString(new Date())),
            });
            setId(selectedUser.id);
            fetchDays();
            setShow(true);
        }
    }, [selectedUser]);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files ? e.target.files[0] : null;
        setUserData({ ...userData, image: file });
    };

    const handleRemoveImage = () => {
        setUserData({ ...userData, image: null });
        const fileInput = document.getElementById("imgInp") as HTMLInputElement;
        if (fileInput) {
            fileInput.value = "";
        }
    };

    const createOrUpdateUser = async (event: React.FormEvent) => {
        event.preventDefault();
        const formData = true
        setLoading(true)

        const errors = await handleFormSubmit(
            id,
            userData,
            validateUser,
            {
                create: apiRepository.createUser,
                update: apiRepository.updateUser,
            },
            () => {
                if (onUpdateSuccess) onUpdateSuccess();
                handleClose();
            },
            (data) => ({
                ...data,
                birthdate: formatDatetoString(data.birthdate),
            }),
            "Ocurrió un error al intentar guardar el usuario. Inténtalo de nuevo.",
            formData
        );

        const errorsDays = await handleFormSubmit(
            userDays.userId,
            userDays,
            validatePaymentDays,
            {
                create: apiRepository.createPayments,
                update: apiRepository.updatePaymentDay,
            },
            () => {
                if (onUpdateSuccess) onUpdateSuccess();
                handleClose();
            },
            (data) => ({
                ...data,
                endDate: formatDatetoString(data.endDate),
            }),
            "Ocurrió un error al intentar guardar la fecha de vecimiento. Inténtalo de nuevo.",
        );

        if (errors) {
            setErrors(errors);
            setErrorsDays(errorsDays);
        }

        setLoading(false)
    };

    return (
        <>
            <div className="modal fade" id="add-user">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Usuario</h5>
                            <button
                                type="button"
                                className="btn-close close-modal"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <Icon.X className="react-feather-custom"></Icon.X>
                            </button>
                        </div>
                        <BaseModal
                            show={show}
                            title="Usuario"
                            onClose={handleClose}
                            onConfirm={createOrUpdateUser}
                            loading={loading}
                        >
                            <form onSubmit={createOrUpdateUser}>
                                <ImageUploadField
                                    formData={userData}
                                    onFileChange={handleFileChange}
                                    onRemoveImage={handleRemoveImage}
                                    routeName="user"
                                />
                                <div className="row">
                                    <div className="col-md-4">
                                        <InputField
                                            label="Nombre"
                                            name="name"
                                            value={userData.name}
                                            onChange={handleInputChange}
                                            error={getErrorMessage(errors, 'name')}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <InputField
                                            label="Apellido"
                                            name="lastname"
                                            value={userData.lastname}
                                            onChange={handleInputChange}
                                            error={getErrorMessage(errors, 'lastname')}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <InputField
                                            label="Correo"
                                            name="email"
                                            value={userData.email}
                                            onChange={handleInputChange}
                                            error={getErrorMessage(errors, 'email')}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <InputField
                                            label="Telefono"
                                            name="phone"
                                            value={userData.phone}
                                            onChange={handleInputChange}
                                            error={getErrorMessage(errors, 'phone')}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <InputField
                                            label="DNI"
                                            name="dni"
                                            value={userData.dni}
                                            onChange={handleInputChange}
                                            error={getErrorMessage(errors, 'dni')}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <CalendarField
                                            label="Fecha de nacimiento"
                                            name="birthdate"
                                            value={userData.birthdate}
                                            onChange={(e) => setUserData((prevFormData) => ({
                                                ...prevFormData,
                                                birthdate: e.value as Date,
                                            }))}
                                            error={getErrorMessage(errors, 'birthdate')}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className={userData.profile === TYPE_ROLES_USERS[0].value ? 'col-md-6' : 'col-md-12'}>
                                        <DropdownField
                                            label="Rol"
                                            name="profile"
                                            value={userData.profile}
                                            onChange={handleInputChange}
                                            options={TYPE_ROLES_USERS}
                                            error={getErrorMessage(errors, 'profile')}
                                        />
                                    </div>
                                    {userData.profile === TYPE_ROLES_USERS[0].value && (
                                        <div className="col-md-6">
                                            <DropdownField
                                                label="Prueba Gratuita"
                                                name="classFree"
                                                value={userData.classFree}
                                                onChange={handleInputChange}
                                                options={TYPE_ACTIONS_USERS}
                                                disabled={id > 0}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="row">
                                    {userData.profile === TYPE_ROLES_USERS[0].value && userData.id && (
                                        <>
                                            <div className="col-md-6">
                                                <InputField
                                                    label="Cantidad de clases"
                                                    name="days"
                                                    value={userDays.days}
                                                    onChange={handleDayInputChange}
                                                    error={getErrorMessage(errorsDays, 'days')}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <CalendarField
                                                    label="Vencimiento de Clases"
                                                    name="endDate"
                                                    value={userData.endDate}
                                                    onChange={(e) => setUserDays((prevFormData) => ({
                                                        ...prevFormData,
                                                        endDate: e.value as Date,
                                                    }))}
                                                    error={getErrorMessage(errorsDays, 'endDate')}
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <InputField
                                            type='password'
                                            name="password"
                                            label="Nueva Contraseña"
                                            value={userData.password}
                                            onChange={handleInputChange}
                                            error={getErrorMessage(errors, 'password')}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <InputField
                                            type='password'
                                            name="newPassword"
                                            label="Confirmar Contraseña"
                                            value={userData.newPassword}
                                            onChange={handleInputChange}
                                            error={getErrorMessage(errors, 'newPassword')}
                                        />
                                    </div>
                                </div>
                                <SwitchField
                                    label="Estado"
                                    checked={userData.active}
                                    onChange={handleInputChange}
                                    name="active"
                                />
                                {errors.find(error => error.field === 'server') && (
                                    <div className="alert alert-danger" role="alert">
                                        {getErrorMessage(errors, "server")}
                                    </div>
                                )}
                                {errorsDays.find(error => error.field === 'server') && (
                                    <div className="alert alert-danger" role="alert">
                                        {getErrorMessage(errorsDays, "server")}
                                    </div>
                                )}
                            </form>
                        </BaseModal>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UsersModal;
