import React from 'react';
import { Link } from 'react-router-dom';
import { img_api } from '../../environment';

interface Image {
  className?: string;
  src: any;
  alt?: string;
  routeName?: string;
  height?: number;
  width?: number;
  id?: string;
  style?: any;
  isExternalLink?: boolean;
}

const ImageWithBaseApi = (props: Image) => {
  const fullSrc = `${img_api}${props.routeName}/${props.src}`;

  if (props.isExternalLink) {
    return (
      <a href={fullSrc} target="_blank" rel="noopener noreferrer">
        <img
          className={props.className}
          src={fullSrc}
          height={props.height}
          alt={props.alt}
          width={props.width}
          id={props.id}
          style={props.style}
        />
      </a>
    );
  }

  return (
      <img
        className={props.className}
        src={fullSrc}
        height={props.height}
        alt={props.alt}
        width={props.width}
        id={props.id}
        style={props.style}
      />
  );
};

export default ImageWithBaseApi;