import React, { useState, useEffect } from 'react';
import { apiRepository } from '../../../api/apiRepository';
import { useResponsiveColumns } from '../../../core/hooks/useResponsiveColumnsCoupons';
import DataTableWrapper from '../common/dataTable/dataTableWrapper';
import CouponesModal from '../common/modals/users-payment-day-modal';
import DeleteUserModal from '../common/modals/delete-user-modal';

const PaymentDays = () => {
  const [data, setData] = useState([]);
  const [selectedPaymentDaysId, setSelectedPaymentDaysId] = useState<number | null>(null);
  const [selectedPaymentDays, setSelectedPaymentDays] = useState<string | null>(null);
  const [totalRecords, setTotalRecords] = useState<number>(-1);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const fetchUserInfo = async (userId) => {
    try {
      setLoading(true);
      const response = await apiRepository.getIdUsers(userId);
      return response.data.data
    } catch (error) {
      console.error("Error al obtener los días de pago:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchPaymentDays = async () => {
    try {
      setLoading(true);
      const paymentDaysResponse = await apiRepository.getPaymentDays();
      const paymentDays = paymentDaysResponse.data.data;
  
      const paymentDaysWithUserInfo = await Promise.all(
        paymentDays.map(async (payment) => {
          const userInfo = await fetchUserInfo(payment.userId);
          return {
            ...payment,
            userName: userInfo ? `${userInfo.name} ${userInfo.lastname}` : "Usuario no encontrado",
          };
        })
      );
  
      setData(paymentDaysWithUserInfo);
    } catch (error) {
      console.error("Error al obtener los días de pago:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPaymentDays();
  }, []);

  const handleEditClick = (rowData: any) => {
    setSelectedPaymentDays(null);
    setTimeout(() => setSelectedPaymentDays(rowData), 0);
  };

  const handleDeleteClick = (rowData: any) => {
    setSelectedPaymentDaysId(null);
    setTimeout(() => setSelectedPaymentDaysId(rowData.id), 0);
  };

  const columns = [
    { field: "id", header: "ID" },
    { field: "userName", header: "Usuario" }, // Muestra el nombre concatenado
    { field: "quantity", header: "Cantidad" },
    {
      field: "endDate",
      header: "Fecha de Fin",
      body: (rowData) => new Date(rowData.endDate).toLocaleDateString("es-PE"),
    },
    { field: "type", header: "Tipo" },
    {
      field: "active",
      header: "Estado",
      body: (rowData) => (
        <span className={rowData.status ? 'badge-active' : 'badge-inactive'}>
          {rowData.status ? 'Activo' : 'Inactivo'}
        </span>
      )
    },
  ];

  const getColumns = useResponsiveColumns(columns);

  const onPageChange = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
    const pageNumber = event.first / event.rows;
    setCurrentPage(pageNumber);
    fetchPaymentDays(pageNumber, event.rows);
  };

  return (
    <div className="page-wrapper page-settings">
      <div className="content">
        <div className="content-page-header content-page-headersplit">
          <h5>Días de Pago</h5>
          <div className="list-btn">
            <button className="btn btn-primary-admin" onClick={handleEditClick}>
              <i className="fa fa-plus me-2" /> Agregar Clases
            </button>
          </div>
        </div>
          <DataTableWrapper
            data={data}
            columns={getColumns()}
            onEdit={handleEditClick}
            onDelete={handleDeleteClick}
            loading={loading}
            paginator
            showGridlines
            rowsPerPage={rows}
            totalRecords={totalRecords}
            first={first}
            onPageChange={onPageChange}
            emptyMessage="No se encontraron días de pago."
          />
      </div>
      <CouponesModal selectedPaymentDays={selectedPaymentDays} onUpdateSuccess={fetchPaymentDays} />
      {/* <DeleteUserModal selectedPaymentDaysId={selectedPaymentDaysId} onDeleteSuccess={fetchPaymentDays} /> */}
    </div>
  );
};

export default PaymentDays;