import React from 'react';
import * as Icon from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { all_routes } from '../../../../core/data/routes/all_routes';

export interface PaymentSuccessModalProps {
    message: string;
}

const PaymentSuccessModal: React.FC<PaymentSuccessModalProps> = ({ message }) => {
  const routes = all_routes;
  const navigate = useNavigate();

  const handleGoToDashboard = () => {
    const successModalButton = document.querySelector('#payment-success [data-bs-dismiss="modal"]');
    if (successModalButton) {
        successModalButton.click();
    }

    navigate(routes.customerDashboard);
  };

  const handleGoToHome = () => {
    const successModalButton = document.querySelector('#payment-success [data-bs-dismiss="modal"]');
    if (successModalButton) {
        successModalButton.click();
    }

    navigate(routes.index);
  };

  return (
    <div>
        <div className="modal fade" id="payment-success">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Pago Exitoso</h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        />
                    </div>
                    <div className="modal-body text-center pt-0">
                        <Icon.CheckCircle size={45} color='#28a745' className="text-success fs-1" />
                        <div className="mt-4">
                            <h4>{message}</h4>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-4">
                        <button
                            type="button"
                            className="btn w-sm btn-secondary"
                            data-bs-dismiss="modal"
                            onClick={handleGoToHome}
                        >
                            Volver a comprar
                        </button>
                        <button
                            type="button"
                            className="btn w-sm btn-success"
                            onClick={handleGoToDashboard}
                        >
                            Ir a mi Dashboard
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default PaymentSuccessModal;