import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { AuthContext } from '../../../../core/context/AuthContext';
import { apiRepository } from '../../../../api/apiRepository';
import { PromotionSection } from '../../../../core/Interface/repositoryInterfaces';

const TrialSection = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    return <div>Error: Auth context not found!</div>;
  }

  const [sectionData, setSectionData] = useState<PromotionSection>([]);
  const { user } = authContext;
  const [userProfile] = useState(user?.role);

  const fetchPromotionSection = async () => {
    try {
      const response = await apiRepository.getPromotionSection();
      setSectionData(response.data.data);
    } catch (error) {
      console.error("Error al obtener los datos de la sección:", error);
    }
  };

  useEffect(() => {
    fetchPromotionSection();
  }, []);

  return (
    <>
        <section className="service-section">
      {userProfile !== 'customer' && userProfile !== 'trainer' && (
          <div className="container pb-5 pt-5  col-11">
            <div className="row mt-4 align-items-center">
              <div className="col-md-6 col-12">
                <div className="appimg aos">
                  <ImageWithBasePath
                    src="assets/img/lift/free-trial.png"
                    className="img-fluid"
                    alt="img"
                  />
                </div>
              </div>
              <div className="mt-4 col-md-6 col-12">
                <div className="download-sec trial-section">
                  {/* <div className="section-heading-two">
                    <p>¡Prueba gratuita de nuestras sesiones!</p>
                    <h2>Descubre tu mejor versión con una prueba gratuita</h2>
                  </div>
                  <div className="heading aos">
                    <p>
                      Somos un centro de entrenamiento enfocado en la fisiología
                      de la mujer. Entrenadoras con más de 10 años de experiencia
                      te guiarán para alcanzar tus objetivos de salud y estética.
                    </p>
                    <h6>
                      ¡Reserva tu prueba gratuita y da el primer paso hacia tus
                      metas!
                    </h6>
                  </div> */}
                  <div dangerouslySetInnerHTML={{ __html: sectionData.description }} />
                  <div className="downlaod-btn aos mt-3">
                    <div className="pricing-plans price-new">
                      <Link to={sectionData?.link} className="btn">
                        Reserva Gratis
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      )}
        </section>
  </>
  );
};

export default TrialSection;