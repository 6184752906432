import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import BreadCrumb from '../../common/breadcrumb/breadCrumb';
import { apiRepository } from '../../../../api/apiRepository';

const Faq = () => {
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });

    const fetchFaqs = async () => {
      try {
        const response = await apiRepository.getFaqs();
        setFaqs(response.data.data);
      } catch (error) {
        console.error('Error al obtener FAQs:', error);
      }
    };

    fetchFaqs();
  }, []);

  return (
    <>
      <BreadCrumb title='Preguntas Frecuentes' item1='Home' item2='Preguntas Frecuentes'/>
      <div className="page-wrapper">
        <div className="content">
          <div className="container">
            <div className="row faq-content justify-content-center">
              <div className="col-md-10 mx-auto faq">
                <div className="accordion">
                  {faqs
                      .filter((faq: any) => faq.status)
                      .map((faq: any, index: number) => {
                    const collapseId = `panelsStayOpen-collapse${index + 1}`;
                    return (
                      <div className="accordion-item" key={index + 1}>
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#${collapseId}`}
                            aria-expanded="false"
                            aria-controls={collapseId}
                          >
                            {faq.title}
                          </button>
                        </h2>
                        <div
                          id={collapseId}
                          className={`accordion-collapse collapse ${index+1 === 0 ? 'show' : ''}`}
                        >
                          <div className="accordion-body">
                            <div dangerouslySetInnerHTML={{ __html: faq.description }} />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;