import React, { useContext, useEffect, useState  } from 'react';
import { Link } from 'react-router-dom';
import { CartContext } from '../../../../core/context/CartContext';
import { getCart } from '../../../../core/services/cartService';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { AuthContext } from '../../../../core/context/AuthContext';

interface PricingPlansProps {
  packages: Array<{
      price: string;
      title: string;
      description: string;
    }>;
  }

  const PricingPlans: React.FC<PricingPlansProps> = ({ packages }) => {
    const routes = all_routes;
    const authContext = useContext(AuthContext);
    const { user } = authContext;
    const cartContext = useContext(CartContext);
    const { cart, addToCart, setCart } = cartContext;
    const [loadingPlan, setLoadingPlan] = useState<number | null>(null);

    useEffect(() => {
      const currentCart = getCart(user?.userId);
      setCart(currentCart);
    }, [setCart]);

    const handleAddToCart = (packageId: number) => {
      setLoadingPlan(packageId);
      setTimeout(() => {
        addToCart(packageId, setCart);
        setLoadingPlan(null);
      }, 1000);
    };

    return (
        <div className="container col-11">
          <div className="row aos">
            {packages.map((suscrib, index) => (
              <div className="col-lg-4 col-sm-12" key={index}>
                <div className={`pricing-plans price-new ${index === 1 ? 'active' : ''}`}>
                  <div className="pricing-planshead">
                    <h6>S/ {suscrib.price}</h6>
                    <h4>{suscrib.title}</h4>
                    <h5>{suscrib.subtitle}</h5>
                  </div>
                  <div className="pricing-planscontent">
                  <ul>
                      {suscrib.description.split('|').map((feature: string, i: number) => (
                        <li key={i}>
                          <i className="fa fa-check-circle me-2" />
                          <span>{feature.trim()}</span> {/* Usar trim() para quitar espacios extras */}
                        </li>
                      ))}
                    </ul>
                    <div className="pricing-btn">
                      {cart.some(item => item.id === suscrib.id) ? (
                        <Link to={routes.customerCart} className="btn btn-view">
                            <i className="fa fa-check me-2 ms-0"></i>

                            Ver Carrito
                        </Link>
                      ) : (
                        <Link
                          to="#"
                          className={`btn btn-view ${loadingPlan === suscrib.id ? 'disabled' : ''}`}
                          onClick={(e) => {
                            if (loadingPlan === suscrib.id) {
                              e.preventDefault();  // Evita la acción de navegar si está "deshabilitado"
                            } else {
                              handleAddToCart(suscrib.id);  // Si no está deshabilitado, ejecuta la acción
                            }
                          }}
                        >
                        {loadingPlan === suscrib.id ? (
                          <i className="fa fa-spinner fa-spin"></i>
                          ) : (
                          'Quiero este Plan'
                        )}
                        </Link>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
    );
  };

  export default PricingPlans;