import React, { useState, useEffect } from 'react';
import { apiRepository } from '../../../api/apiRepository';
import { Users } from '../../../core/Interface/repositoryInterfaces';
import DataTableWrapper from '../common/dataTable/dataTableWrapper';
import UsersModal from '../common/modals/users-modal';
import DeleteUserModal from '../common/modals/delete-user-modal';
import { useResponsiveColumns } from '../../../core/hooks/useResponsiveColumns';

const UsersManage = () => {
  const [data, setData] = useState<Users[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [totalUsers, setTotalUsers] = useState<number>(-1);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const fetchUsers = async (pageNumber = currentPage, pageSize = rows) => {
    try {
      setLoading(true);
      const response = await apiRepository.getUsers(pageNumber, pageSize);
      const totalRecords = response.data.data.totalElements;
      setData(response.data.data.content);
      setTotalUsers(totalRecords);
    } catch (error) {
      console.error("Error al obtener los datos de usuarios:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleEditClick = (rowData: any) => {
    setSelectedUser(null);
    setTimeout(() => setSelectedUser(rowData), 0);
  };

  const handleDeleteClick = (rowData: any) => {
    setSelectedUserId(null);
    setTimeout(() => setSelectedUserId(rowData.id), 0);
  };

  const columns = [
    {
      field: "name",
      header: "Nombre Completo",
      body: (rowData) => {
        return (
          <span>
            {rowData.name} {rowData.lastname}
          </span>
        );
      }    },
    { field: "phone", header: "Teléfono" },
    { field: "profile", header: "Rol" },
    { field: "dni", header: "DNI" },
    { field: "email", header: "Correo" },
    {
      field: "active",
      header: "Estado",
      body: (rowData) => (
        <span className={rowData.active ? 'badge-active' : 'badge-inactive'}>
          {rowData.active ? 'Activo' : 'Inactivo'}
        </span>
      )
    },
    { field: "birthdate", header: "Nacimiento" },
  ];

  const getColumns = useResponsiveColumns(columns);

  const onPageChange = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
    const pageNumber = event.first / event.rows
    setCurrentPage(pageNumber);
    fetchUsers(pageNumber, event.rows);
  };

  return (
    <div className="page-wrapper page-settings">
      <div className="content">
        <div className="content-page-header content-page-headersplit">
          <h5>Usuarios</h5>
          <div className="list-btn">
            <button className="btn btn-primary-admin" onClick={handleEditClick}>
              <i className="fa fa-plus me-2" /> Agregar Usuario
            </button>
          </div>
        </div>
        <DataTableWrapper
          data={data}
          columns={getColumns()}
          onEdit={handleEditClick}
          onDelete={handleDeleteClick}
          loading={loading}
          paginator
          showGridlines
          rowsPerPage={rows}
          totalRecords={totalUsers}
          first={first}
          onPageChange={onPageChange}
          emptyMessage="No se encontraron usuarios."
        />
      </div>
      <UsersModal selectedUser={selectedUser} onUpdateSuccess={fetchUsers} />
      <DeleteUserModal selectedUserId={selectedUserId} onDeleteSuccess={fetchUsers} />
    </div>
  );
};

export default UsersManage;