import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from '@hello-pangea/dnd';
import { apiRepository } from '../../../api/apiRepository';
import { Banners } from '../../../core/Interface/repositoryInterfaces';
import {
  addNewServiceRow as addNewServiceRowCore,
  deleteServiceRow as deleteServiceRowCore,
  handleOnDragEnd as handleOnDragEndCore,
} from '../../../core/services/dragAndDropServices';
import {
  validateBanner,
  ValidationError,
} from '../../../core/form/validationForm';
import ImageWithBaseApi from '../../../core/img/ImageWithBaseApi';
import { InputField } from '../common/input/inputField';

const AddHome = () => {
  const [services, setServices] = useState<Banners[]>([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<ValidationError[]>([]);

  const addNewServiceRow = () => {
    const updatedServices = addNewServiceRowCore(services);
    setServices(updatedServices);
  };

  const deleteServiceRow = async (id: string) => {
    const updatedServices = await deleteServiceRowCore(
      id,
      services,
      apiRepository,
    );
    setServices(updatedServices);
  };

  const handleOnDragEnd = (result: DropResult) => {
    const updatedServices = handleOnDragEndCore(result, services);
    setServices(updatedServices);
  };

  const handleInputChange = (
    id: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = event.target;
    const updatedServices = services.map((service) =>
      service.id === id ? { ...service, [name]: value } : service,
    );
    setServices(updatedServices);
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string,
  ) => {
    if (event.target.files && event.target.files[0]) {
      const updatedServices = services.map(
        (service) =>
          service.id === id
            ? { ...service, image: event.target.files[0] }
            : service, // Guardar el archivo real
      );
      setServices(updatedServices);
    }
  };

  const handleCheckboxChange = (id: string) => {
    const updatedServices = services.map((service) =>
      service.id === id ? { ...service, status: !service.status } : service,
    );
    setServices(updatedServices);
  };

  const validateServices = (services: Service[]): ValidationError[] => {
    let allErrors: ValidationError[] = [];
    services.forEach((service) => {
      const errors = validateBanner(service).map((error) => ({
        ...error,
        id: service.id,
      }));
      allErrors = allErrors.concat(errors);
    });
    return allErrors;
  };

  const fetchBanners = async () => {
    setLoading(true);
    try {
      const response = await apiRepository.getBanners();
      const servicesWithStringIds = response.data.data.map((service) => ({
        ...service,
        id: service.id.toString(),
        image: service.image || '',
        status: service.status,
        upload: true,
      }));

      servicesWithStringIds.sort((a, b) => a.position - b.position);

      setLoading(false);
      setServices(servicesWithStringIds);
    } catch (error) {
      console.error('Error al obtener los datos de paquetes:', error);
    }
  };

  const handleCreateHome = async () => {
    setErrors([]);
    const validationErrors = validateServices(services);

    if (validationErrors.length > 0) {
      setErrors(validationErrors);
      return;
    }

    setLoading(true);
    try {
      for (const service of services) {
        const formData = new FormData();
        for (const key in service) {
          if (Object.prototype.hasOwnProperty.call(service, key)) {
            formData.append(key, service[key]);
          }
        }

        if (!service.upload) {
          await apiRepository.createBanner(formData);
        } else {
          await apiRepository.updateBanner(service.id, formData);
        }
      }

      validationErrors.push({
        field: 'success',
        message: '¡La actualización de datos fue exitosa!',
      });
      setErrors(validationErrors);
      await fetchBanners();
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        const cleanedMessage = error.response.data.message.replace(
          /^Bad request: /,
          '',
        );
        validationErrors.push({ field: 'server', message: cleanedMessage });
      } else {
        validationErrors.push({
          field: 'server',
          message:
            'Ocurrió un error al intentar iniciar sesión. Inténtalo de nuevo.',
        });
      }
      setErrors(validationErrors);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBanners();
  }, []);

  return (
    <div className="page-wrapper ">
      <div className="content">
        <div className="container-service col-lg-11 col-sm-12 m-auto">
          <div className="content-page-header">
            <div className="row">
              <div className="col-sm-12">
                <div className="additional">
                  <div className="row w-100 mb-2">
                    <div className="col sub-title Service">
                      <h6 className="mb-0">Banner del Home</h6>
                    </div>

                    <div className="col-8 profile-upload-para text-end">
                      <p className="truncate-text mt-0">
                        * Recomendamos un tamaño de 1920px de ancho y 800px de
                        alto. Solo se aceptan imágenes jpg y png.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {errors.find((error) => error.field === 'success') && (
              <div className="alert alert-success" role="alert">
                {errors.find((error) => error.field === 'success')?.message}
              </div>
            )}
            {errors.find((error) => error.field === 'server') && (
              <div className="alert alert-danger" role="alert">
                {errors.find((error) => error.field === 'server')?.message}
              </div>
            )}
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="droppable-services ">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {services.map((service, index) => (
                      <Draggable
                        key={service.id}
                        draggableId={service.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="row service-cont drag-order"
                            style={{
                              backgroundColor: snapshot.isDragging
                                ? '#f9f9f9'
                                : 'white',
                              padding: '10px',
                              borderRadius: '10px',
                              marginBottom: '10px',
                              ...provided.draggableProps.style,
                            }}
                          >
                            <div className="col-6 col-md-6  col-lg-4 col-xl-4">
                              <div className="form-group">
                                <InputField
                                  className="form-control"
                                  label="Titulo del Banner"
                                  name="title"
                                  value={service.title}
                                  onChange={(event) =>
                                    handleInputChange(service.id, event)
                                  }
                                  error={
                                    errors.find(
                                      (error) =>
                                        error.id === service.id &&
                                        error.field === 'title',
                                    )?.message
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-6 col-md-6 col-lg col-xl-2">
                              <div className="form-group">
                                <InputField
                                  className="form-control"
                                  label="Descripción"
                                  name="subtitle"
                                  value={service.subtitle}
                                  onChange={(event) =>
                                    handleInputChange(service.id, event)
                                  }
                                  error={
                                    errors.find(
                                      (error) =>
                                        error.id === service.id &&
                                        error.field === 'subtitle',
                                    )?.message
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-3 col-xl-2">
                              <div className="form-group">
                                <InputField
                                  className="form-control"
                                  label="Link del Banner"
                                  name="path"
                                  value={service.path}
                                  onChange={(event) =>
                                    handleInputChange(service.id, event)
                                  }
                                  error={
                                    errors.find(
                                      (err) =>
                                        err.id === service.id &&
                                        err.field === 'path',
                                    )?.message
                                  }
                                />
                              </div>
                            </div>
                            <div className="col d-flex">
                              <div className="flex-grow-1">
                                <div className="form-group me-2">
                                  <label className="form-label w-100">
                                    <span>Imagen del Banner</span>
                                  </label>
                                  <div className="d-flex w-100 mb-3 align-items-start">
                                    <div className="profile-upload-content">
                                      <div className="profile-upload-btn">
                                        <div className="profile-upload-file btn btn-upload">
                                          <input
                                            type="file"
                                            accept="image/*"
                                            onChange={(event) =>
                                              handleFileChange(event, service.id)
                                            }
                                          />
                                          <Link
                                            to="#"
                                          >
                                            Subir
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="profile-upload-img">
                                      {service.image instanceof File ? (
                                        <img
                                          className="img-fluid shadow rounded"
                                          src={URL.createObjectURL(service.image)}
                                          alt="Sube una imagen"
                                          style={{
                                            width: '150px',
                                            height: 'auto',
                                          }}
                                        />
                                      ) : (
                                        <ImageWithBaseApi
                                          src={`${service.image}`}
                                          routeName="banner"
                                          className="img-fluid shadow rounded"
                                          alt="Sube una imagen"
                                          style={{
                                            width: '150px',
                                            height: 'auto',
                                          }}
                                          isExternalLink={false}
                                        />
                                      )}
                                    </div>
                                  </div>
                                  {errors.find((error) => error.id === service.id && error.field === 'image') && (
                                    <small className="text-danger">
                                      {errors.find((error) => error.id === service.id && error.field === 'image')?.message}
                                    </small>
                                  )}
                                </div>
                              </div>
                              <div className="d-flex flex-shrink-0">
                                <div className="form-group">
                                  <label>
                                    <span>Estado</span>
                                  </label>
                                  <br />
                                  <div className="active-switch">
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        name="status"
                                        checked={service.status}
                                        onChange={() => handleCheckboxChange(service.id)}
                                      />
                                      <span className="sliders round" />
                                    </label>
                                  </div>
                                </div>
                                {services.length > 1 && (
                                  <div className="form-group d-flex justify-content-center align-items-start ms-3">
                                    <button
                                      onClick={() => deleteServiceRow(service.id)}
                                      className="btn mt-3 p-0"
                                    >
                                      <Icon.Trash2 className="react-feather-custom trashicon" />
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>

                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <div className="row">
              <div className="col-6 d-flex justify-content-start">
                <Link
                  to="#"
                  className="link-sets add-extra"
                  onClick={addNewServiceRow}
                >
                  <i className="fa fa-plus-circle me-2" aria-hidden="true" />
                  Agregar Banner
                </Link>
              </div>

              <div className="col-6 d-flex justify-content-end">
                <button
                  className="btn btn-primary-admin mb-4"
                  type="button"
                  onClick={handleCreateHome}
                >
                  {loading ? (
                    <>
                      <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                      Guardando...
                    </>
                  ) : (
                    'Guardar cambios'
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddHome;