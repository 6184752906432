import React, { useContext, useEffect } from 'react';
import Slider from "react-slick";
import ImageWithBaseApi from '../../../../core/img/ImageWithBaseApi';
import { CartContext } from '../../../../core/context/CartContext';
import { AuthContext } from '../../../../core/context/AuthContext';
import { getCart } from '../../../../core/services/cartService';

interface Package {
  id: string;
  title: string;
  price: string;
  description: string;
  features: string[];
}

interface ProductSliderProps {
  products: Package[];
}


const ProductCarousel: React.FC<ProductSliderProps> = ({ products }) => {
  const cartContext = useContext(CartContext);
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const { cart, addToCart, setCart, removeFromCart } = cartContext;
  const slidesToShowCount = products.length >= 4 ? 4 : products.length;

  const topProjects = {
    dots: true,
    autoplay: false,
    slidesToShow: slidesToShowCount,
    speed: 500,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: slidesToShowCount,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: slidesToShowCount >= 3 ? 3 : slidesToShowCount,
        },
      },
      {
        breakpoint: 776,
        settings: {
          slidesToShow: slidesToShowCount >= 2 ? 2 : slidesToShowCount,
        },
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const currentCart = getCart(user?.userId);
    setCart(currentCart);
  }, []);

  const renderPackage = (product: Package, index: number) => (
    <div className="providerset col-md-4" key={index}>
      <div className="providerset-img providerset-img-seven">
        <ImageWithBaseApi routeName="packet" src={product.image} alt="img" />
      </div>
      <div className="providerset-content providerset-content-seven">
        <div className="providerset-price">
          <div className="providerset-name">
            <h4>
              {product.title}
            </h4>
            <span className="text-primary">{product.description}</span>
          </div>
        </div>
        <div className="provider-rating-seven">
          <div className="providerset-prices">
            <h6>
              S/ {product.price}
            </h6>
          </div>
          {cart.some(item => item.id === product.id) ? (
            (() => {
              const productInCart = cart.find(item => item.id === product.id);
              return (
                <div className="d-flex align-items-center">
                  <button
                    className="btn btn-light me-2"
                    onClick={() => removeFromCart(product.id, setCart)}
                  >
                    <i className="fa fa-minus" />
                  </button>
                  <button className="btn btn-light me-2">
                    {productInCart?.quantity}
                  </button>
                  <button
                    className="btn btn-light"
                    onClick={() => addToCart(product.id, setCart)}
                  >
                    <i className="fa fa-plus" />
                  </button>
                </div>
              );
            })()
          ) : (
            <button
              className="btn btn-primary-user"
              onClick={() => addToCart(product.id, setCart)}
            >
              Agregar al Carrito
            </button>
          )}

        </div>
      </div>
    </div>
  );

  return (
    <div className="row aos">
      <div className="col-md-12">
        <Slider {...topProjects} className="product-service">
          {products.map(renderPackage)}

        </Slider>
      </div>
    </div>
  );
};

export default ProductCarousel;
