import React, { useState, useEffect } from 'react';
import { apiRepository } from '../../../../api/apiRepository';
import { Schedules } from '../../../../core/Interface/repositoryInterfaces';
import { validateSchedule, ValidationError } from '../../../../core/form/validationForm';
import { InputField, CalendarField, TimePickerField, DropdownField, AutoCompleteDropdown } from '../../../admin/common/input/inputField';
import { handleChange,  formatDatetoString } from '../../../../core/utils/utils';
import { getErrorMessage } from '../../../../core/utils/errorUtils';
import { handleFormSubmit } from '../../../../core/services/formService';
import { CLASS_STATUS, MINUTES_SUGGESTINOS } from '../../../../core/data/options/options';
import BaseModal from './baseModal';
import { convertToLocalDate, convertToLocalDateString } from '../../../../core/utils/dateUtils';
import moment from 'moment-timezone';

export interface UpdateScheduleModalProps {
    selectedSchedule?: Schedules;
    onUpdateSuccess?: () => void;
    trainerList?: { value: string; label: string }[];
}

const SchedulesModal: React.FC<UpdateScheduleModalProps> = ({ selectedSchedule, onUpdateSuccess, trainerList }) => {
    const [formData, setFormData] = useState([]);
    const [errors, setErrors] = useState<ValidationError[]>([]);
    const [show, setShow] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [classes, setClasses] = useState([]);
    const [headquarters, setHeadquarters] = useState([]);
    const [selectedHeadquarters, setSelectedHeadquarters] = useState<any>(null);

    const [filteredSuggestions, setFilteredSuggestions] = useState(MINUTES_SUGGESTINOS);
    const handleSearch = (query: string) => {
        setFilteredSuggestions(
            MINUTES_SUGGESTINOS.filter((option) =>
                option.label.toLowerCase().includes(query.toLowerCase())
            )
        );
    };

    const loadClasses = async () => {
        try {
            const classData = await apiRepository.getClasses();
            setClasses(classData.data.data.map((classItem: { id: string, title: string, headquartersId: string }) => ({
                label: classItem.title,
                value: classItem.id,
                headquartersId: classItem.headquartersId,
            })));
        } catch (error) {
            console.error('Error al obtener las clases:', error);
        }
    };

    const loadHeadquarters = async () => {
        try {
            const headquarterData = await apiRepository.getHeadquarters();
            setHeadquarters(headquarterData.data.data.map((headquarterItem: { id: string, name: string }) => ({
                label: headquarterItem.name,
                value: headquarterItem.id,
            })));
        } catch (error) {
            console.error('Error al obtener las clases:', error);
        }
    };

    const filteredClasses = classes.filter((classItem) =>
        !selectedHeadquarters || classItem.headquartersId === selectedHeadquarters
    );

    useEffect(() => {
        loadHeadquarters();
        loadClasses();
    }, []);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        handleChange(e, setFormData);
    };

    const handleClose = () => {
        setShow(false);
        setErrors([]);
        setFormData([]);
        setSelectedHeadquarters(null);
        setLoading(false);
    };

    useEffect(() => {
        if (selectedSchedule) {
            const start = moment(selectedSchedule.hourStart, "HH:mm");
            const end = moment(selectedSchedule.hourEnd, "HH:mm");
            const minutesDifference = end.diff(start, 'minutes');

            setFormData({
                id: selectedSchedule.id,
                hourEnd: selectedSchedule.hourEnd,
                hourStart: selectedSchedule.hourStart,
                quantity: selectedSchedule.quantity,
                status: selectedSchedule.status,
                title: selectedSchedule.title,
                trainerId: selectedSchedule.trainerId,
                classId: selectedSchedule.classId,
                minutes: minutesDifference,
                date: selectedSchedule.date && !isNaN(new Date(selectedSchedule.date).getTime())
                    ? convertToLocalDate(selectedSchedule.date)
                    : convertToLocalDate(formatDatetoString(new Date())),
            });
            setShow(true);
        }
    }, [selectedSchedule]);

    useEffect(() => {
        if (formData.classId) {
          const selectedClass = classes.find(c => c.value === formData.classId);
          if (selectedClass && !formData.title) {
            setFormData(prevFormData => ({
              ...prevFormData,
              title: selectedClass.label,
            }));
          }
        }
      }, [formData.classId, formData.title]);

    const handleConfirmCreate = async (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);

            const start = moment(formData.hourStart, "HH:mm");
            const end = start.clone().add(Number(formData.minutes), 'minutes');

        const errors = await handleFormSubmit(
            formData.id,
            formData,
            validateSchedule,
            {
                create: apiRepository.createSchedule,
                update: apiRepository.updateSchedule,
            },
            () => {
                onUpdateSuccess?.();
                handleClose();
            },
            (data) => ({
                ...data,
                date: convertToLocalDateString(data.date),
                hourEnd: end.format("HH:mm"),
            }),
            "Error al procesar la solicitud. Inténtalo de nuevo."
        );

        if (errors) {
            setErrors(errors);
        }

        setLoading(false);
    };

    return (
        <BaseModal
            show={show}
            title={formData.id === -1 ? "Agregar Clase" : "Editar Clase"}
            onClose={handleClose}
            onConfirm={handleConfirmCreate}
            loading={loading}
        >
            <form onSubmit={handleConfirmCreate}>
                <div className="row">
                    <div className="col-md-6">
                        <DropdownField
                            label="Sede"
                            value={selectedHeadquarters}
                            options={headquarters}
                            onChange={(e) => setSelectedHeadquarters(e.value)}
                            placeholder="Selecciona una sede"
                        />
                    </div>
                    <div className="col-md-6">
                        <DropdownField
                            label="Clase"
                            name="classId"
                            value={formData.classId || ''}
                            options={filteredClasses}
                            onChange={handleInputChange}
                            placeholder="Selecciona la clase del horario"
                            error={getErrorMessage(errors, 'classId')}
                        />
                    </div>
                </div>

                {trainerList && trainerList.length > 0 && (
                    <DropdownField
                        label="Selecciona al entrenador"
                        name="trainerId"
                        options={trainerList}
                        value={formData.trainerId}
                        onChange={handleInputChange}
                        placeholder="Selecciona al entrenador de la clase"
                        error={getErrorMessage(errors, 'trainerId')}
                    />
                )}
                <div className="row">
                    <div className="col-md-6">
                        <InputField
                            label="Título"
                            name="title"
                            value={formData.title || ''}
                            onChange={handleInputChange}
                            error={getErrorMessage(errors, 'title')}
                        />
                    </div>
                    <div className="col-md-6">
                        <InputField
                            label="Cantidad"
                            name="quantity"
                            value={formData.quantity || ''}
                            onChange={handleInputChange}
                            error={getErrorMessage(errors, 'quantity')}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <CalendarField
                            label="Fecha"
                            name="date"
                            value={formData.date || ''}
                            onChange={(e) => {
                                setFormData((prevFormData) => ({
                                  ...prevFormData,
                                  date: convertToLocalDate(e.value),
                                }));
                            }}
                            error={getErrorMessage(errors, 'date')}
                        />
                    </div>
                    <div className="col-md-6">
                        <DropdownField
                            label="Estado de la clase"
                            name="status"
                            options={CLASS_STATUS}
                            value={formData.status || ''}
                            onChange={handleInputChange}
                            placeholder="Selecciona el estado de la clase"
                            error={getErrorMessage(errors, 'status')}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <TimePickerField
                            label="Hora de Inicio"
                            value={formData.hourStart || ''}
                            format="hh:mm a"
                            onChange={(newValue: string) => setFormData(prevFormData => ({
                                ...prevFormData,
                                hourStart: newValue,
                            }))}
                            error={getErrorMessage(errors, 'hourStart')}
                        />
                    </div>
                    <div className="col-md-6">
                        <AutoCompleteDropdown
                            label="Duración de la clase en minutos"
                            value={formData.minutes || ''}
                            suggestions={filteredSuggestions}
                            name="minutes"
                            onChange={(val) => setFormData(prevFormData => ({
                                ...prevFormData,
                                minutes: val,
                            }))}
                            onSearch={handleSearch}
                            error={getErrorMessage(errors, 'minutes')}
                        />
                    </div>
                </div>
                {errors.find(error => error.field === 'server') && (
                    <div className="alert alert-danger" role="alert">
                        {getErrorMessage(errors, "server")}
                    </div>
                )}
            </form>
        </BaseModal>
    );
};

export default SchedulesModal;