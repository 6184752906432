export const TYPE_DISCOUNT = [
    { label: 'Porcentaje', value: true },
    { label: 'Monto Fijo', value: false },
];

export const TYPE_GENERAL_PRODUCT = [
    { label: 'Descuento General', value: false },
    { label: 'Descuento de Paquete', value: true },
];

export const TYPE_ROLES_USERS = [
    { label: 'Customer', value: 'customer' },
    { label: 'Trainer', value: 'trainer' },
    { label: 'Admin', value: 'admin' },
];

export const TYPE_ACTIONS_USERS = [
    { label: 'Aprobar', value: true },
    { label: 'Denegar', value: false },
];

export const CLASS_STATUS = [
    { value: 1, label: 'Pendiente' },
    { value: 2, label: 'Atención' },
    { value: 3, label: 'Finalizada' },
    { value: 4, label: 'Cancelada' },
    { value: 5, label: 'Asistencia Terminada' },
];

export const TYPE_PACKAGE = [
    { label: 'Servicio', value: 1 },
    { label: 'Producto', value: 2 },
    { label: 'GiftCard', value: 3 },
];

export const TYPE_SUB_SERVICE = [
    { label: 'Clases', value: "Clases" },
    { label: 'Membresias', value: "Membresias" },
    { label: 'Clases Reformer', value: "Clases Reformer" },
    { label: 'Membresias Premium', value: "Membresias Premium" },
];

export const CLASSE_IS_PREMIUM = [
    { label: 'Clases Premium', value: true },
    { label: 'Clase Estandar', value: false },
];

export const MINUTES_SUGGESTINOS = [
    { label: '30', value: '30' },
    { label: '60', value: '60' },
    { label: '90', value: '90' },
];