import React, { useState, useEffect } from 'react';
import { apiRepository } from '../../../api/apiRepository';
import { Faq as FaqIntarface } from '../../../core/Interface/repositoryInterfaces';
import DataTableWrapper from '../common/dataTable/dataTableWrapper';
import FaqModal from '../common/modals/faq-modal';
import DeleteFaqModal from '../common/modals/delete-faq-modal';
import { useResponsiveColumns } from '../../../core/hooks/useResponsiveColumnsFaq';

const Faq = () => {
  const [selectedId, setSelectedId] = useState<any>(null);
  const [selectedFaq, setSelectedFaq] = useState<any>(null);
  const [data, setData] = useState<FaqIntarface[]>([]);

  const fetchFaqs = async () => {
    try {
      const response = await apiRepository.getFaqs();
      setData(response.data.data);
    } catch (error) {
      console.error("Error al obtener los datos de FAQs:", error);
    }
  };

  useEffect(() => {
    fetchFaqs();
  }, []);

  const handleEditClick = (rowData: any) => {
    setSelectedFaq(null);
    setTimeout(() => setSelectedFaq(rowData), 0);
  };

  const handleDeleteClick = (rowData: any) => {
    setSelectedId(null);
    setTimeout(() => setSelectedId(rowData.id), 0);
  };

  const columns = [
    { field: "title", header: "Pregunta" },
    {
      field: "status", header: "Estado", body: (rowData) => (
        <span className={rowData.status ? 'badge-active' : 'badge-inactive'}>
          {rowData.status ? 'Activo' : 'Inactivo'}
        </span>
      )
    }
  ];

  const getColumns = useResponsiveColumns(columns);

  return (
    <div className="page-wrapper page-settings">
      <div className="content ">
        <div className="container-service col-lg-11 col-sm-12 m-auto">
          <div className="content-page-header content-page-headersplit">
            <h5>FAQ’s</h5>
            <div className="list-btn">
              <button
                className="btn btn-primary-admin"
                type="button"
                onClick={handleEditClick}
              >
                <i className="fa fa-plus me-2" />
                Agregar FAQ
              </button>
            </div>
          </div>
          <DataTableWrapper
            data={data}
            columns={getColumns()}
            onEdit={handleEditClick}
            onDelete={handleDeleteClick}
            paginator={false}
            showGridlines
            emptyMessage="No se encontraron preguntas frecuentes."
          />
        </div>
        <FaqModal selectedFaq={selectedFaq} onUpdateSuccess={fetchFaqs} />
        <DeleteFaqModal selectedId={selectedId} onDeleteSuccess={fetchFaqs} />
      </div>
    </div>
  );
};

export default Faq;