import React, { useState, useEffect } from 'react';
import { apiRepository } from '../../../api/apiRepository';
import { Package, packageTypes } from '../../../core/Interface/repositoryInterfaces';
import DataTableWrapper from '../common/dataTable/dataTableWrapper';
import PackageModal from '../common/modals/package-modal';
import DeletePackageModal from '../common/modals/delete-packages-modal';
import { useResponsiveColumns } from '../../../core/hooks/useResponsiveColumnsPackage';

const Packages = () => {
    const [data, setData] = useState<Package[]>([]);
    const [categories, setCategories] = useState<packageTypes[]>([]);
    const [selectedPackageId, setSelectedPackageId] = useState<any>(null);
    const [selectedPackage, setSelectedPackage] = useState<any>(null);
    const [totalPackages, setTotalPackages] = useState<number>(-1);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);

    const fetchPackages = async (pageNumber = currentPage, pageSize = rows) => {
        setLoading(true);
        try {
            const response = await apiRepository.getPackage(pageNumber, pageSize);
            const totalRecords = response.data.data.totalElements;
            setData(response.data.data.content);
            setTotalPackages(totalRecords);
        } catch (error) {
            console.error("Error al obtener los datos de paquetes:", error);
        } finally {
            setLoading(false);
        }
    };

    const fetchCategories = async () => {
        try {
            const response = await apiRepository.getPackageTypes();
            const activeCategories = response.data.data.filter((category: any) => category.status === true);
            const formattedCategories = activeCategories.map((category: any) => ({
                label: category.name,
                value: category.id,
                type: category.type,
            }));
            setCategories(formattedCategories);
        } catch (error) {
            console.error("Error al obtener los datos de categorías:", error);
        }
    };

    useEffect(() => {
        fetchPackages();
        fetchCategories();
    }, []);

    const handleEditClick = (rowData: any) => {
        setSelectedPackage(null);
        setTimeout(() => setSelectedPackage(rowData), 0);
    };

    const handleDeleteClick = (rowData: any) => {
        setSelectedPackageId(null);
        setTimeout(() => setSelectedPackageId(rowData.id), 0);
    };

    const columns = [
        { field: "title", header: "Título" },
        { field: "price", header: "Precio", body: (rowData: any) => `S/ ${rowData.price?.toFixed(2) || '0.00'}` },
        {
            field: "packageTypeId",
            header: "Categoría",
            body: (rowData: any) => {
                const category = categories.find(cat => cat.value === rowData.packageTypeId);
                return category ? category.label : "Desconocido";
            }
        },
        { field: "quantityDate", header: "Cantidad de días" },
        {
            field: "status",
            header: "Estado",
            body: (rowData: any) => (
                <span className={rowData.status ? 'badge-active' : 'badge-inactive'}>
                    {rowData.status ? 'Activo' : 'Inactivo'}
                </span>
            )
        },
    ];

    const getColumns = useResponsiveColumns(columns);

    const onPageChange = (event: any) => {
        setFirst(event.first);
        setRows(event.rows);
        const pageNumber = event.first / event.rows;
        setCurrentPage(pageNumber);
        fetchPackages(pageNumber, event.rows);
    };

    return (
        <div className="page-wrapper page-settings">
            <div className="content">
                <div className="content-page-header content-page-headersplit">
                    <h5>Paquetes</h5>
                    <div className="list-btn">
                        <button className="btn btn-primary-admin" onClick={handleEditClick}>
                            <i className="fa fa-plus me-2" /> Agregar Paquete
                        </button>
                    </div>
                </div>
                <DataTableWrapper
                    data={data}
                    columns={getColumns()}
                    onEdit={handleEditClick}
                    onDelete={handleDeleteClick}
                    loading={loading}
                    paginator
                    showGridlines
                    rowsPerPage={rows}
                    totalRecords={totalPackages}
                    first={first}
                    onPageChange={onPageChange}
                    emptyMessage="No se encontraron paquetes."
                />
            </div>
            <PackageModal selectedPackage={selectedPackage} onUpdateSuccess={fetchPackages} categoriesPackage={categories} />
            <DeletePackageModal selectedPackageId={selectedPackageId} onDeleteSuccess={fetchPackages}/>
        </div>
    );
};

export default Packages;