import React, { useState, useEffect } from 'react';
import { apiRepository } from '../../../api/apiRepository';
import {  } from '../../../core/Interface/repositoryInterfaces';
import DataTableWrapper from '../common/dataTable/dataTableWrapper';
import SuscriptionsModal from '../common/modals/user-suscriptions-modal';
import DeleteUserModal from '../common/modals/delete-user-modal';

const UserSuscriptions = () => {
    const [data, setData] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState<any>(null);
    const [selectedUser, setSelectedUser] = useState<any>(null);
    const [totalUsers, setTotalUsers] = useState(-1);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);

    const fetchUsers = async (pageNumber = currentPage, pageSize = rows) => {
        try {
            setLoading(true);
            const response = await apiRepository.getSubscriptions(pageNumber, pageSize);
            const totalRecords = response.data.data.totalElements;
            setData(response.data.data.content);
            setTotalUsers(totalRecords);
        } catch (error) {
            console.error("Error al obtener los datos de usuarios:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const handleEditClick = (rowData: any) => {
        setSelectedUser(null);
        setTimeout(() => setSelectedUser(rowData), 0);
    };

    const handleDeleteClick = (rowData: any) => {
        setSelectedUserId(null);
        setTimeout(() => setSelectedUserId(rowData.id), 0);
    };

    const columns = [
        { field: "fullname", header: "Nombre Completo" },
        { field: "email", header: "Correo Electrónico" },
    ];

    const onPageChange = (event: any) => {
        setFirst(event.first);
        setRows(event.rows);
        const pageNumber = event.first / event.rows;
        setCurrentPage(pageNumber);
        fetchUsers(pageNumber, event.rows);
    };

    return (
        <div className="page-wrapper page-settings">
            <div className="content">
                <div className="content-page-header content-page-headersplit">
                    <h5>Usuarios</h5>
                    <div className="list-btn">
                        <button className="btn btn-primary-admin" onClick={handleEditClick}>
                            <i className="fa fa-plus me-2" /> Agregar Usuario
                        </button>
                    </div>
                </div>
                <DataTableWrapper
                    data={data}
                    columns={columns}
                    onEdit={handleEditClick}
                    onDelete={handleDeleteClick}
                    loading={loading}
                    paginator
                    showGridlines
                    rowsPerPage={rows}
                    totalRecords={totalUsers}
                    first={first}
                    onPageChange={onPageChange}
                    emptyMessage="No se encontraron usuarios."
                />
                <SuscriptionsModal selectedUser={selectedUser} onUpdateSuccess={fetchUsers} />
                <DeleteUserModal selectedUserId={selectedUserId} onDeleteSuccess={fetchUsers} />
            </div>
        </div>
    );
};

export default UserSuscriptions;