import React, { useState, useEffect, useContext } from 'react';
import { apiRepository } from '../../../../api/apiRepository';
import { Schedules } from '../../../../core/Interface/repositoryInterfaces';
import DataTableWrapper from '../../../admin/common/dataTable/dataTableWrapper';
import "react-datepicker/dist/react-datepicker.css";
import { useResponsiveColumns } from '../../../../core/hooks/useResponsiveColumnsTrainer';
import { useOutletContext } from 'react-router-dom';
import { AuthContext } from '../../../../core/context/AuthContext';
import { formatTimeTo12Hour } from '../../../../core/utils/utils';
import SchedulesModal from '../../../admin/common/modals/schedules-modal';
import DeleteSchedulesModal from '../../../admin/common/modals/delete-schedules-modal';

const CustomerProfile = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    return <div>Error: Auth context not found!</div>;
  }

  const { user } = authContext;
  const [data, setData] = useState<Schedules[]>([]);
  const [selectedScheduleId, setSelectedScheduleId] = useState<any>(null);
  const [selectedSchedules, setSelectedSchedules] = useState<any>(null);
  const [filterFuture, setFilterFuture] = useState(false);
  const { setBreadcrumbData } = useOutletContext();

  useEffect(() => {
    setBreadcrumbData({ title: "Horario", item1: "Entrenador", item2: "Horario" });
  }, [setBreadcrumbData]);

  const filterSchedules = (schedules: any) => {
    const today = new Date();
    const startOfToday = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate()));

    if (filterFuture) {
      return schedules.filter(schedule => {
        const scheduleDate = new Date(schedule.date);
        return scheduleDate >= startOfToday;
      });
    }
    return schedules;
  };

  const fetchSchedules = async () => {
    try {
      let response;

      if (user?.role === "trainer") {
        response = await apiRepository.getTrainerSchedules(user?.userId);
      } else {
        response = await apiRepository.getUserSchedules(user?.userId);
      }

      const filteredSchedules = filterSchedules(response.data.data);
      setData(filteredSchedules);
    } catch (error) {
      console.error('Error al obtener los datos de usuarios:', error);
    }
  };

  useEffect(() => {
    fetchSchedules();
  }, [filterFuture]);

  const handleEditClick = (rowData: any) => {
    setSelectedSchedules(null);
    setTimeout(() => {
        const updatedRowData = {
            ...rowData,
            trainerId: user?.userId || rowData.trainerId
        };
        setSelectedSchedules(updatedRowData);
    }, 0);
};

  const handleDeleteClick = (rowData: any) => {
    setSelectedScheduleId(null);
    setTimeout(() => setSelectedScheduleId(rowData.id), 0);
  };

  const formatReservation = (reservation: number) => {
    return reservation > 0 ? reservation : 'Sin reservas';
  };

  const columns = [
    { field: "title", header: "Nombre" },
    { field: "status", header: "test" },
    {
      field: "status",
      header: "Estado",
      body: (rowData) => {
        let label = '';
        let className = '';

        switch (rowData.status) {
          case 1:
            label = 'Pendiente';
            className = 'badge-pending';
            break;
          case 2:
            label = 'En Atención';
            className = 'badge-active';
            break;
          case 3:
              label = 'Finalizada';
              className = 'badge-default';
              break;
          case 4:
            label = 'Cancelado';
            className = 'badge-delete';
            break;
            case 5:
              label = 'Finalizada';
              className = 'badge-active';
            break;
          default:
            label = 'Desconocido';
            className = 'badge-default';
        }

        return <span className={className}>{label}</span>;
      }
    },
    {
      field: "date",
      header: "Fecha y Horario",
      sortable: true,
      body: (rowData) => {
        return (
          <span>
            {rowData.date} - {formatTimeTo12Hour(rowData.hourStart)} a {formatTimeTo12Hour(rowData.hourEnd)}
          </span>
        );
      }
    },
    { field: "quantity", header: "Total Cupos" },
    {
      field: "reservation",
      header: "Reservas",
      body: (rowData) => formatReservation(rowData.reservation),
    },
  ];

  const getColumns = useResponsiveColumns(columns);

  return (
    <>
      <h4>Clases</h4>
      <div className="">
        <div className="d-flex justify-content-between mt-3 mb-3">
          <div className="filter-buttons me-1">
            <button
              className={`btn ${filterFuture ? 'btn-primary-solid' : 'btn-light'}`}
              onClick={() => setFilterFuture(prev => !prev)}>
              <i className="fa fa-eye me-2" />
              {filterFuture ? 'Mostrar todas las clases' : 'Mostrar solo clases futuras'}
            </button>
          </div>

          <div className="list-btn">
            <button className="btn btn-primary-blue" type="button" onClick={handleEditClick}>
              <i className="fa fa-plus me-2" />
              Agregar Clase
            </button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
        <DataTableWrapper
          data={data}
          columns={getColumns()}
          onEdit={handleEditClick}
          lazy={false}
          onDelete={handleDeleteClick}
          paginator={false}
          sortField="date"
          sortOrder={-1}
          showGridlines
          emptyMessage="No se encontraron horarios."
        />
        </div>
      </div>
      <SchedulesModal selectedSchedule={selectedSchedules} onUpdateSuccess={fetchSchedules} />
      <DeleteSchedulesModal selectedScheduleId={selectedScheduleId} onDeleteSuccess={fetchSchedules} />
    </>
  );
};

export default CustomerProfile;