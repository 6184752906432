import React, { useEffect, useState } from 'react';
import ImageWithBaseApi from '../../../../core/img/ImageWithBaseApi';

export interface ComplaintModalProps {
  selectedComplaint: {
    id: number;
    firstName: string;
    lastName: string;
    documentType: string;
    documentNumber: string;
    email: string;
    phoneNumber: string;
    department: string;
    province: string;
    district: string;
    address: string;
    reference: string;
    complaintType: string;
    consumptionType: string;
    receiptNumber: string;
    complaintDate: string;
    provider: string;
    productCost: string;
    productDescription: string;
    purchaseDate: string;
    consumptionDate: string;
    complaintDetails: string;
    customerRequest: string;
    evidence: string;
    isMinor: boolean;
  };
}

const ComplaintModal: React.FC<ComplaintModalProps> = ({ selectedComplaint }) => {
  const initialData = {
    id: 0,
    firstName: '',
    lastName: '',
    documentType: '',
    documentNumber: '',
    email: '',
    phoneNumber: '',
    department: '',
    province: '',
    district: '',
    address: '',
    reference: '',
    complaintType: '',
    consumptionType: '',
    receiptNumber: '',
    complaintDate: '',
    provider: '',
    productCost: '',
    productDescription: '',
    purchaseDate: '',
    consumptionDate: '',
    complaintDetails: '',
    customerRequest: '',
    evidence: '',
    isMinor: false,
  };

  const [complaintData, setComplaintData] = useState(selectedComplaint || initialData);

  useEffect(() => {
    setComplaintData(selectedComplaint || initialData);
  }, [selectedComplaint]);

  return (
    <div className="modal fade" id="complaint-modal">
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content shadow-lg rounded-lg">
          <div className="modal-header text-white">
            <h5 className="modal-title">Detalles de la Queja</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Cerrar"></button>
          </div>
          <div className="modal-body">
            <div className="container">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <div className="card p-3 shadow-sm rounded">
                    <h5><strong>Información Personal</strong></h5>
                    <p><strong>Nombre:</strong> {complaintData.firstName} {complaintData.lastName}</p>
                    <p><strong>Documento:</strong> {complaintData.documentType} - {complaintData.documentNumber}</p>
                    <p><strong>Email:</strong> {complaintData.email}</p>
                    <p><strong>Teléfono:</strong> {complaintData.phoneNumber}</p>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="card p-3 shadow-sm rounded">
                    <h5><strong>Dirección</strong></h5>
                    <p><strong>Departamento:</strong> {complaintData.department}</p>
                    <p><strong>Provincia:</strong> {complaintData.province}</p>
                    <p><strong>Distrito:</strong> {complaintData.district}</p>
                    <p><strong>Dirección:</strong> {complaintData.address}</p>
                  </div>
                </div>
              </div>
              <div className="card p-3 shadow-sm rounded mb-3">
                <div className="row">
                  <div className="col-md-8">
                    <h5><strong>Detalles de la Queja</strong></h5>
                    <p><strong>Tipo de Queja:</strong> {complaintData.complaintType}</p>
                    <p><strong>Fecha de Compra:</strong> {complaintData.purchaseDate}</p>
                    <p><strong>Descripción del Producto:</strong> {complaintData.productDescription}</p>
                    <p><strong>Detalles de la Queja:</strong> {complaintData.complaintDetails}</p>
                    <p><strong>Solicitud del Cliente:</strong> {complaintData.customerRequest}</p>
                  </div>
                  <div className="col-md-4">
                    {complaintData.evidence && (
                      <div className="text-left">
                        <h5><strong>Evidencia del Reclamo:</strong></h5>
                        <div className="profile-upload-img">
                          <ImageWithBaseApi
                            src={complaintData.evidence}
                            routeName="complaint"
                            className="img-fluid shadow rounded"
                            style={{ height: '200px', width: 'auto', objectFit: 'cover' }}
                            alt="Evidencia del reclamo"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComplaintModal;