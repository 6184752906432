import React, { useState, useEffect } from 'react';
import * as Icon from 'react-feather';
import { apiRepository } from '../../../../api/apiRepository';
import BaseModal from './baseModalDelete';

export interface DeleteFaqModalProps {
    selectedId: number;
    onDeleteSuccess?: () => void;
}

const DeleteFaqModal: React.FC<DeleteFaqModalProps> = ({ selectedId, onDeleteSuccess }) => {
    const [show, setShow] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (selectedId) {
            setShow(true);
        }
    }, [selectedId]);

    const handleClose = () => {
        setShow(false);
    };

    const handleConfirmDelete = async () => {
        if (selectedId === -1) return;

        try {
            setLoading(true);
            await apiRepository.deleteFaq(selectedId);

            if (onDeleteSuccess) {
                setShow(false);
                onDeleteSuccess();
            }
        } catch (error) {
            console.error("Error al eliminar FAQ:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <BaseModal
            show={show}
            title="Eliminar Pregunta"
            onClose={handleClose}
            onConfirm={handleConfirmDelete}
            loading={loading}
        >
            <div className="text-center">
                <Icon.Trash2 size={45} color='#f05050' className="text-danger fs-1" />
                <div className="mt-4">
                    <h4>¿Eliminar Pregunta?</h4>
                    <p className="text-muted mb-0">¿Estás seguro de que quieres eliminar esta pregunta?</p>
                </div>
            </div>
        </BaseModal>
    );
};

export default DeleteFaqModal;
