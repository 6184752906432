import React, {useEffect, useState} from 'react'
import { apiRepository } from '../../../../api/apiRepository';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PackageSlider from '../../common/packageSlider/packageSlider'
import PricingPlans from '../../common/pricingPlans/pricingPlans'
import ProductCarousel from '../../common/productCarousel/productCarousel'

const PreferredSection = () => {
  const [packages, setPackages] = useState([]);
  const [productIds, setProductIds] = useState([]);
  const [sortedCategories, setSortedCategories] = useState([]);
  const imgslideroption = {
    dots: false,
    nav: true,
    infinite: true,
    speed: 500,
    swipe:true,
    slidesToShow: 4,
    tochMove:true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchPackagesCategories = async () => {
      try {
        const response = await apiRepository.getPackageTypes();
        const filteredCategories = response.data.data.filter(item => item.status === true);
        const sortedCategories = filteredCategories.sort((a, b) => a.position - b.position);
        setSortedCategories(sortedCategories);

        const productIds = sortedCategories.filter(item => item.type === 2).map(item => item.id);
        setProductIds(productIds);
        return sortedCategories;
      } catch (error) {
        console.error("Error fetching packages:", error);
        return [];
      }
    };

    const fetchPackages = async (sortedCategories: any[]) => {
      try {
        const response = await apiRepository.getIdPackageList();
        if (response && response.data) {
          const groupedByPackageTypeId = response.data.data.reduce(
            (acc: Record<string, any[]>, item: any) => {
              const { packageTypeId } = item;
              if (!acc[packageTypeId]) {
                acc[packageTypeId] = [];
              }
              acc[packageTypeId].push(item);
              return acc;
            },
            {}
          );

          Object.keys(groupedByPackageTypeId).forEach((key) => {
            groupedByPackageTypeId[key].sort((a, b) => a.price - b.price);
          });

          const orderedGroupedByPackageTypeId = sortedCategories.map((category: any) => ({
            [category.id]: groupedByPackageTypeId[category.id] || [],
          }));

          setPackages(orderedGroupedByPackageTypeId);
        }
      } catch (error) {
        console.error("Error al obtener los paquetes:", error);
      }
    };

    const fetchData = async () => {
      const sortedCategories = await fetchPackagesCategories();
      await fetchPackages(sortedCategories);
    };

    fetchData();
  }, []);

  return (
    <section className="section">
      <div className="container col-11">
        <div className="row justify-content-center">
          {packages.map((group, index) => {
            const [groupKey, groupPackages] = Object.entries(group)[0];

            if (!groupPackages || groupPackages.length === 0) {
              return null;
            }

            if (productIds.includes(Number(groupKey))) {
              return (
                <div key={index} className="col-12 my-4">
                  <div className="row">
                    <div className="col-md-12 text-center aos">
                      <div className="section-heading sec-header">
                        <h2 className="text-center">
                          {sortedCategories[index]?.title || `Paquetes tipo ${groupKey}`}
                        </h2>
                        <p>{sortedCategories[index]?.subtitle || ""}</p>
                      </div>
                    </div>
                  </div>

                  <ProductCarousel products={groupPackages} />
                </div>
              );
            }

            return (
              <div key={index} className="col-12 my-4">
                <div className="row">
                  <div className="col-md-12 text-center aos">
                    <div className="section-heading sec-header">
                      <h2 className="text-center">
                        {sortedCategories[index]?.title || `Paquetes tipo ${groupKey}`}
                      </h2>
                      <p>{sortedCategories[index]?.subtitle || ""}</p>
                    </div>
                  </div>
                </div>
                {groupPackages.length === 3 ? (
                  <PricingPlans packages={groupPackages} />
                ) : (
                  <div className="home-package">
                    <PackageSlider packages={groupPackages} sliderOptions={imgslideroption} />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}


export default PreferredSection;