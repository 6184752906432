import React, { useEffect, useState } from 'react';
import ImageWithBaseApi from '../../../../core/img/ImageWithBaseApi';
import { Link } from 'react-router-dom';
import FeatureSection from './feature-section';
import MainFooter from '../footer/main-footer';
import TrialSection from './trialSection';
import PackageSection from './packageSection';
import HomeHeader from '../header/main-header';
import AuthModals from '../header/authModals';
import Slider from 'react-slick';
import { apiRepository } from '../../../../api/apiRepository';

const NewHome = () => {
  const [banners, setBanners] = useState([]);
  const imgslideroption = {
    dots: false,
    nav: true,
    infinite: true,
    speed: 500,
    swipe: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          swipe: true,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          swipe: true,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
          swipe: true,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await apiRepository.getBanners();
        setBanners(response.data.data);
      } catch (error) {
        console.error('Error fetching banners:', error);
      }
    };

    fetchBanners();
  }, []);

  return (
    <>
      <HomeHeader type={1} />
      <>
        <Slider
          {...imgslideroption}
          className="img-slider  nav-center hero-section  slick-slider  slide-arrows-hero "
        >
          {banners
            .filter((banner) => banner.status)
            .map((banner, index) => (
              <div className="slide-images" key={index}>
                <div className="hero-content position-relative overflow-hidden">
                  <div className="hero-image slide-images">
                    <ImageWithBaseApi
                      routeName="banner"
                      src={banner.image}
                      alt={banner.altText || 'Banner'}
                      className="shape-03"
                    />
                  </div>
                  <div className="container hero-action d-flex">
                    <div className="action-content col-md-6 col-xxl-8 col-xl-10 col-lg-11 text-left justify-content-center">
                      <h1 className="mb-2">{banner.title}</h1>
                      <p>{banner.subtitle}</p>
                      <div className="mb-2 appointment-btn">
                        <Link
                          to={banner.path || '#'}
                          className="btn btn-primary d-inline-flex align-items-center"
                        >
                          {banner.buttonText || 'Ver más'}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </Slider>
        <TrialSection />
        <PackageSection />

        <FeatureSection />
        <MainFooter />
      </>
      <AuthModals />
    </>
  );
};

export default NewHome;
